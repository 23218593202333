<template>
  <div class="row">
    <div class="col-12">
      {{ $t("search.contractcode") }}
      <m-input
        v-model="search.CONTRACTCODE"
        class="search-form-field"
        nolabel
      ></m-input>
    </div>

    <div class="col-12">
      {{ $t("search.firmname") }}
      <m-input
        v-model="search.FIRMNAME"
        class="search-form-field"
        nolabel
      ></m-input>
    </div>

    <div class="col-12">
      {{ $t("search.fullname") }}
      <m-input
        v-model="search.FULLNAME"
        class="search-form-field"
        nolabel
      ></m-input>
    </div>

    <div class="col-12">
      {{ $t("search.barcode") }}
      <m-input
        v-model="search.BARCODE"
        class="search-form-field"
        nolabel
      ></m-input>
    </div>
    <div class="col-12" style="text-align: center">İşlem Tarihi</div>

    <div class="col-6">
      {{ $t("search.startdate") }}

      <m-input
        class="search-form-field"
        v-model="search.TRANSDATE[0]"
        nolabel
        type="date"
      ></m-input>
    </div>

    <div class="col-6">
      {{ $t("search.enddate") }}

      <m-input
        class="search-form-field"
        type="date"
        v-model="search.TRANSDATE[1]"
        nolabel
      ></m-input>
    </div>

    <div class="col-12" style="text-align: center">Kargo Tarihi</div>

    <div class="col-6">
      {{ $t("search.startdate") }}

      <m-input
        class="search-form-field"
        v-model="search.CARGODATE[0]"
        nolabel
        type="date"
      ></m-input>
    </div>
    <div class="col-6">
      {{ $t("search.enddate") }}

      <m-input
        class="search-form-field"
        type="date"
        v-model="search.CARGODATE[1]"
        nolabel
      ></m-input>
    </div>

    <!--<div class="col-6">
      {{ $t("search.startdate") }}

      <m-input
        class="search-form-field"
        v-model="search.TRANSDATE[0]"
        nolabel
        type="date"
      ></m-input>
    </div>
    <div class="col-6">
      {{ $t("search.enddate") }}

      <m-input
        class="search-form-field"
        type="date"
        v-model="search.TRANSDATE[1]"
        nolabel
      ></m-input>
    </div>-->
  </div>
</template>

<script>
import api from "../../api";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPerms"]),
  },
  data() {
    return {
      search: {
        CONTRACTOCDE: null,
        FULLNAME: null,
        FIRMNAME: null,
        BARCODE: null,
        TRANSDATE: [null, null],
        CARGODATE: [null, null],
      },
      options: {
        reseller: [],
      },
    };
  },

  methods: {
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        CONTRACTOCDE: null,
        FULLNAME: null,
        FIRMNAME: null,
        BARCODE: null,
        TRANSDATE: [null, null],
        CARGODATE: [null, null],
      };
    },
  },
};
</script>

<style></style>
