<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.COUNTRYNAME" langlabel="countryname" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getCountry(this.ID);
    }
  },
  data() {
    return {
      ID: null,
      form: {
        COUNTRYNAME: null,
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveCountry(this.form, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getCountry(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getCountryOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const { COUNTRYNAME } = res.data.data;
            this.form = { COUNTRYNAME };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteCountry(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
