<template>
  <b-tabs>
    <b-tab :title="$t('search.global')" active>
      <div class="row">
        <div class="col-12">
          {{ $t("search.agentcity") }}
          <m-select
            v-model="search.RESELLERCITYID"
            class="search-form-field"
            :options="options.city"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.senderagent") }}
          <m-select
            v-model="search.SENDERRESELLERID"
            class="search-form-field"
            :options="options.reseller"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.takeragent") }}
          <m-select
              v-model="search.TAKERRESELLERID"
              class="search-form-field"
              :options="options.reseller"
              nolabel
          ></m-select>
        </div>

        <div class="col-12">
          <m-input
            class="search-form-field"
            v-model="search.BARCODE"
            nolabel
            :placeholder="$t('search.barcode')"
          ></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">{{
            $t("search.actiondate")
          }}</span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input
            class="search-form-field"
            v-model="search.SAVEDATE[0]"
            nolabel
            type="date"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input
            class="search-form-field"
            type="date"
            v-model="search.SAVEDATE[1]"
            nolabel
          ></m-input>
        </div>
        <div class="col-12">
          <span class="text-center" style="display: block">{{
            $t("search.sender")
          }}</span>
        </div>
        <div class="col-12">
          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.sandername')"
            v-model="search.SENDERFULLNAME"
          ></m-input>
          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.senderfirmname')"
            v-model="search.SENDERFIRMNAME"
          ></m-input>

          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.senderidentityno')"
            v-model="search.SENDERIDENTITYNO"
          ></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">{{
            $t("search.taker")
          }}</span>
        </div>
        <div class="col-12">
          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.takername')"
            v-model="search.TAKERFULLNAME"
          ></m-input>
          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.takerfirmname')"
            v-model="search.TAKERFIRMNAME"
          ></m-input>

          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.takeridentityno')"
            v-model="search.TAKERIDENTITYNO"
          ></m-input>
        </div>

        <div class="col-12">
          {{ $t("search.deliverystatus") }}
          <m-select
            v-model="search.ISDELIVERY"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.deliverydate") }}
          </span>
        </div>

        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input
            class="search-form-field"
            v-model="search.DELIVERYDATE[0]"
            nolabel
            type="date"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input
            class="search-form-field"
            type="date"
            v-model="search.DELIVERYDATE[1]"
            nolabel
          ></m-input>
        </div>

        <div class="col-6">
          {{ $t("search.cancelstatus") }}
          <m-select
            v-model="search.ISCANCEL"
            class="search-form-field"
            nolabel
            :options="options.yesNo"
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.returnstatus") }}
          <m-select
            v-model="search.ISRETURN"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.isclosed") }}
          <m-select
            v-model="search.ISCLOSED"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>

        <div class="col-6">
          {{ $t("search.isaccounted") }}
          <m-select
            v-model="search.ISACCOUNTED"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>

        <div class="col-6">
          {{ $t("search.informationformstatus") }}

          <m-select
            v-model="search.ISINFORMATIONFORM"
            class="search-form-field"
            nolabel
            :options="options.yesNo"
          ></m-select>
        </div>
      </div>
    </b-tab>
    <b-tab :title="$t('search.mediate')">
      <div class="row">
        <div class="col-12">
          {{ $t("search.mediate") }}

          <m-select
            class="search-form-field"
            v-model="search.BROKERAGEID"
            nolabel
            :options="options.brokerage"
          ></m-select>
        </div>
        <div class="col-12">
          <m-input
            class="search-form-field"
            v-model="search.INTEGRATIONBARCODE"
            nolabel
            :placeholder="$t('search.mediatebarcode')"
          ></m-input>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {


      search: {
        BARCODE: null,
        INTEGRATIONBARCODE: null,

        RESELLERCITYID: null,

        SAVEDATE: [null, null],
        DELIVERYDATE: [null, null],

        SENDERFIRMNAME: null,
        SENDERFULLNAME: null,
        SENDERIDENTITYNO: null,

        TAKERFIRMNAME: null,
        TAKERFULLNAME: null,
        TAKERIDENTITYNO: null,

        BROKERAGEID: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,

        ISCANCEL: null,
        ISDELIVERY: null,
        ISRETURN: null,
        ISINFORMATIONFORM: null,
        ISCLOSED: null,
        ISACCOUNTED: null
      },
      options: {
        reseller: [],
        city: [],
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
        approved: [
          { text: "Tümü", value: null },
          { text: "Onaylandı", value: "Onaylandı" },
          { text: "Beklemede", value: "Beklemede" },
        ],
        brokerage: [],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
    this.getReseller();
    this.getBrokerage();
    this.getCity();
  },
  methods: {
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getReseller() {
      api.getReseller().then((res) => {
        if (res.data.status) {
          this.options.reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getResellerWithCity(id) {
      api.getResellerWithCityId(id).then((res) => {
        if (res.data.status) {
          const reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });

          this.options.reseller = reseller;
        }
      });
    },
    getCity() {
      api.getCity().then((res) => {
        if (res.data.status) {
          const city = res.data.data.rows.map((item) => {
            return {
              text: item.CITYNAME,
              value: item.ID,
            };
          });

          this.options.city = city;
        }
      });
    },
    getSearch() {
/*      this.search.eq={
        ISDELIVERY : "1"
      };*/
      return this.search;
    },
    clearSearch() {
      this.search = {
        BARCODE: null,
        INTEGRATIONBARCODE: null,

        RESELLERCITYID: null,

        SAVEDATE: [null, null],
        DELIVERYDATE: [null, null],

        SENDERFIRMNAME: null,
        SENDERFULLNAME: null,
        SENDERIDENTITYNO: null,

        TAKERFIRMNAME: null,
        TAKERFULLNAME: null,
        TAKERIDENTITYNO: null,

        BROKERAGEID: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,

        ISCANCEL: null,
        ISDELIVERY: null,
        ISRETURN: null,
        ISINFORMATIONFORM: null,
        ISCLOSED: null,
        ISACCOUNTED: null,
      };
    },
  },
  watch: {
    "search.RESELLERCITYID"(val) {
      if (intval(val) > 0) {
        this.search.SENDERRESELLERID = null;
        this.getResellerWithCity(val);
      }
    },
  },
};
</script>

<style></style>
