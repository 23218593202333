<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.COUPONNAME" langlabel="campaignname" />
        <m-input v-model="form.COUPONCODE" langlabel="couponcode" />
        <m-input v-model="form.STARTDATE" langlabel="startdate" type="date" />
        <m-input v-model="form.ENDDATE" langlabel="enddate" type="date" />
        <m-input
          v-model="form.DISCOUNTRATE"
          langlabel="discountrate"
          type="number"
        />
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getCampaignCoupon(this.ID);
    }
  },
  data() {
    return {
      ID: null,
      form: {
        COUPONNAME: null,
        COUPONCODE: null,
        STARTDATE: null,
        ENDDATE: null,
        DISCOUNTRATE: null,
        ISACTIVE: 1,
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveCampaignCoupon(this.form, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getCampaignCoupon(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getCampaignCouponOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              COUPONNAME,
              COUPONCODE,
              STARTDATE,
              ENDDATE,
              DISCOUNTRATE,
              ISACTIVE,
            } = res.data.data;
            this.form = {
              COUPONNAME,
              COUPONCODE,
              STARTDATE,
              ENDDATE,
              DISCOUNTRATE,
              ISACTIVE,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteCampaignCoupon(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
