<template>
  <div class="row">
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.VEHICLENAME"
        nolabel
        :placeholder="$t('search.vehicleplate')"
      ></m-input>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        VEHICLENAME: null,
      },
    };
  },

  methods: {
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        VEHICLENAME: null,
      };
    },
  },
};
</script>

<style></style>
