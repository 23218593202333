import Vue from "vue";

const EventBus = new Vue();
export default EventBus;
// Event listener for warranty transaction success
window.addEventListener("message", function(e) {
  if (e.data.event === "grid-row-double-click-event") {
    EventBus.$emit("grid-row-double-click", e.data.data);
  } else if (e.data.event === "apicargo-transfer-click-event") {
    EventBus.$emit("apicargo-transfer-click", e.data.data);
  } else if (e.data.event === "cargo-track-click-event") {
    EventBus.$emit("cargo-track-click", e.data.data);
  } else if (e.data.event === "cargo-create-manifest-click-event") {
    EventBus.$emit("cargo-create-manifest-click", e.data.data);
  }
});
