<template>
  <div class="grid-col-editor">
    <div class="grid-col-editor-box">
      <div class="grid-col-editor-tab">
        <div class="grid-col-editor-tab-headers">
          <div
            class="grid-col-editor-tab-header"
            :class="{
              'grid-col-editor-tab-header-active': tabs.active == 'visibility',
            }"
            @click="setTabActive('visibility')"
          >
            Görünürlük
          </div>
          <div
            @click="setTabActive('color')"
            class="grid-col-editor-tab-header"
            :class="{
              'grid-col-editor-tab-header-active': tabs.active == 'color',
            }"
          >
            Satır Renklendirme
          </div>
          <div
            class="grid-col-editor-tab-header"
            :class="{
              'grid-col-editor-tab-header-active': tabs.active == 'settings',
            }"
            @click="setTabActive('settings')"
          >
            Ayarlar
          </div>
        </div>
        <div class="grid-col-editor-tab-contents">
          <div
            class="grid-col-editor-tab-content"
            v-show="tabs.active == 'visibility'"
          >
            <b-form-group>
              <template #label>
                <b>Göster/Gizle:</b><br />
                <b-form-checkbox
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  aria-describedby="flavours"
                  aria-controls="flavours"
                  @change="toggleAll"
                  size="sm"
                >
                  {{ allSelected ? "Tümünü Bırak" : "Tümünü Seç" }}
                </b-form-checkbox>
              </template>

              <template v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  size="sm"
                  id="flavors"
                  v-model="selected"
                  :options="fields"
                  :aria-describedby="ariaDescribedby"
                  name="flavors"
                  class="ml-1"
                  aria-label="Individual flavours"
                  stacked
                  plain
                ></b-form-checkbox-group>
              </template>
            </b-form-group>
          </div>

          <div
            class="grid-col-editor-tab-content"
            v-show="tabs.active == 'color'"
          >
            <b-btn squared style="float: right" size="sm" @click="addRule"
              >Yeni Ekle</b-btn
            >
            <div style="clear: both"></div>

            <b-table-simple style="color: white">
              <b-thead>
                <b-tr>
                  <b-th>Alan</b-th>
                  <b-th>Şart</b-th>
                  <b-th>Değer</b-th>
                  <b-th>#</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(color, index) in colors.rules">
                  <tr :key="`r0-${index}`">
                    <td>
                      <m-select
                        nolabel
                        v-model="color.colId"
                        :options="fields"
                      />
                    </td>
                    <td>
                      <m-select
                        nolabel
                        v-model="color.condition"
                        :options="options.condition"
                      />
                    </td>
                    <td>
                      <m-input nolabel v-model="color.conditionString" />
                    </td>
                    <td>
                      <b-btn
                        variant="danger"
                        size="sm"
                        @click="removeRule(index)"
                        >&times;</b-btn
                      >
                    </td>
                  </tr>
                  <tr :key="`r1-${index}`">
                    <td colspan="2">
                      Arkaplan Rengi:<br />
                      <m-color-picker
                        :color="color.background"
                        v-model="color.background"
                      />
                    </td>
                    <td colspan="2">
                      Yazı Rengi:<br />
                      <m-color-picker
                        :color="color.color"
                        v-model="color.color"
                      />
                    </td>
                  </tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import api from "../../api";
export default {
  props: ["gridOptions", "type", "gridRulesUserDefined"],
  mounted() {
    //  setTimeout(() => {
    this.getColums();
    //    }, 750);

    if (typeof this.gridRulesUserDefined == typeof []) {
      if (this.gridRulesUserDefined.length) {
        this.colors.rules = this.gridRulesUserDefined;
      }
    }
  },
  data() {
    return {
      tabs: {
        active: "visibility",
      },

      allSelected: false,
      indeterminate: false,
      selected: [],
      fields: [],

      colors: {
        rules: [],
      },

      options: {
        condition: [
          {
            text: "Eşitse",
            value: "=",
          },
          {
            text: "Eşit Değilse",
            value: "!=",
          },
          {
            text: "Büyükse",
            value: ">",
          },
          {
            text: "Küçükse",
            value: "<",
          },
          {
            text: "Büyük Eşitse",
            value: ">=",
          },
          {
            text: "Küçük Eşitse",
            value: "<=",
          },
        ],
      },
    };
  },
  methods: {
    addRule() {
      this.colors.rules.push({
        condition: null,
        colId: null,
        conditionString: null,
        color: "",
        background: "",
      });
    },
    removeRule(index) {
      this.colors.rules.splice(index, 1);
    },

    setTabActive(active) {
      this.tabs.active = active;
    },
    toggleAll(checked) {
      this.selected = checked
        ? this.fields.slice().map((item) => item.value)
        : [];
    },

    getColums() {
      console.log("gridoptions.colApi", this.gridOptions.columnApi);
      this.gridOptions.columnApi.getAllColumns().forEach((column) => {
        // console.log(column.colId);
        this.fields.push({
          text: column.colDef.headerName,
          value: column.colId,
        });
        if (column.visible) {
          this.selected.push(column.colId);
        }
      });
    },

    convertGridSystem() {
      this.gridOptions.columnApi.applyColumnState({
        state: this.fields.map((item) => {
          return {
            colId: item.value,
            hide: !this.selected.includes(item.value),
          };
        }),
      });

      this.gridOptions.getRowStyle = (params) => {
        if (!params.data) return;
        const callback = (item) => {
          const colDef = params.node.columnApi.columnController.columnDefs;
          const col = colDef.find((col) => col.field == item.colId);

          if (!col) {
            return null;
          }

          let colValue = params.data[item.colId];

          if ("valueFormatter" in col) {
            colValue = col.valueFormatter({ value: colValue });
          }

          switch (item.condition) {
            case "=":
              if (colValue == item.conditionString) {
                return {
                  background: item.background,
                  color: item.color,
                };
              }

              break;
            case "!=":
              if (colValue != item.conditionString) {
                return {
                  background: item.background,
                  color: item.color,
                };
              }
              break;
            case ">":
              if (colValue > item.conditionString) {
                return {
                  background: item.background,
                  color: item.color,
                };
              }
              break;
            case "<":
              if (colValue < item.conditionString) {
                return {
                  background: item.background,
                  color: item.color,
                };
              }
              break;
            case ">=":
              if (colValue >= item.conditionString) {
                return {
                  background: item.background,
                  color: item.color,
                };
              }
              break;
            case "<=":
              if (colValue <= item.conditionString) {
                return {
                  background: item.background,
                  color: item.color,
                };
              }
              break;
            default:
              return null;
              break;
          }
        };

        for (let item of this.colors.rules) {
          let res = callback(item);
          if (res) {
            return res;
          }
        }
      };
    },

    save() {
      this.convertGridSystem();
      this.$nextTick(() => {
        let colState = this.gridOptions.columnApi.getColumnState();
        const data = {
          type: this.type,
          rules: this.colors.rules,
          columnState: colState,
        };
        api.saveUserDefinedTemplate(data).then((res) => {
          swal("Başarılı", "Başarıyla kayıt edildi", "success");
        });
      });
    },
  },
  watch: {
    selected(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.fields.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
    gridRulesUserDefined() {
      if (this.gridRulesUserDefined) {
        this.colors.rules = this.gridRulesUserDefined;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.grid-col-editor {
  top: 33px;
  right: 0px;
  position: absolute;
  width: 30%;
  height: calc(100% - 33px);
  background-color: rgb(49, 49, 49);
  border-left: 3px dashed rgba(16, 36, 80, 0.9);

  & > * {
    box-sizing: border-box;
  }

  /**<div class="grid-col-editor">
          <div class="grid-col-editor-box">
            <div class="grid-col-editor-tab">
              <div class="grid-col-editor-tab-headers">
                <div class="grid-col-editor-tab-header">
                  Görünürlük
                </div>
                <div class="grid-col-editor-tab-header grid-col-editor-tab-header-active">
                  Satır Renklendirme
                </div>
                <div class="grid-col-editor-tab-header">
                  Ayarlar
                </div>
              </div>
              <div class="grid-col-editor-tab-contents"></div>
            </div>
          </div>
        </div>
      </div> */

  &-box {
    width: 100%;
    height: 100%;
  }

  &-tab {
    width: 100%;
    height: 100%;

    &-headers {
      width: 22px;
      height: 100%;
      float: left;
      font-size: 11px;
      background-color: royalblue;
    }

    &-header {
      width: 22px;

      writing-mode: vertical-rl;
      /*text-orientation: sideways;*/
      transform: scale(-1);
      border-top: 1px solid rgb(22, 21, 21);
      padding: 10px 3px;
      cursor: pointer;

      &:last-child {
        border-top: none;
      }

      &:hover {
        background-color: darken(royalblue, 7);
      }

      &-active {
        background-color: darken(royalblue, 15);
      }
    }

    &-contents {
      width: calc(100% - 22px);

      height: 100%;
      float: left;
    }
    &-content {
      width: 100%;
      height: 100%;
      overflow: auto;

      padding: 7px;
    }
  }
}
</style>