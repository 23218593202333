<template>
  <m-app id="test">
    <ag-grid-vue
      style="width: 100%; height: 100%;"
      class="ag-theme-balham-dark"
      :components="components"
      :columnDefs="columnDefs"
      :rowData="rowData"
    ></ag-grid-vue>
  </m-app>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue";

  import getAutoComplete from "../components/Editor/AutoComp";

  import "ag-grid-community/dist/styles/ag-grid.css";
  import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
  export default {
    components: {
      AgGridVue,
    },
    data() {
      return {
        columnDefs: null,
        rowData: null,
        components: null,
      };
    },
    beforeMount() {
      this.columnDefs = [
        { headerName: "Make", field: "make" },
        {
          headerName: "Model",
          field: "model",
          editable: true,
          cellEditor: "autoComplete",
          cellEditor: "autoComplete",
          cellEditorParams: {
            propertyRendered: "city",
            returnObject: true,
            rowData: [
              { id: 1, city: "Paris", country: "France" },
              { id: 2, city: "London", country: "United Kingdom" },
              { id: 3, city: "Berlin", country: "Germany" },
              { id: 4, city: "Madrid", country: "Spain" },
              { id: 5, city: "Rome", country: "Italy" },
              { id: 6, city: "Copenhagen", country: "Denmark" },
              { id: 7, city: "Brussels", country: "Belgium" },
              { id: 8, city: "Amsterdam", country: "The Netherlands" },
            ],
            columnDefs: [
              { headerName: "City", field: "city" },
              { headerName: "Country", field: "country" },
            ],
          },
          valueFormatter: (params) => {
            if (params.value) return params.value.city;
            return "";
          },
        },
        { headerName: "Price", field: "price" },
      ];

      this.rowData = [
        { make: "Toyota", model: "Celica", price: 35000 },
        { make: "Ford", model: "Mondeo", price: 32000 },
        { make: "Porsche", model: "Boxter", price: 72000 },
      ];

      this.components = { autoComplete: getAutoComplete() };
    },
    methods: {},
  };
</script>

<style lang="scss">
  $colorMain: #08090d;
  $colorSecond: #1a1e26;
  $colorThird: #343a40;
  $colorFourth: #6e7371;
  $colorFifth: #a1a69c;

  $colorWhite: #fff;
  .my-grid-class {
    height: 100%;
    background-color: $colorSecond;
    color: $colorWhite;
    .grid-tools {
      background-color: $colorMain;
    }

    .filter {
      background-color: $colorThird;
      color: $colorWhite;

      & > input {
        color: $colorWhite;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $colorWhite;
          opacity: 1; /* Firefox */
        }

        &::-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $colorWhite;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $colorWhite;
        }
      }
    }
  }
</style>
