<template>
  <m-app>
    <m-menu>
      <m-menu-item :title="getLang('menu.system')">
        <m-sub-menu>
         <!-- <m-menu-item :title="getLang('Dashboard2')" @click.native="openPop('https://integration.epsistem.com', 'dashboard2')" /> -->
          <m-menu-item :title="getLang('menu.settings')" v-if="getPerms('settings')" />
          <m-menu-item :title="getLang('menu.myaccount')"
            @click.native="openTab('form/myaccount', getLang('menu.myaccount'))" />
          <m-menu-item divider />

          <m-menu-item submenu :title="getLang('menu.langmanagement')">
            <m-sub-menu>
              <m-menu-item :title="getLang('lang')" @click.native="openTab('form/lang', getLang('lang'))"
                v-if="getPerms('langs')" />

              <m-menu-item :title="getLang('langfield')" @click.native="openTab('form/langfield', getLang('langfield'))"
                v-if="getPerms('langfields')" />
              <m-menu-item divider />

              <m-menu-item :title="getLang('lang', 'list')" @click.native="
                openTab('list/basic/lang', getLang('lang', 'list'))
              " v-if="getPerms('langs')" />

              <m-menu-item :title="getLang('langfield', 'list')" @click.native="
                openTab('list/basic/langfield', getLang('langfield', 'list'))
              " v-if="getPerms('langfields')" />
            </m-sub-menu>
          </m-menu-item>
          <!--
          <m-menu-item
            title="Firma Tanımı"
            @click.native="openTab('form/firm', 'Firma Tanımı')"
          />
          <m-menu-item
            title="Firma Listesi"
            @click.native="openTab('list/basic/firm', 'Firma Listesi')"
          />

          <m-menu-item divider />
          -->
          <m-menu-item submenu :title="getLang('menu.usermanagement')">
            <m-sub-menu>
              <m-menu-item :title="getLang('role')" @click.native="openTab('form/role', getLang('role'))"
                v-if="getPerms('roles')" />

              <m-menu-item :title="getLang('user')" @click.native="openTab('form/user', getLang('user'))"
                v-if="getPerms('users')" />
              <m-menu-item divider />

              <m-menu-item :title="getLang('role', 'list')" @click.native="
                openTab('list/basic/role', getLang('role', 'list'))
              " v-if="getPerms('roles')" />

              <m-menu-item :title="getLang('user', 'list')" @click.native="
                openTab('list/basic/user', getLang('user', 'list'))
              " v-if="getPerms('users')" />
            </m-sub-menu>
          </m-menu-item>

          <m-menu-item :title="getLang('menu.logout')" @click.native="
            () => {
              $router.push({ path: '/' });
            }
          " />
        </m-sub-menu>
      </m-menu-item>
      <m-menu-item :title="getLang('menu.definitions')">
        <m-sub-menu>
          <m-menu-item :title="getLang('reseller')" @click.native="openTab('form/reseller', getLang('reseller'))"
            v-if="getPerms('resellers')" />
          <m-menu-item :title="getLang('brokerage')" @click.native="openTab('form/brokerage', getLang('brokerage'))"
            v-if="getPerms('brokerages')" />
          <m-menu-item :title="getLang('brokerageagent')" @click.native="
            openTab('form/brokerageagent', getLang('brokerageagent'))
          " v-if="getPerms('brokerageagents')" />
          <m-menu-item :title="getLang('package')" @click.native="openTab('form/package', getLang('package'))"
            v-if="getPerms('packages')" />

          <m-menu-item :title="getLang('present')" @click.native="openTab('form/present', getLang('present'))"
            v-if="getPerms('presents')" />

          <m-menu-item :title="getLang('presentaddress')" @click.native="
            openTab('form/presentaddress', getLang('presentaddress'))
          " v-if="getPerms('presents')" />

          <m-menu-item :title="getLang('vehicle')" @click.native="openTab('form/vehicle', getLang('vehicle'))"
            v-if="getPerms('vehicles')" />

          <m-menu-item :title="getLang('returnvariable')" @click.native="
            openTab('form/returnvariable', getLang('returnvariable'))
          " v-if="getPerms('returnvariables')" />

          <m-menu-item :title="getLang('paytype')" @click.native="openTab('form/paytype', getLang('paytype'))"
            v-if="getPerms('paytypes')" />

          <m-menu-item :title="getLang('sendingtype')"
            @click.native="openTab('form/sendingtype', getLang('sendingtype'))" v-if="getPerms('sendingtypes')" />

          <m-menu-item :title="getLang('campaigncoupon')" @click.native="
            openTab('form/campaigncoupon', getLang('campaigncoupon'))
          " v-if="getPerms('campaigncoupons')" />

          <m-menu-item :title="getLang('contract')" @click.native="openTab('form/contract', getLang('contract'))"
            v-if="getPerms('contracts')" />

          <m-menu-item :title="getLang('cargotype')" @click.native="openTab('form/cargotype', getLang('cargotype'))"
            v-if="getPerms('cargotypes')" />

          <m-menu-item :title="getLang('manifest')" @click.native="openTab('form/manifest', getLang('manifest'))"
            v-if="getPerms('manifests')" />

          <m-menu-item :title="getLang('box')" @click.native="openTab('form/box', getLang('box'))"
            v-if="getPerms('boxes')" />

          <m-menu-item :title="getLang('staticprice')"
            @click.native="openTab('form/staticprice', getLang('staticprice'))" v-if="getPerms('staticprices')" />

          <m-menu-item :title="getLang('resellermap')"
            @click.native="openTab('form/resellermap', getLang('resellermap'))"
            v-if="getPerms('resellermaps') && useMap" />

          <m-menu-item submenu :title="getLang('menu.citycountydefinitions')">
            <m-sub-menu>
              <m-menu-item :title="getLang('country')" @click.native="openTab('form/country', getLang('country'))"
                v-if="getPerms('countries') && useCountry" />
              <m-menu-item :title="getLang('region')" @click.native="openTab('form/region', getLang('region'))"
                v-if="getPerms('regions') && useRegion" />
              <m-menu-item :title="getLang('city')" @click.native="openTab('form/city', getLang('city'))"
                v-if="getPerms('cities')" />
              <m-menu-item :title="getLang('county')" @click.native="openTab('form/county', getLang('county'))"
                v-if="getPerms('counties')" />
              <m-menu-item :title="getLang('neighborhood')"
                @click.native="openTab('form/town', getLang('neighborhood'))" v-if="getPerms('towns')" />
            </m-sub-menu>
          </m-menu-item>
        </m-sub-menu>
      </m-menu-item>
      <m-menu-item :title="getLang('menu.actions')">
        <m-sub-menu>
          <m-menu-item :title="getLang('menu.integrationactions')"
            @click.native="openTab('upload/file', 'Entegrasyon İşlemi')" v-if="getPerms('integrations')" />
          <m-menu-item :title="getLang('cargo')" @click.native="openTab('form/cargo', 'Kargo Kaydı')"
            v-if="getPerms('cargomanuel')" />
          <m-menu-item :title="getLang('cargobasic')" @click.native="openTab('form/cargobasic', 'Hızlı Kargo Kaydı')"
            v-if="getPerms('cargobasic')" />
          <m-menu-item :title="getLang('menu.mediatecargodefinitions')"
            @click.native="openTab('form/cargo/0/1', 'Aracı Kargo Kaydı')" v-if="getPerms('cargobrokerage')" />

          <m-menu-item :title="getLang('menu.integrationcargolist')" @click.native="
            openTab('list/basic/apicargo', 'Entegrasyon Kargo Listesi')
          " v-if="getPerms('apicargos')" />

          <m-menu-item :title="getLang('menu.resellertrans')" @click.native="
            openTab('form/resellertrans', getLang('menu.resellertrans'))
          " v-if="getPerms('resellertrans')" />

          <m-menu-item :title="getLang('menu.resellerclaimtrans')" @click.native="
            openTab(
              'form/resellerclaimtrans',
              getLang('menu.resellerclaimtrans')
            )
          " v-if="getPerms('resellerclaimtrans')" />

          <m-menu-item :title="getLang('menu.contracttrans')" @click.native="
            openTab('form/contracttrans', getLang('menu.contracttrans'))
          " v-if="getPerms('contracttrans')" />

          <m-menu-item :title="getLang('menu.contractpayedtrans')" @click.native="
            openTab(
              'form/contractpayedtrans',
              getLang('menu.contractpayedtrans')
            )
          " v-if="getPerms('contractpayedtrans')" />

          <m-menu-item divider />

          <m-menu-item :title="getLang('menu.vehicleupload')"
            @click.native="openTab('form/vehicleupload', 'Araç Yükleme')" v-if="getPerms('vehicleupload')" />

          <m-menu-item :title="getLang('menu.vehicledownload')"
            @click.native="openTab('form/vehicledownload', 'Araç İndirme')" v-if="getPerms('vehicledownload')" />

          <m-menu-item :title="getLang('menu.famazoncargodefinitions')" @click.native="
            openTab('integration/farmazon', 'Farmazon Kargo Kaydı')
          " v-if="getPerms('farmazons')" />

          <m-menu-item :title="getLang('menu.apiintegrationlist')" @click.native="
            openTab('form/apiintegration', 'Api Token Formu')
          " v-if="getPerms('apiintegration')" />
          <m-menu-item :title="getLang('menu.apiintegrationform')" @click.native="
            openTab('list/basic/apiintegration', 'Api Token Listesi')
          " v-if="getPerms('apiintegration')" />

          <m-menu-item :title="getLang('menu.tti')" @click.native="openTab('service/tti', 'TTİ İşlemi')"
            v-if="getPerms('tti')" />
        </m-sub-menu>
      </m-menu-item>
      <m-menu-item :title="getLang('menu.lists')">
        <m-sub-menu>
          <m-menu-item :title="getLang('cargo', 'list')" @click.native="
            openTab('list/basic/cargo', getLang('cargo', 'list'))
          " v-if="getPerms('cargolist')" />
          <m-menu-item :title="getLang('reseller', 'list')" @click.native="
            openTab('list/basic/reseller', getLang('reseller', 'list'))
          " v-if="getPerms('resellers')" />

          <m-menu-item :title="getLang('brokerage', 'list')" @click.native="
            openTab('list/basic/brokerage', getLang('brokerage', 'list'))
          " v-if="getPerms('brokerages')" />

          <m-menu-item :title="getLang('brokerageagent', 'list')" @click.native="
            openTab(
              'list/basic/brokerageagent',
              getLang('brokerageagent', 'list')
            )
          " v-if="getPerms('brokerageagents')" />

          <m-menu-item :title="getLang('package', 'list')" @click.native="
            openTab('list/basic/package', getLang('package', 'list'))
          " v-if="getPerms('packages')" />

          <m-menu-item :title="getLang('present', 'list')" @click.native="
            openTab('list/basic/present', getLang('present', 'list'))
          " v-if="getPerms('presents')" />

          <m-menu-item :title="getLang('presentaddress', 'list')" @click.native="
            openTab(
              'list/basic/presentaddress',
              getLang('presentaddress', 'list')
            )
          " v-if="getPerms('presents')" />

          <m-menu-item :title="getLang('vehicle', 'list')" @click.native="
            openTab('list/basic/vehicle', getLang('vehicle', 'list'))
          " v-if="getPerms('vehicles')" />

          <m-menu-item :title="getLang('returnvariable', 'list')" @click.native="
            openTab(
              'list/basic/returnvariable',
              getLang('returnvariable', 'list')
            )
          " v-if="getPerms('returnvariables')" />

          <m-menu-item :title="getLang('paytype', 'list')" @click.native="
            openTab('list/basic/paytype', getLang('paytype', 'list'))
          " v-if="getPerms('paytypes')" />

          <m-menu-item :title="getLang('sendingtype', 'list')" @click.native="
            openTab('list/basic/sendingtype', getLang('sendingtype', 'list'))
          " v-if="getPerms('sendingtypes')" />

          <m-menu-item :title="getLang('campaigncoupon', 'list')" @click.native="
            openTab(
              'list/basic/campaigncoupon',
              getLang('campaigncoupon', 'list')
            )
          " v-if="getPerms('campaigncoupons')" />

          <m-menu-item :title="getLang('contract', 'list')" @click.native="
            openTab('list/basic/contract', getLang('contract', 'list'))
          " v-if="getPerms('contracts')" />

          <m-menu-item :title="getLang('cargotype', 'list')" @click.native="
            openTab('list/basic/cargotype', getLang('cargotype', 'list'))
          " v-if="getPerms('cargotypes')" />

          <m-menu-item :title="getLang('manifest', 'list')" @click.native="
            openTab('list/basic/manifest', getLang('manifest', 'list'))
          " v-if="getPerms('manifests')" />

          <m-menu-item :title="getLang('box', 'list')" @click.native="openTab('list/basic/box', getLang('box', 'list'))"
            v-if="getPerms('boxes')" />

          <m-menu-item :title="getLang('staticprice', 'list')" @click.native="
            openTab('list/basic/staticprice', getLang('staticprice', 'list'))
          " v-if="getPerms('staticprices')" />

          <m-menu-item submenu :title="getLang('menu.citycountylist')">
            <m-sub-menu>
              <m-menu-item :title="getLang('country', 'list')" @click.native="
                openTab('list/basic/country', getLang('country', 'list'))
              " v-if="getPerms('countries') && useCountry" />
              <m-menu-item :title="getLang('region', 'list')" @click.native="
                openTab('list/basic/region', getLang('region', 'list'))
              " v-if="getPerms('regions') && useRegion" />
              <m-menu-item :title="getLang('city', 'list')" @click.native="
                openTab('list/basic/city', getLang('city', 'list'))
              " v-if="getPerms('cities')" />
              <m-menu-item :title="getLang('county', 'list')" @click.native="
                openTab('list/basic/county', getLang('county', 'list'))
              " v-if="getPerms('counties')" />
              <m-menu-item :title="getLang('neighborhood', 'list')" @click.native="
                openTab('list/basic/town', getLang('neighborhood', 'list'))
              " v-if="getPerms('towns')" />
            </m-sub-menu>
          </m-menu-item>
        </m-sub-menu>
      </m-menu-item>

      <m-menu-item :title="getLang('menu.reports')">
        <m-sub-menu>
          <m-menu-item :title="getLang('resellercashbox', 'list')" @click.native="
            openTab(
              'list/basic/resellercashbox',
              getLang('resellercashbox', 'list')
            )
          " v-if="getPerms('resellercashboxes')" />
          <m-menu-item :title="getLang('contractaccount', 'list')" @click.native="
  openTab(
    'list/basic/contractaccount',
    getLang('contractaccount', 'list')
            )
          " v-if="getPerms('contractaccounts')" />
          <m-menu-item :title="getLang('reselleraccount', 'list')" @click.native="
            openTab(
              'list/basic/reselleraccount',
              getLang('reselleraccount', 'list')
            )
          " v-if="getPerms('reselleraccounts')" />
          <m-menu-item :title="getLang('deliverycargo', 'list')" @click.native="
            openTab(
              'list/basic/deliverycargo',
              getLang('deliverycargo', 'list')
            )
          " v-if="getPerms('deliverycargos')" />
          <m-menu-item :title="getLang('invoice', 'list')" @click.native="
            openTab('list/basic/invoice', getLang('invoice', 'list'))
          " v-if="getPerms('invoices')" />
          <m-menu-item :title="getLang('cargopersondelivery', 'list')" @click.native="
            openTab(
              'list/basic/cargopersondelivery',
              getLang('cargopersondelivery', 'list')
            )
          " v-if="getPerms('cargopersondeliveries')" />
        </m-sub-menu>
      </m-menu-item>

      <m-menu-item title="Raporlar v2">
        <m-sub-menu>
          <m-menu-item submenu title="Kasa/Bakiye">
            <m-sub-menu>
              <m-menu-item title="Günlük Kasa Raporu" @click.native="
                openTab('list/basic/dailycashbox', 'Günlük Kasa Raporu')
              " v-if="getPerms('dailycashbox')" />

              <m-menu-item title="Özet Kasa Raporu" @click.native="
                openTab('list/basic/sumcashbox', 'Özet Kasa Raporu')
              " v-if="getPerms('sumcashbox')" />

              <m-menu-item title="Kasa Raporu" @click.native="openTab('list/basic/cashbox', 'Kasa Raporu')"
                v-if="getPerms('cashbox')" />
            </m-sub-menu>
          </m-menu-item>

          <m-menu-item submenu title="Hakediş">
            <m-sub-menu>
              <m-menu-item title="Özet Hakediş Raporu" @click.native="
                openTab('list/basic/sumearning', 'Özet Hakediş Raporu')
              " v-if="getPerms('sumearning')" />

              <m-menu-item title="Hakediş Raporu" @click.native="openTab('list/basic/earning', 'Hakediş Raporu')"
                v-if="getPerms('earning')" />
            </m-sub-menu>
          </m-menu-item>

          <m-menu-item submenu title="Sözleşmeli Müşteri">
            <m-sub-menu>
              <m-menu-item title="Özet Sözleşme Raporu" @click.native="
                openTab('list/basic/sumcontracttrans', 'Özet Sözleşme Raporu')
              " v-if="getPerms('sumcontracttrans')" />

              <m-menu-item title="Sözleşme Raporu" @click.native="
                openTab('list/basic/contracttrans', 'Sözleşme Raporu')
              " v-if="getPerms('contracttrans')" />
            </m-sub-menu>
          </m-menu-item>

          <m-menu-item submenu title="Kargo">
            <m-sub-menu>
              <m-menu-item title="İşlem Türü Raporu" @click.native="
                openTab('list/basic/cargotranskind', 'İşlem Türü Raporu')
              " v-if="getPerms('cargotranskind')" />

              <m-menu-item title="Teslim Edilmiş Kargolar" @click.native="
                openTab(
                  'list/basic/cargodeliveried',
                  'Teslim Edilmiş Kargolar'
                )
              " v-if="getPerms('cargodeliveried')" />
              <m-menu-item title="İade Edilmiş Kargolar" @click.native="
                openTab('list/basic/cargoreturned', 'İade Edilmiş Kargolar')
              " v-if="getPerms('cargoreturned')" />
              <m-menu-item title="İptal Edilmiş Kargolar" @click.native="
                openTab('list/basic/cargocancelled', 'İptal Edilmiş Kargolar')
              " v-if="getPerms('cargocancelled')" />
              <!--
              <m-menu-item
                title="İşlem Görmemiş Kargolar"
                @click.native="
                  openTab('list/basic/cargonoaction', 'İşlem Görmemiş Kargolar')
                "
                v-if="getPerms('cargonoaction')"
              />-->
              <m-menu-item title="Teslim Edilmemiş Kargolar" @click.native="
                openTab(
                  'list/basic/cargonotdelivered',
                  'Teslim Edilmemiş Kargolar'
                )
              " v-if="getPerms('cargonotdelivered')" />
            </m-sub-menu>
          </m-menu-item>
        </m-sub-menu>
      </m-menu-item>

      <m-menu-item :title="getLang('menu.help')">
        <m-sub-menu>
          <m-menu-item :title="getLang('menu.mobileappdownload')" @click.native="openUrl('app.apk')" />
          <m-menu-item :title="getLang('menu.printerhelperappdownload')" @click.native="openUrl('ProderPrinter.zip')" />
          <m-menu-item :title="getLang('menu.printerappdownload')" @click.native="openUrl('Printer.zip')" />

          <m-menu-item title="Proder Web" @click.native="openUrl('http://proder.com.tr')" />
        </m-sub-menu>
      </m-menu-item>
    </m-menu>
    <m-tab ref="mainAppTab" />
  </m-app>
</template>

<script>
import config from "../config";
import EventBus from "../libs/EventBus";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPerms"]),
    getLangField:
      () =>
        (key, type = "form") => {
          const items = key.split("/");
          const val = {
            cargo: "menu.cargo",
            cargotrack: "menu.cargotrack",
            box: "menu.box",
            staticprice: "menu.staticprice",
            reseller: "menu.agent",
            lang: "menu.lang",
            langfield: "menu.langfield",
            role: "menu.role",
            user: "menu.user",
            brokerage: "menu.mediate",
            brokerageagent: "menu.mediateagent",
            package: "menu.package",
            present: "menu.present",
            presentaddress: "menu.presentaddress",
            vehicle: "menu.vehicle",
            returnvariable: "menu.returnvariable",
            paytype: "menu.paytype",
            sendingtype: "menu.sendingtype",
            campaigncoupon: "menu.campaigncoupon",
            contract: "menu.contract",
            cargotype: "menu.cargotype",
            city: "menu.city",
            county: "menu.county",
            neighborhood: "menu.neighborhood",
            town: "menu.neighborhood",
          };

          if (items.length > 1) {
            if (items[0] == "form") {
              if (items[1] in val) {
                return val[items[1]] + "definitions";
              }
            } else if (items[0] == "list") {
              if (items[2] in val) {
                return val[items[2]] + "list";
              }
            }
          } else {
            if (key in val) {
              if (type == "form") {
                return val[key] + "definitions";
              } else if (type == "list") {
                return val[key] + "list";
              } else {
                return val[key];
              }
            }
          }

          return key;
        },
    useMap() {
      return config.USE_MAP_API;
    },
    useCountry() {
      return config.USE_COUNTRY;
    },
    useRegion() {
      return config.USE_REGION;
    },
  },

  mounted() {
    EventBus.$on("grid-row-double-click", (row) => {
      this.openTab(
        "form/" + row.type + "/" + row.ID,
        this.$t(this.getLangField(row.type)) + ` [${row.ID}]`
      );
    });
    EventBus.$on("apicargo-transfer-click", (id) => {
      this.openTab("form/cargo/0/0/" + id, "Aracı Kargo Transferi - " + id);
    });
    EventBus.$on("cargo-track-click", (id) => {
      this.openTab("form/cargotrack/" + id, "Kargo Detayları - " + id);
    });

    EventBus.$on("cargo-create-manifest-click", (params) => {
      this.openTab("form/manifest/0/" + params, "Manifesto Kaydı");
    });

    //this.$refs["mainAppTab"].openTab("welcome", "Hoş Geldiniz :)");
    //this.$refs["mainAppTab"].openTab("grid", "Grid");
    setTimeout(() => {
      //this.$refs["mainAppTab"].openTab("welcome", "Hoş Geldiniz :)", "ww");
      /*localStorage.setItem("selectedlang", "tr");
      const selectedlang = localStorage.getItem("selectedlang");
      const messages = JSON.parse(localStorage.getItem("messages"));

      this.$root.$i18n.setLocaleMessage(selectedlang, messages[selectedlang]);
      this.$root.$i18n.locale = selectedlang;*/
    }, 5000);
  },
  methods: {
    openPop(url, name) {
      let opt = "width=1920, height=1080";
      if (screen.height < 1080) {
        opt = "width=1366, height=768"
      }
      window.open(url, name, opt);
    },
    openUrl(url) {
      window.open(url, "new");
    },
    getLang(key, type = "form") {
      return this.$t(this.getLangField(key, type));
    },
    openTab(link, title) {
      this.$refs["mainAppTab"].openTab(link, title);
    },
  },
};
</script>

<style>
</style>
