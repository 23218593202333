<template>
  <b-form-group
    label-for="input-horizontal"
    label-cols-sm="4"
    :label="getLabel"
    label-size="sm"
    label-align="right"
  >
    <b-form-radio-group
      id="btn-radios-2"
      v-model="vValue"
      size="sm"
      small
      :type="type"
      :options="opt"
      buttons
      button-variant="outline-primary"
      :disabled="disabled"
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: () => null,
    },
    langlabel: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => "text",
    },
    options: {
      type: Array,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    opt() {
      if (this.options == null) {
        return [
          { text: this.$t("buton.yes"), value: 1 },
          { text: this.$t("buton.no"), value: 0 },
        ];
      }
      return this.options;
    },
    getLabel() {
      if (this.label == null && this.langlabel != null) {
        return this.$t("form." + this.langlabel);
      }
      return this.label;
    },
    vValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
