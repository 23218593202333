import config from "../config";

class Maps {
  drawMap = null;
  geocoder = null;
  drawingManager = null;
  isInitialized = false;
  isSetPolynon = false;
  customPolygon = null;
  polygons = [];

  selectedPolygon = null;
  constructor() {
    // Create the script tag, set the appropriate attributes
    let script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.MAP_API_KEY}&callback=initMap&libraries=drawing,geometry&dummy=.js`;
    script.defer = true;

    // Attach your callback function to the `window` object
    window.initMap = () => {
      let script1 = document.createElement("script");
      script1.src = `https://cdn.rawgit.com/googlemaps/js-map-label/gh-pages/src/maplabel.js`;

      // Append the 'script' element to 'head'
      document.head.appendChild(script1);
      // JS API is loaded and available
      this.initMap();
    };

    document.head.appendChild(script);
  }

  initMap() {
    this.isInitialized = true;
  }

  createMap(myMapArea, poly) {
    var myLatlng = new google.maps.LatLng(37.87257583335618, 32.4917828305815);
    var myOptions = {
      zoom: 5,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    var map = new google.maps.Map(myMapArea, myOptions);
    var polygons = [];

    for (var i = 0; i < poly.length; i++) {
      var bounds = new google.maps.LatLngBounds();
      polygons.push(
        new google.maps.Polygon({
          paths: poly[i].path,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        })
      );

      for (let xi = 0; xi < poly[i].path.length; xi++) {
        bounds.extend(poly[i].path[xi]);
      }

      var myLatlng = bounds.getCenter();
      console.log(myLatlng);

      var mapLabel = new MapLabel({
        text: poly[i].name,
        position: myLatlng,
        map: map,
        fontSize: 12,
        align: "left",
      });
      mapLabel.set("position", myLatlng);

      const polygon = polygons[polygons.length - 1];

      google.maps.event.addListener(
        polygon,
        "dblclick",
        (function(pol, i) {
          return function() {
            console.log(pol, i);
            //alert(i);
          };
        })(poly[i], i)
      );

      polygon.setMap(map);
    }
    // });
  }

  setPoliygons(path) {
    this.isSetPolynon = true;
    this.customPolygon = new google.maps.Polygon({
      paths: path,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });
    this.customPolygon.setMap(this.drawMap);
  }

  clearAllPolygons() {
    if (this.isSetPolynon && this.customPolygon != null) {
      this.customPolygon.setMap(null);
    } else {
      if (this.selectedPolygon != null) {
        this.selectedPolygon.setMap(null);
      }
    }
  }

  initDrawingMap(myMapArea) {
    var initialize = () => {
      var myLatlng = new google.maps.LatLng(
        37.87257583335618,
        32.4917828305815
      );
      var myOptions = {
        zoom: 13,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      this.drawMap = new google.maps.Map(myMapArea, myOptions);
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
        },
      });
      this.drawingManager.setMap(this.drawMap);

      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        function(event) {
          var newShape = event.overlay;
          newShape.type = event.type;
        }
      );

      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event) => {
          this.selectedPolygon = event.overlay;
          overlayClickListener(event.overlay);
          this.polygons = event.overlay.getPath().getArray();

          console.log("overlaycomplete", event.overlay.getPath().getArray());
        }
      );
    };
    function overlayClickListener(overlay) {
      google.maps.event.addListener(overlay, "mouseup", (event) => {
        console.log("overlayClickListener", overlay.getPath().getArray());
        this.polygons = overlay.getPath().getArray();
      });
    }

    initialize();
  }

  getGeocoder() {
    if (this.geocoder == null) {
      return (this.geocoder = new google.maps.Geocoder());
    }

    return this.geocoder;
  }

  getPolygons() {
    if (this.isSetPolynon) {
      return JSON.parse(
        JSON.stringify(this.customPolygon.getPath().getArray())
      );
    }
    return JSON.parse(JSON.stringify(this.polygons));
  }

  isPointInside(position, polygons) {
    for (let i = 0; i < polygons.length; i++) {
      if (
        google.maps.geometry.poly.containsLocation(
          position,
          new google.maps.Polygon({
            paths: polygons[i].path,
          })
        )
      )
        return polygons[i];
    }
    return false;
  }

  geocodeAddress(addr) {
    return new Promise((resolve, reject) => {
      this.getGeocoder().geocode({ address: addr }, function(results, status) {
        if (status === "OK") {
          resolve(results[0].geometry.location);
        } else {
          reject(status);
        }
      });
    });
  }
}

export default Maps;
