<template>
  <div class="row">
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.INTEGRATIONBARCODE"
        nolabel
        :placeholder="$t('search.barcode')"
      ></m-input>
    </div>
    <div class="col-12">
      <span class="text-center" style="display: block">{{
        $t("search.savedate")
      }}</span>
    </div>
    <div class="col-6">
      {{ $t("search.startdate") }}
      <m-input
        class="search-form-field"
        v-model="search.SAVEDATE[0]"
        nolabel
        type="date"
      ></m-input>
    </div>
    <div class="col-6">
      {{ $t("search.enddate") }}
      <m-input
        class="search-form-field"
        type="date"
        v-model="search.SAVEDATE[1]"
        nolabel
      ></m-input>
    </div>
    <div class="col-12">
      <span class="text-center" style="display: block">{{
        $t("search.taker")
      }}</span>
    </div>
    <div class="col-12">
      <m-input
        class="search-form-field"
        nolabel
        :placeholder="$t('search.takername')"
        v-model="search.TAKERFULLNAME"
      ></m-input>
      <m-input
        class="search-form-field"
        nolabel
        :placeholder="$t('search.takerfirmname')"
        v-model="search.TAKERFIRMNAME"
      ></m-input>

      <m-input
        class="search-form-field"
        nolabel
        :placeholder="$t('search.takeridentityno')"
        v-model="search.TAKERIDENTITYNO"
      ></m-input>
    </div>
    <div class="col-12">
      {{ $t("search.istransfered") }}
      <m-select
        class="search-form-field"
        v-model="search.ISTRANSFERED"
        nolabel
        :options="options.yesNo"
      ></m-select>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        INTEGRATIONBARCODE: null,
        SAVEDATE: [null, null],
        TAKERFIRMNAME: null,
        TAKERFULLNAME: null,
        TAKERIDENTITYNO: null,
        ISTRANSFERED: null,
      },
      options: {
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
    this.getReseller();
  },
  methods: {
    getReseller() {
      api.getReseller().then((res) => {
        if (res.data.status) {
          this.options.reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        INTEGRATIONBARCODE: null,
        SAVEDATE: [null, null],
        TAKERFIRMNAME: null,
        TAKERFULLNAME: null,
        TAKERIDENTITYNO: null,
        ISTRANSFERED: null,
      };
    },
  },
};
</script>

<style></style>
