<template>
  <b-form-group
    label-for="input-horizontal"
    :label-cols-sm="nolabel ? 0 : 4"
    :label="getLabel"
    label-size="sm"
    label-align="right"
  >
    <b-form-textarea
      v-model="vValue"
      size="sm"
      small
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: String,
    label: {
      type: String,
      default: () => null,
    },
    langlabel: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => "text",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    nolabel: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    getLabel() {
      if (this.label == null && this.langlabel != null) {
        return this.$t("form." + this.langlabel);
      }
      return this.label;
    },
    vValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
