<template>
  <m-app>
    <div class="m-list-container">
      <div class="m-list-search-area">
        <div class="m-list-input-area">
          <slot></slot>
        </div>
        <div v-show="this.gMapCountRemain > 0" class="m-list-info-area">Kalan Kontör: {{ gMapCountRemain }}</div>
        <div class="m-list-info-area">Kayıt Sayısı: {{ rowCount }}</div>
        <div class="m-list-button-area">
          <b-button-group class="special" size="sm" block>
            <b-button variant="success" @click="search">{{
              $t("btn.search")
            }}</b-button>
            <b-button variant="danger" @click="clear">{{
              $t("btn.clear")
            }}</b-button>
            <!--
            <b-button variant="primary" @click="getAll">{{
              $t("btn.all")
            }}</b-button>
            -->
          </b-button-group>
        </div>
      </div>
      <div class="m-list-grid" :class="{ 'm-list-grid-with-sum': isshowsum }">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark"
          :components="components"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDefs"
          xrowData="rowData"
          rowSelection="multiple"
          :enableColResize="true"
          @rowDoubleClicked="(row) => this.$emit('rowDoubleClicked', row)"
          @cell-clicked="cellClicked"
          :gridOptions="gridOptions"
          :localeText="{ noRowsToShow: $t('grid.nodata') }"
          ref="myGrid"
          :rowBuffer="0"
          rowModelType="infinite"
          :paginationPageSize="100"
          :cacheOverflowSize="2"
          :maxConcurrentDatasourceRequests="1"
          :infiniteInitialRowCount="1"
          :maxBlocksInCache="10"
          :gridReady="$emit('gridReady')"
          multiSortKey="ctrl"
        ></ag-grid-vue>

        <m-grid-col-settings
          ref="gridColSettings"
          :gridOptions="gridOptions"
          :type="type"
          v-if="gridColEditor"
          :gridRulesUserDefined="gridRulesUserDefined"
        />
      </div>
      <div class="m-list-grid-sum" v-if="isshowsum">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark"
          :columnDefs="sumcolumnDefs"
          :rowData="sumrowData"
          :localeText="{ noRowsToShow: $t('grid.nodata') }"
        ></ag-grid-vue>
      </div>
    </div>
    <div class="m-list-button-area">
      <slot name="footer"></slot>
    </div>

    <div class="gridcol-settings">
      <div
        class="gridcol-settings-button"
        @click="saveGridCol"
        v-show="gridColEditor"
      >
        <b-icon-check scale="1.5" />
      </div>
      <div
        class="gridcol-settings-button gridcol-settings-button-blue"
        @click="gridColEditor = !gridColEditor"
      >
        <b-icon-menu-button scale="1.5" />
      </div>
    </div>
  </m-app>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "../../libs/GridValueFormatter";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import YesNoVue from "../Base/Form/YesNo.vue";
import copy from "copy-to-clipboard";
export default {
  props: {
    cols: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    isshowsum: {
      type: Boolean,
      default: () => false,
    },
    sumcols: {
      type: Array,
      default: () => [],
    },
    sumrows: {
      type: Array,
      default: () => [],
    },
    rowCount: {
      default: () => 0,
    },
    gMapCountRemain: {
      default: () => 0,
    },
    type: String,
    gridRulesUserDefined: {
      default: () => [],
    },
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      defaultColDefs: {
        sortable: true,
        resizable: true,
      },
      columnDefs: null,
      rowData: [],
      components: null,
      gridOptions: null,

      sumcolumnDefs: null,
      sumrowData: [],
      sumgridOptions: null,

      gridColEditor: false,
      cellClicked: (event) => {
        copy(event.value, {
          // debug: true,
          // message: 'Press #{key} to copy',
        });
      },
    };
  },
  beforeMount() {
    this.gridOptions = {
      defaultColDef: {
        resizable: true,
      },
    };
    this.sumgridOptions = {};
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.sumgridApi = this.sumgridOptions.api;
  },
  methods: {
    search() {
      this.$emit("search");
    },
    clear() {
      this.$emit("clear");
    },
    getAll() {
      this.$emit("get-all");
    },
    getSelectedRows() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    getGridOptions() {
      return this.gridOptions;
    },
    getGridApi() {
      return this.gridApi;
    },
    showGridColEditor() {
      this.gridColEditor = true;
    },
    saveGridCol() {
      this.$refs.gridColSettings.save();
    },
  },
  watch: {
    cols() {
      this.$nextTick(() => {
        //json ile çektiğimiz için valueFormatter bu şekilde
        this.columnDefs = this.cols.map((item) => {
          if ("valueFormatter" in item) {
            switch (item.valueFormatter) {
              case "yesNo":
                item.valueFormatter = yesNo;
                break;
            }
          }
          //dil dönüşümü
          item.headerName = this.$t("list." + item.field);
          return item;
        });
      });
    },
    rows() {
      this.rowData = this.rows;
    },
    sumcols() {
      this.$nextTick(() => {
        //json ile çektiğimiz için valueFormatter bu şekilde
        this.sumcolumnDefs = this.sumcols;
      });
    },
    sumrows() {
      this.sumrowData = this.sumrows;
    },
  },
};
</script>

<style lang="scss">
.m-list-container {
  width: 100%;
  height: calc(100% - 34px);

  .m-list-search-area {
    width: 275px;
    float: left;
    height: 100%;

    .m-list-input-area {
      padding: 5px;
      overflow: auto;
      width: 100%;
      height: calc(100% - 53px);
    }

    .m-list-info-area {
      width: 100%;
      height: 20px;
      border-top: 2px solid #777;
      text-align: center;
    }

    .m-list-button-area {
      width: 100%;
      height: 33px;
      border-top: 2px solid #777;

      .btn-group.special {
        display: flex;
      }

      .special .btn {
        flex: 1;
      }
    }
  }
  .m-list-grid {
    width: calc(100% - 275px);
    float: left;
    border-left: 2px solid #777;
    height: 100%;
    position: relative;

    &.m-list-grid-with-sum {
      height: 75% !important;
    }
  }

  .m-list-grid-sum {
    width: calc(100% - 275px);
    float: left;
    border-left: 2px solid #777;
    height: 25%;
  }
}

.m-list-button-area {
  width: 100%;
  height: 34px;
  border-top: 2px solid #777;
}

.gridcol-settings {
  max-width: 66px;
  min-width: 33px;
  height: 33px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #35224ff1;
  z-index: 99;

  .gridcol-settings-button {
    float: left;
    width: 33px;
    height: 33px;
    cursor: pointer;
    text-align: center;
    padding-top: 8px;
    background-color: transparent;

    &:hover {
      background-color: rgb(47, 79, 79);
    }

    &-blue {
      &:hover {
        background-color: rgb(72, 61, 139);
      }
    }
  }
}
</style>
