<template>
  <b-form-group
    :label-cols-sm="nolabel ? 0 : 4"
    :label="nolabel ? '' : getLabel"
    label-size="sm"
    label-align="right"
  >
    <b-input-group>
      <b-form-input
        :state="validate != null ? !validate.$invalid : null"
        v-on="$listeners"
        :readonly="readonly"
        v-model="vValue"
        size="sm"
        small
        :type="type"
        v-if="type != 'number'"
        :placeholder="placeholder"
      ></b-form-input>
      <b-form-input
        :state="validate != null ? !validate.$invalid : null"
        v-on="$listeners"
        :readonly="readonly"
        v-model="vValue"
        size="sm"
        small
        type="text"
        :placeholder="placeholder"
        v-numericOnly
        v-else
      ></b-form-input>
      <b-form-invalid-feedback v-if="validate != null && validate.$dirty">
        <template v-for="item in validate.$params">
          <p :key="`${item.type}`" v-if="!validate[item.type]">
            {{ getMessage(item) }}
          </p>
        </template>
      </b-form-invalid-feedback>
    </b-input-group>
    <b-form-text v-text="desc" v-show="desc != null" />
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: () => null,
    },
    langlabel: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => "text",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    nolabel: {
      type: Boolean,
      default: () => false,
    },
    validate: {
      default: () => null,
      type: Object,
    },
    desc: {
      default: () => null,
      type: String,
    },
  },
  computed: {
    getLabel() {
      if (this.label == null && this.langlabel != null) {
        return (
          this.$t("form." + this.langlabel) + (this.isRequired ? " *" : "")
        );
      }
      return this.label + (this.isRequired ? " *" : "");
    },
    vValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      isRequired: false,
    };
  },
  methods: {
    getMessage(item) {
      switch (item.type) {
        case "required":
          this.isRequired = true;
          return `${this.getLabel} boş olamaz!`;
          break;
        case "email":
          return `${this.getLabel} geçersiz!`;
          break;
        case "numeric":
          return `${this.getLabel} sayısal olmalıdır!`;
          break;
        case "minLength":
          return `${this.getLabel} en az ${item.min} karakter olmalıdır!`;
          break;
        case "maxLength":
          return `${this.getLabel} en fazla ${item.max} karakter olmalıdır!`;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.col-form-label-sm {
  font-size: inherit !important;
}
.form-control-sm {
  font-size: inherit !important;
}
.invalid-feedback p {
  margin-bottom: 0px;
}
</style>
