<template>
  <m-basic-form :showButtons="false">
    <div class="row">
      <div class="col-12">
        <h6>Entegrasyon EXCEL Dosyası Yükleme İşlemi</h6>
      </div>
      <div class="col-12">
        <input type="file" ref="file" />
        <br />
      </div>
      <div class="col-12 mt-3">
        <b-button @click="upload" size="sm" squared variant="info"
          >Yükle</b-button
        >
      </div>
    </div>
    <m-loading :msg="loadingMsg" v-if="loadingShow" />
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {},
  data() {
    return {
      file: null,
      loadingShow: false,
      loadingMsg: false,
    };
  },
  methods: {
    upload() {
      const upFile = this.$refs.file.files[0];

      if (["xls", "xlsx"].indexOf(upFile.name.split(".").pop()) < 0) {
        swal(
          this.$t("dialog.notice"),
          "Sadece xls ve xlsx uzantılı dosyalar yüklenebilir!",
          "error"
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", upFile);

      this.loadingShow = true;
      api
        .upload(formData)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            swal({
              title: "Dosyanız yüklendi.",
              text:
                "Dosyayı işlemeye başlanılsın mı? Vazgeçerseniz tekrar yükelemeniz gereklidir.",
              icon: "warning",
              buttons: ["Vazgeç", "Evet"],
            }).then((willAllowed) => {
              if (willAllowed) {
                this.loadingMsg =
                  "Bu işlem uzun sürebilir. Lütfen kapatmayınız..";
                this.loadingShow = true;
                //işlemeye başla
                const data = {
                  file: res.data.data.file,
                };
                api
                  .integration(data)
                  .then((integrationRes) => {
                    if (integrationRes.data.status) {
                      let msg = `${integrationRes.data.data.rowCount} satır işlendi.<br>`;
                      msg += `${integrationRes.data.data.successCount} satır başarılı şekilde sisteme akatarıldı.<br>`;
                      if (integrationRes.data.data.isHaveErr) {
                        msg += `${
                          integrationRes.data.data.rowCount -
                          integrationRes.data.data.successCount
                        } satır hatalı olduğu için aktarılamadı.<br>`;
                        msg += `Hatalar<br>`;
                        msg += integrationRes.data.data.errs.join("<br>");
                      }
                      const div = document.createElement("div");
                      div.style.color = "black";
                      div.innerHTML = msg;
                      swal({
                        type: "notice",
                        title: "İşlem Sonucu",
                        content: div,
                      });
                    } else {
                      swal(
                        this.$t("dialog.notice"),
                        this.$t("dialog.anerrorhasccurred") +
                          integrationRes.data.errMsg,
                        "error"
                      );
                    }
                  })
                  .catch((err) => {
                    swal(
                      this.$t("dialog.systemerror"),
                      this.$t("dialog.anerrorhasccurred") + err.toString(),
                      "error"
                    );
                  })
                  .finally(() => {
                    this.loadingShow = false;
                  });
              }
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
