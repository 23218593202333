<template>
  <m-basic-form :formId="null" :showButtons="false">
    <div class="row">
      <div class="col-12">
        <h5 style="text-align: center">{{ $t("info.cargoinfo") }}</h5>
      </div>
      <div class="col-4">
        <m-input readonly v-model="form.BARCODE" langlabel="cargobarcode" />
        <m-input readonly v-model="form.INTEGRATIONBARCODE" langlabel="mediatebarcode" />
        <m-input readonly v-model="isBroker" langlabel="ismediatecargo" />
        <m-input readonly v-model="form.BROKERAGETITLE" langlabel="mediate" />
        <m-input readonly v-model="form.BROKERAGEAGENTTITLE" langlabel="mediateagent" />
        <m-input readonly v-model="saveDateTime" langlabel="savedate" />
        <m-input readonly v-model="saveUserFull" langlabel="saveuser" />
      </div>
      <div class="col-4">
        <m-input readonly v-model="deliveryType" langlabel="deliverytype" />
        <m-input readonly v-model="form.TRACKINGNO" langlabel="trackingno" />
        <m-input readonly v-model="form.PIECE" langlabel="cargopiece" />
        <m-input readonly v-model="form.PAYTYPENAME" langlabel="paytype" />
        <m-input readonly v-model="form.SENDINGTYPENAME" langlabel="sendingtype" />
        <m-input readonly v-model="form.PACKAGENAME" langlabel="package" />
        <m-input readonly v-model="form.DESI" langlabel="deci" />
        <m-input readonly v-model="form.WEIGHT" langlabel="weight" />
      </div>
      <div class="col-4">
        <m-input readonly v-model="form.STATICPRICEAMOUNT" langlabel="staticprice" />
        <m-input readonly v-model="form.BOXAMOUNT" langlabel="boxamount" />

        <m-input readonly v-model="form.NETAMOUNT" langlabel="shippingamount" />
        <m-input readonly v-model="form.PRODUCTPRICE" langlabel="productprice" />
        <m-input readonly v-model="form.PAYMENTDOORPRICE" langlabel="payingdooramount" />
        <m-input readonly v-model="form.TOTALAMOUNT" langlabel="frombuyeramount" />
        <m-textarea readonly v-model="form.DESCRIPTION" langlabel="description" />
      </div>
    </div>
    <div class="row">
      <div :class="anotherInvoice ? 'col-4' : 'col-6'">
        <h6 style="text-align: center">{{ $t("info.sender") }}</h6>
        <m-input readonly v-model="form.SENDERRESELLERNAME" langlabel="senderagent" />
        <m-input readonly v-model="form.SENDERFIRMNAME" langlabel="senderfirmname" />
        <m-input readonly v-model="form.SENDERFULLNAME" langlabel="sendername" />
        <m-input readonly v-model="form.SENDERGSM" langlabel="senderphone" />

        <m-input readonly v-model="form.SENDERCITYNAME" langlabel="sendercity" />
        <m-input readonly v-model="form.SENDERCOUNTYNAME" langlabel="sendercounty" />
        <m-input readonly v-model="form.SENDERTOWNNAME" langlabel="senderneighborhood" />
      </div>
      <div :class="anotherInvoice ? 'col-4' : 'col-6'">
        <h6 style="text-align: center">{{ $t("info.taker") }}</h6>
        <m-input readonly v-model="form.TAKERRESELLERNAME" langlabel="takeragent" />
        <m-input readonly v-model="form.TAKERFIRMNAME" langlabel="takerfirmname" />
        <m-input readonly v-model="form.TAKERFULLNAME" langlabel="takername" />
        <m-input readonly v-model="form.TAKERGSM" langlabel="takerphone" />

        <m-input readonly v-model="form.TAKERCITYNAME" langlabel="takercity" />
        <m-input readonly v-model="form.TAKERCOUNTYNAME" langlabel="takercounty" />
        <m-input readonly v-model="form.TAKERTOWNNAME" langlabel="takerneighborhood" />
      </div>

      <div class="col-4" v-if="anotherInvoice">
        <h6 style="text-align: center">Fatura Edilecek Müşteri</h6>

        <m-input readonly v-model="form.ANOTHERFIRMNAME" langlabel="anotherinvoicefirmname" />
        <m-input readonly v-model="form.ANOTHERFULLNAME" langlabel="anotherinvoicename" />
        <m-input readonly v-model="form.ANOTHERGSM" langlabel="anotherinvoicephone" />

        <m-input readonly v-model="form.ANOTHERCITYNAME" langlabel="anotherinvoicecity" />
        <m-input readonly v-model="form.ANOTHERCOUNTYNAME" langlabel="anotherinvoicecounty" />
        <m-input readonly v-model="form.ANOTHERTOWNNAME" langlabel="anotherinvoiceneighborhood" />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <h6 style="text-align: center">{{ $t("form.cancelstatus") }}</h6>
        <m-input readonly v-model="isCancel" langlabel="iscancel" />
        <m-input readonly v-model="form.CANCELDESCRIPTION" langlabel="canceldescription" />
        <m-input readonly v-model="cancelDateTime" langlabel="canceldate" />
        <m-input readonly v-model="cancelUserFull" langlabel="canceluser" />
      </div>
      <div class="col-3">
        <h6 style="text-align: center">{{ $t("form.informationform") }}</h6>
        <m-input readonly v-model="isInformationForm" langlabel="isinformationform" />
        <m-input readonly v-model="informationFormDateTime" langlabel="informationformdate" />
        <m-input readonly v-model="informationFormUserFull" langlabel="informationformuser" />
      </div>
      <div class="col-3">
        <h6 style="text-align: center">{{ $t("form.deliverystatus") }}</h6>
        <m-input readonly v-model="isDelivery" langlabel="isdelivery" />
        <m-input readonly v-model="deliveryDateTime" langlabel="deliverydate" />
        <m-input readonly v-model="deliveryUserFull" langlabel="deliveryuser" />
        <m-input readonly v-model="form.DELIVEREDFULLNAME" langlabel="deliveredfullname" />
        <m-input readonly v-model="form.DELIVEREDIDENTITYNO" langlabel="deliveredidentityno" />
      </div>
      <div class="col-3">
        <h6 style="text-align: center">{{ $t("form.returnstatus") }}</h6>
        <m-input readonly v-model="isReturn" langlabel="isreturn" />
        <m-input readonly v-model="returnDateTime" langlabel="returndate" />
        <m-input readonly v-model="returnUserFull" langlabel="returnuser" />
        <m-input readonly v-model="form.RETURNVARIABLENAME" langlabel="returnreason" />
      </div>
    </div>


    <div class="row" v-if="this.apicargo || this.client">
      <div class="col-12">
        <h5 style="text-align: center">İşlemler</h5>
      </div>
      <div class="col-12">
        <b-table-simple style="color: white !important">
          <b-thead>
            <b-tr>
              <b-th style="color: white !important; text-align: center">
                İşlem
              </b-th>
              <b-th style="color: white !important; text-align: center">
                İşlem Tarihi
              </b-th>
              <b-th style="color: white !important; text-align: center">
                İşlem Saati
              </b-th>
              <b-th style="color: white !important; text-align: center">
                Açıklamalar
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="apicargo">
              <b-td>Entegrasyon Kargo Aktarımı</b-td>
              <b-td>{{ apicargo.SAVEDATE }}</b-td>
              <b-td>{{ apicargo.SAVETIME }}</b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr>
              <b-td>Sisteme Kayıt Edildi/Aktarıldı</b-td>
              <b-td>{{ form.SAVEDATE }}</b-td>
              <b-td>{{ form.SAVETIME }}</b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr v-if="form.ISCANCEL == '1'">
              <b-td>İptal Edildi</b-td>
              <b-td>{{ form.CANCELDATE }}</b-td>
              <b-td>{{ form.CANCELTIME }}</b-td>
              <b-td>
                İptal Eden: {{ cancelUserFull }}<br>
                İptal Sebebi: {{ form.CANCELDESCRIPTION }}
              </b-td>
            </b-tr>
            <b-tr v-if="form.ACCEPTCLIENTTIME && form.ACCEPTCLIENTDATE">
              <b-td>Dağıtıcı Birim Kabul Etti</b-td>
              <b-td>{{ form.ACCEPTCLIENTDATE }}</b-td>
              <b-td>{{ form.ACCEPTCLIENTTIME }}</b-td>
              <b-td></b-td>
            </b-tr>
            <b-tr v-for="(item, index) in client" :key="index">
              <b-td>
                {{ clientTransConvert(item.TRASNSACTIONTYPE) }}
                <small style="color:red">({{ item.TRASNSACTIONMESSAGE }})</small>
              </b-td>
              <b-td><small>Sisteme Bildirme Tarihi:</small>{{ item.SAVEDATE }}<br><small>İşlem
                  Tarihi:</small>{{ item.TRASNSACTIONDATE }}</b-td>
              <b-td><small>Sisteme Bildirme Saati:</small>{{ item.SAVETIME }}<br><small>İşlem
                  Saati:</small>{{ item.TRASNSACTIONTIME }}</b-td>
              <b-td>
                <template v-if="item.CITY">
                  <span>İl: {{ item.CITY }}</span><br>
                </template>
                <template v-if="item.COUNTRY">
                  <span>İlçe: {{ item.COUNTRY }}</span><br>
                </template>
                <template v-if="item.AGENTNAME">
                  <span>Şube Adı: {{ item.AGENTNAME }}</span><br>
                </template>
                <template v-if="item.VEHICLEPLATE">
                  <span>Araç Plakası: {{ item.VEHICLEPLATE }}</span><br>
                </template>
                <template v-if="item.PERSONALFULLNAME">
                  <span>İşlem Yapan Personel: {{ item.PERSONALFULLNAME }}</span><br>
                </template>
                <small style="color:red">Dağıtıcı Birimden Gelen Bilgi</small>
              </b-td>
            </b-tr>
            <b-tr v-if="form.ISDELIVERY == '1'">
              <b-td>Teslim Edildi</b-td>
              <b-td>{{ form.DELIVERYDATE }}</b-td>
              <b-td>{{ form.DELIVERYTIME }}</b-td>
              <b-td>
                Teslim Edilen TC: {{ form.DELIVEREDIDENTITYNO }}<br>
                Teslim Edilen: {{ form.DELIVEREDFULLNAME }}
              </b-td>
            </b-tr>

            <b-tr v-if="form.ISRETURN == '1'">
              <b-td>İade Edildi</b-td>
              <b-td>{{ form.RETURNDATE }}</b-td>
              <b-td>{{ form.RETURNTIME }}</b-td>
              <b-td>
                İade Sebebi: {{ form.RETURNVARIABLENAME }}
              </b-td>
            </b-tr>

          </b-tbody>
        </b-table-simple>
      </div>
    </div>

    <div class="row" v-if="form.ISCLIENTCARGO=='1'">
      <div class="col-12">
        <h5 style="text-align: center">Dağıtıcı Birim Bilgileri</h5>
      </div>
      <div class="col-6">Dağıtıcı Birim</div>
      <div class="col-6">{{form.CLIENTNAME}}</div>
      <div class="col-6">Dağıtıcı Birim Gönderim Durumu</div>
      <div class="col-6">{{form.CLIENTCARGOSENDED=='1'?'Gönderildi':'Gönderilemedi'}} <b-btn variant="success"
          v-if="form.CLIENTCARGOSENDED=='0' && form.CLIENTCARGOERROR" size="sm" @click="resendClientCargo">Yeniden
          Gönder</b-btn>
      </div>
      <div class="col-6">Dağıtıcı Birim Gönderim Hatası</div>
      <div class="col-6">{{form.CLIENTCARGOERROR}}
      </div>

      <div class="col-6">Dağıtıcı Birim Barkodu</div>
      <div class="col-6">{{form.AGENTBARCODE}}</div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5 style="text-align: center">{{ $t("info.cargotrans") }}</h5>
      </div>
      <div class="col-12">
        <b-table-simple style="color: white !important">
          <b-thead>
            <b-tr>
              <b-th style="color: white !important; text-align: center">
                {{ $t("table.barcode") }}
              </b-th>
              <b-th style="color: white !important; text-align: center">
                {{ $t("table.action") }}
              </b-th>
              <b-th style="color: white !important; text-align: center">
                {{ $t("table.actiondate") }}
              </b-th>
              <b-th style="color: white !important; text-align: center">
                {{ $t("table.actionuser") }}
              </b-th>
              <b-th style="color: white !important; text-align: center">
                {{ $t("table.actionagent") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item, index) in trans" :key="index">
              <b-td>{{ item.barcode }}</b-td>
              <b-td>{{ item.action }}
                {{
                item.vehiclebarcode ? " (" + item.vehiclebarcode + ")" : ""
                }}</b-td>
              <b-td>{{ item.date }}</b-td>
              <b-td>{{ item.user }}</b-td>
              <b-td>{{ item.reseller }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>

    <div class="row" v-if="photos.length">
      <div class="col-12">
        <h5 style="text-align: center">{{ $t("info.cargodeliveryphotos") }}</h5>
      </div>
      <div class="col-4" v-for="photo in photos">
        <a target="_blank" :href="photo.PHOTODATA">
          <img :src="photo.PHOTODATA" style="width: 100%;" />
        </a>
      </div>
    </div>
    <div class="leftpanel" v-show="getPerms('deliveryreturnaction')">
      <b-button block size="sm" variant="success" @click="deliveryToggle">{{
      form.ISDELIVERY === "1" ? "Teslimi Geri Al" : "Teslim Et"
      }}</b-button>
      <b-button block size="sm" variant="danger" @click="returnToggle">{{
      form.ISRETURN === "1" ? "İadeyi Geri Al" : "İade Et"
      }}</b-button>

      <b-button block size="sm" variant="warning" @click="acceptToggle">{{
          form.ISACCEPTCLIENT === "0" ? "Dağıtıcı Birim Kabul" : "Dağıtıcı Birim Kabul Geri Al"
        }}</b-button>

    </div>

    <b-modal id="deliveryModal" @ok="deliveryModalOk" @hidden="resetModalDeliveryForm" @show="resetModalDeliveryForm"
      title="Teslim İşlemi">
      Teslim Edilen TC No:
      <m-input nolabel type="number" v-model="modal.deliveryIdentityNo" />
      Teslim Edilen Adı Soyadı:
      <m-input nolabel v-model="modal.deliveredFullName" />
    </b-modal>
    <b-modal id="deliveryCancelModal" title="Teslim İptal İşlemi" @ok="deliveryCancelModalOk">
      <div class="d-block">
        <p>Teslim işlemini geri almak istediğinize emin misiniz?</p>
        Teslim İptal İşlemini Açıklaması
        <m-input nolabel v-model="modal.deliveryCancelVariable" />
      </div>
    </b-modal>

    <b-modal id="returnCancelModal" title="İade İptal İşlemi" @ok="returnCancelModalOk">
      <div class="d-block">
        <p>İade işlemini geri almak istediğinize emin misiniz?</p>
        İade İptal İşlemini Açıklaması
        <m-input nolabel v-model="modal.returnCancelVariable" />
      </div>
    </b-modal>

    <b-modal id="returnModal" @ok="returnModalOk" @hidden="resetModalReturnForm" @show="returnModalOpened"
      title="İade İşlemi">
      İade Sebebi Seçiniz
      <b-form-radio-group v-model="modal.returnVariableId" :options="modal.returnVariableList" />
    </b-modal>

    <b-modal v-model="dialog.cancelDialog" :cancel-title="$t('buton.docancel')" :ok-title="$t('dialog.cancelaction')"
      @show="resetCancelDialog" @hidden="resetCancelDialog" @ok="handleCancelDialogOk">
      <template #modal-title>{{ $t("dialog.canceltitle") }}</template>
      <div class="d-block">
        <p>{{ $t("dialog.iscancel") }}</p>
        {{ $t("dialog.cancelreason") }}
        <m-input nolabel v-model="dialog.cancelDialogVariable" :placeholder="$t('dialog.cancelreason')" />
      </div>
    </b-modal>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>
    <a :href="printerInvoiceUrl" v-show="false" ref="printerInvoiceBtn">Yazdır</a>
    <template slot="footer">
      <b-button @click="printAction" :disabled="ID == null" size="sm" squared variant="warning">{{
      $t("buton.printbarcode")
      }}</b-button>
      <b-button @click="printInvoiceAction" :disabled="ID == null" size="sm" squared variant="danger"
        v-if="getPerms('printinvoice')">{{ $t("buton.printinvoice") }}</b-button>
      <b-button @click="cancelCargo" :disabled="ID == null || form.ISCANCEL == '1'" size="sm" squared variant="info">{{
      $t("buton.docancel")
      }}</b-button>
    </template>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import { mapGetters } from "vuex";
import swal from "sweetalert";
import Textarea from "../../components/Base/Form/Textarea.vue";
import cfg from '../../config';
export default {
  components: { Textarea },
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getTrack(this.ID);
    }
  },
  computed: {
    ...mapGetters(["getPerms"]),
    printerUrl() {
      return `prdr://invoicebarcode_temp%20ID=${this.ID}%200`;
    },
    printerInvoiceUrl() {
      return `prdr://invoice_temp%20ID=${this.ID}%200`;
    },
    deliveryType() {
      if (this.form.DELIVERYTYPE == "1") {
        return this.$t("form.deliveryaddress");
      } else {
        return this.$t("form.deliveryagent");
      }
    },
    isBroker() {
      if (this.form.ISBROKER || this.form.ISBROKERAGES) {
        if (this.form.ISBROKER == "1" || this.form.ISBROKERAGES == "1") {
          return "Evet";
        }
      }

      return "Hayır";
    },
    isCancel() {
      if (this.form.ISCANCEL) {
        if (this.form.ISCANCEL == "1") {
          return "Evet";
        }
      }

      return "Hayır";
    },
    isDelivery() {
      if (this.form.ISDELIVERY) {
        if (this.form.ISDELIVERY == "1") {
          return "Evet";
        }
      }

      return "Hayır";
    },
    isInformationForm() {
      if (this.form.ISINFORMATIONFORM) {
        if (this.form.ISINFORMATIONFORM == "1") {
          return "Evet";
        }
      }

      return "Hayır";
    },
    isReturn() {
      if (this.form.ISRETURN) {
        if (this.form.ISRETURN == "1") {
          return "Evet";
        }
      }

      return "Hayır";
    },
    saveDateTime() {
      return new Date(
        this.form.SAVEDATE + " " + this.form.SAVETIME
      ).toLocaleString();
    },
    saveUserFull() {
      return (
        this.form.SAVEFIRSTNAME +
        " " +
        this.form.SAVELASTNAME +
        " (" +
        this.form.SAVEUSERNAME +
        ")"
      );
    },
    cancelDateTime() {
      if (this.form.ISCANCEL == "1") {
        return new Date(
          this.form.CANCELDATE + " " + this.form.CANCELTIME
        ).toLocaleString();
      }
      return null;
    },
    cancelUserFull() {
      if (this.form.ISCANCEL == "1") {
        return (
          this.form.CANCELFIRSTNAME +
          " " +
          this.form.CANCELLASTNAME +
          " (" +
          this.form.CANCELUSERNAME +
          ")"
        );
      }
      return null;
    },

    informationFormDateTime() {
      if (this.form.INFORMATIONFORM == "1") {
        return new Date(
          this.form.INFORMATIONFORMDATE + " " + this.form.INFORMATIONFORMTIME
        ).toLocaleString();
      }
      return null;
    },
    informationFormUserFull() {
      if (this.form.INFORMATIONFORM == "1") {
        return (
          this.form.INFORMATIONFORMFIRSTNAME +
          " " +
          this.form.INFORMATIONFORMLASTNAME +
          " (" +
          this.form.INFORMATIONFORMUSERNAME +
          ")"
        );
      }
      return null;
    },

    deliveryDateTime() {
      if (this.form.ISDELIVERY == "1") {
        return new Date(
          this.form.DELIVERYDATE + " " + this.form.DELIVERYTIME
        ).toLocaleString();
      }
      return null;
    },
    deliveryUserFull() {
      if (this.form.ISDELIVERY == "1") {
        return (
          this.form.DELIVERYFIRSTNAME +
          " " +
          this.form.DELIVERYLASTNAME +
          " (" +
          this.form.DELIVERYUSERNAME +
          ")"
        );
      }
      return null;
    },

    returnDateTime() {
      if (this.form.ISRETURN == "1") {
        return new Date(
          this.form.RETURNDATE + " " + this.form.RETURNTIME
        ).toLocaleString();
      }
      return null;
    },
    returnUserFull() {
      if (this.form.ISRETURN == "1") {
        return (
          this.form.RETURNFIRSTNAME +
          " " +
          this.form.RETURNLASTNAME +
          " (" +
          this.form.RETURNUSERNAME +
          ")"
        );
      }
      return null;
    },
  },
  data() {
    return {
      ID: null,
      form: {},
      loadingShow: false,
      loadingMsg: null,
      trans: [],
      photos: [],
      client: [],
      apicargo: {
        SAVEDATE: null,
        SAVETIME: null,
      },

      anotherInvoice: cfg.USE_ANOTHER_INVOICE,


      modal: {
        deliveryCancelVariable: null,
        deliveryIdentityNo: null,
        deliveredFullName: null,

        returnVariableList: [],

        returnCancelVariable: null,
        returnVariableId: null,
      },

      dialog: {
        cancelDialog: false,
        cancelDialogVariable: null,
      },
    };
  },
  methods: {
    resendClientCargo() {
      this.loadingMsg = 'Dağıtıcı Birime Gönderiliyor';
      this.loadingShow = true;
      api
        .resendClientCargo(this.ID
        )
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              this.$t("dialog.cancelok"),
              "success"
            );
            this.form.CLIENTCARGOSENDED = 1;
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    clientTransConvert(val) {
      switch (val.toString()) {
        case "0":
          return 'Araç Yükleme';
          break;
        case "1":
          return 'Araç İndirme';
          break;
        case "2":
          return 'Dağıtıcı Şubede';
          break;
        case "3":
          return 'Teslim Edilmek Üzere Kuryede';
          break;
        case "4":
          return 'Kargonuz Dağıtıma Çıktı';
        break;
        case "5":
          return 'Kargonuz Teslim Edildi';
        break;
        case "6":
          return 'Kargo İade Süreci Başladı';
        break;
        case "7":
          return 'Kargo İade Edildi';
        break;
        case "8":
          return 'Teslim Edilemedi, Tekrardan Dağıtıma Çıkarılacak';
        break;
        case "9":
          return 'Kargonuz İptal Edildi';
        break;
        case "10":
          return 'Dağıtım Sorumlusu İade/Devir';
        break;
        case "99":
          return 'Sorgulama İşlemi Başarısız';
        break;
        default:
          return val;
        break;
      }
    },
    printInvoiceAction() {
      // this.$refs.printerInvoiceBtn.click();
      window.top.location.href = `prdr://invoice_temp%20ID=${this.ID}%200`; // +
    },
    resetCancelDialog() {
      this.dialog.cancelDialogVariable = null;
    },
    handleCancelDialogOk() {
      const variable = this.dialog.cancelDialogVariable;
      if (variable != null && variable.length < 3) {
        swal(
          this.$t("dialog.notice"),
          this.$t("validate.canceldescriptionnotnull"),
          "error"
        );
        return;
      }

      this.loadingMsg = this.$t("info.cancellingcargo");
      this.loadingShow = true;
      api
        .setCargoCancel({
          id: this.ID,
          variable,
        })
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              this.$t("dialog.cancelok"),
              "success"
            );
            this.form.ISCANCEL = 1;
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    printAction() {
      // this.$refs.printerBtn.click();
      window.top.location.href = `prdr://invoicebarcode_temp%20ID=${this.ID}%200`; // +
    },
    cancelCargo() {
      if (!this.getPerms("cancelcargo")) {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.noauthforcancel"),
          "error"
        );
        return;
      }
      this.dialog.cancelDialog = true;
    },
    getReturnVariable() {
      api.getReturnVariable().then((res) => {
        if (res.data.status) {
          this.modal.returnVariableList = res.data.data.rows.map((item) => {
            return {
              value: item.ID,
              text: item.RETURNVARIABLENAME,
            };
          });
        }
      });
    },
    resetModalDeliveryForm() {
      this.modal.deliveryCancelVariable = null;
      this.modal.deliveryIdentityNo = null;
      this.modal.deliveredFullName = null;
    },
    deliveryToggle() {
      if (this.form.ISDELIVERY === "1") {
        this.$bvModal.show("deliveryCancelModal");
      } else {
        this.$bvModal.show("deliveryModal");
      }
    },
    deliveryCancelModalOk() {
      this.deliveryModalOk();
    },
    deliveryModalOk() {
      if (this.form.ISDELIVERY === "1") {
        if (!this.modal.deliveryCancelVariable) {
          swal(
            "Uyarı",
            "Teslim İptal İşlemini Açıklaması boş geçilemez!",
            "error"
          );
          return;
        }
      } else {
        if (!this.modal.deliveredFullName) {
          swal("Uyarı", "Teslim Edilen Adı Soyadı boş geçilemez!", "error");
          return;
        }
        if (!this.modal.deliveryIdentityNo) {
          swal("Uyarı", "Teslim Edilen TC No boş geçilemez!", "error");
          return;
        }

        if (
          this.modal.deliveryIdentityNo.length < 10 ||
          this.modal.deliveryIdentityNo.length > 11
        ) {
          swal(
            "Uyarı",
            "Teslim Edilen TC No min 10 max 11 karakter uzunluğunda olabilir!",
            "error"
          );
          return;
        }
      }

      api
        .setDeliveryStatus({
          type: this.form.ISDELIVERY === "1" ? "deliverycancel" : "delivery",
          id: this.ID,
          identityNo: this.modal.deliveryIdentityNo,
          fullName: this.modal.deliveredFullName,
          cancelVariable: this.modal.deliveryCancelVariable,
        })
        .then((res) => {
          if (res.data.status) {
            swal(
              "Başarılı",
              this.form.ISDELIVERY === "1"
                ? "Teslim İptal Etme işlemi başarıyla tamamlanmıştır."
                : "Teslim Etme işlemi başarıyla tamamlanmıştır.",
              "success"
            );
            this.form.ISDELIVERY = this.form.ISDELIVERY === "1" ? "0" : "1";
          } else {
            swal("Uyarı", "Bir hata oluştu: " + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal("Sistem Uyarısı", "Bir hata oluştu: " + err.toString(), "error");
        });
    },
    returnToggle() {
      if (this.form.ISRETURN === "1") {
        this.$bvModal.show("returnCancelModal");
      } else {
        this.$bvModal.show("returnModal");
      }
    },
    acceptToggle() {

      api.acceptCargo(this.ID, (this.form.ISACCEPTCLIENT == 1) ? 0 : 1)
          .then((res) => {
            if (res.data.status) {
              this.form.ISACCEPTCLIENT = this.form.ISACCEPTCLIENT === "1" ? "0" : "1";
              swal(
                  "Başarılı",
                  this.form.ISACCEPTCLIENT === "1"
                      ? "Dağıtıcı Birim Kabul işlemi başarıyla tamamlanmıştır."
                      : "Dağıtıcı Birim Kabul Geri Al işlemi gerçekleştirilmiştir.",
                  "success"
              );

              this.form.ACCEPTCLIENTTIME = res.data.data.row.ACCEPTCLIENTTIME;
              this.form.ACCEPTCLIENTDATE = res.data.data.row.ACCEPTCLIENTDATE;

            } else {
              swal("Uyarı", "Bir hata oluştu: " + res.data.errMsg, "error");
            }
          })
          .catch((err) => {
            swal("Sistem Uyarısı", "Bir hata oluştu: " + err.toString(), "error");
          });
    },
    returnModalOk() {
      if (this.form.ISRETURN === "1") {
        if (!this.modal.returnCancelVariable) {
          swal(
            "Uyarı",
            "İade İptal İşleminin Açıklaması boş geçilemez!",
            "error"
          );
          return;
        }
      } else {
        if (!this.modal.returnVariableId) {
          swal("Uyarı", "Lütfen iade sebebi seçiniz!", "error");
          return;
        }
      }

      api
        .setReturnStatus({
          type: this.form.ISRETURN === "1" ? "returncancel" : "return",
          id: this.ID,
          variableId: this.modal.returnVariableId,
          cancelVariable: this.modal.returnCancelVariable,
        })
        .then((res) => {
          if (res.data.status) {
            swal(
              "Başarılı",
              this.form.ISRETURN === "1"
                ? "İade İptal Etme işlemi başarıyla tamamlanmıştır."
                : "İade Etme işlemi başarıyla tamamlanmıştır.",
              "success"
            );
            this.form.ISRETURN = this.form.ISRETURN === "1" ? "0" : "1";
          } else {
            swal("Uyarı", "Bir hata oluştu: " + res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal("Sistem Uyarısı", "Bir hata oluştu: " + err.toString(), "error");
        });
    },
    resetModalReturnForm() {
      this.modal.returnCancelVariable = null;
      this.modal.returnVariableId = null;
    },
    returnModalOpened() {
      this.getReturnVariable();
      this.resetModalReturnForm();
    },
    returnCancelModalOk() {
      this.returnModalOk();
    },

    getTrack(id) {
      api.getCargoTrack(id).then((res) => {
        this.form = res.data.data.info[0];
        this.trans = res.data.data.trans;
        this.photos = res.data.data.photos;
        this.client = res.data.data.client;
        this.apicargo = res.data.data.apicargo;
        console.log(this.client)
      });
    },
  },
};
</script>

<style lang="scss">
.leftpanel {
  position: fixed;
  right: 25px;
  bottom: 45px;
  width: 120px;
  opacity: 0.2;
  padding: 5px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    opacity: 1;
    background: rgb(31, 29, 29);
  }
}
</style>
