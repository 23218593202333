<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-select
          :options="options.contracts"
          v-model="form.CONTRACTID"
          langlabel="contract"
        />

        <m-input v-model="form.ACTIONDATE" langlabel="actiondate" type="date" />

        <m-input
          v-model="form.CLAIMPAYED"
          type="number"
          langlabel="payedcustomer"
        />
        <m-textarea v-model="form.DESCRIPTION" langlabel="description" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
    }

    this.getContractList();
  },
  data() {
    return {
      ID: null,
      form: {
        CONTRACTID: null,
        ACTIONDATE: null,
        CLAIMPAYED: null,
        DESCRIPTION: null,
      },
      options: {
        contracts: [],
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    getContractList() {
      api
        .getContractList()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.contracts = res.data.data.rows.map((item) => {
              return {
                text:
                  (item.BROKERAGETITLE || "") +
                  (item.FIRMNAME || "") +
                  " " +
                  (item.FULLNAME || ""),
                value: item.ID,
              };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveContractPayedTrans(
          { ...this.form, CLAIMPAYED: toNumberFromTr(this.form.CLAIMPAYED) },
          this.ID
        )
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteCity(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
