<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.ROLENAME" langlabel="rolename" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-form-group :label="$t('info.grants')">
          <b-form-checkbox-group
            v-model="permissionSelected"
            :options="permissions"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  computed: {
    formRoles() {
      return this.permissions.map((item) => {
        const isSelected =
          this.permissionSelected.indexOf(item.value) > -1 ? 1 : 0;
        return {
          ROLEID: this.ID,
          PERMLISTID: item.value,
          PERMVALUE: isSelected,
        };
      });
    },
  },
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getRole(this.ID);
      this.getRolePerms(this.ID);
    }
    api.getPermission().then((res) => {
      if (res.data.status) {
        this.permissions = res.data.data.rows.map((item) => {
          return {
            text: item.PERMTITLE,
            value: item.ID,
          };
        });
      }
    });
  },
  data() {
    return {
      ID: null,
      form: {
        ROLENAME: null,
      },
      loadingShow: false,
      loadingMsg: null,

      permissions: [],
      permissionSelected: [],
    };
  },
  methods: {
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveRole(this.form, this.formRoles, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getRolePerms(id) {
      api.getRolePerms(id).then((res) => {
        if (res.data.status && res.data.data) {
          this.permissionSelected = res.data.data.rows
            .filter((item) => item.PERMVALUE == "1")
            .map((item) => item.PERMLISTID);
        }
      });
    },
    getRole(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getRoleOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const { ROLENAME } = res.data.data;
            this.form = { ROLENAME };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteRole(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
