<template>
  <m-basic-form
      @save="save"
      @remove="remove"
      :loadingMsg="loadingMsg"
      :loadingShow="loadingShow"
      :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.VEHICLENAME" langlabel="vehicleplate" />
        <m-input v-model="form.VEHICLEBARCODE" langlabel="vehiclebarcode" />
        <m-input v-model="form.SEAL_VALUE" langlabel="sealvalue" /> <!--langlabel labelda isim deperi-->
        <b-form-checkbox
            v-model="form.ISRINGVEHICLE"
        >
          {{ $t("info.isringvehicle") }}
        </b-form-checkbox>
      </div>
    </div>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>
    <template slot="footer">
      <b-button @click="printAction" size="sm" squared variant="warning"
      >Barkod Yazdır</b-button
      >
    </template>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getVehicle(this.ID);
    }
  },
  computed: {
    printerUrl() {
      return `prdr://vehiclebarcode_temp%20ID=${this.ID}%200`;
    },
  },
  data() {
    return {
      ID: null,
      form: {
        VEHICLENAME: null,
        VEHICLEBARCODE: null,
        ISRINGVEHICLE: false,
        SEAL_VALUE: null
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    printAction() {
      // this.$refs.printerBtn.click();
      window.top.location.href = `prdr://vehiclebarcode_temp%20ID=${this.ID}%200`; // +
    },
    saveVehicle(){
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
          .saveVehicle(this.form, this.ID)
          .then((res) => {
            if (res.data.status) {
              if (this.ID == res.data.data.ID) {
                swal({
                  icon: "success",
                  title: this.$t("dialog.success"),
                  text:
                      "Başarıyla kayıt güncellendi, barkod yazdırmak istiyor musunuz?",
                  buttons: ["Vazgeç", "Evet"],
                }).then((isOk) => {
                  if (isOk) {
                    this.printAction();
                  }
                });
              } else {
                this.ID = res.data.data.ID;
                swal({
                  icon: "success",
                  title: this.$t("dialog.success"),
                  text:
                      "Başarıyla kayıt edildi, barkod yazdırmak istiyor musunuz?",
                  buttons: ["Vazgeç", "Evet"],
                }).then((isOk) => {
                  if (isOk) {
                    this.printAction();
                  }
                });
              }
            } else {
              swal(
                  this.$t("dialog.notice"),
                  this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                  "error"
              );
            }
          })
          .catch((err) => {
            swal(
                this.$t("dialog.systemerror"),
                this.$t("dialog.anerrorhasccurred") + err.toString(),
                "error"
            );
          })
          .finally(() => {
            this.loadingShow = false;
          });
    },
    save() {
      if (!this.form.SEAL_VALUE) {
         swal({
          icon: "info",
          title: this.$t("dialog.info"),
          text: "Mühür no alanı boş, devam etmek istiyor musunuz?",
          buttons: ["Vazgeç", "Evet"], // İlk buton "Vazgeç" (Hayır), ikinci buton "Evet"
        }).then((isOk) => {
          if (isOk) {
            this.saveVehicle()
          }else{
            return;
          }
        });
      }else{
        this.saveVehicle()
      }
    },
    getVehicle(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
          .getVehicleOne(id)
          .then((res) => {
            if (res.data.status && !res.data.msg) {
              const { VEHICLENAME, VEHICLEBARCODE,ISRINGVEHICLE,SEAL_VALUE } = res.data.data;
              this.form = {
                VEHICLENAME,
                VEHICLEBARCODE,
                ISRINGVEHICLE,
                SEAL_VALUE
              };
            } else if (res.data.status && res.data.msg) {
              swal(this.$t("dialog.info"), res.data.msg, "info");
            } else {
              swal(
                  this.$t("dialog.notice"),
                  this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                  "error"
              );
            }
          })
          .catch((err) => {
            swal(
                this.$t("dialog.systemerror"),
                this.$t("dialog.anerrorhasccurred") + err.toString(),
                "error"
            );
          })
          .finally(() => {
            this.loadingShow = false;
          });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
          .deleteVehicle(this.ID)
          .then((res) => {
            if (res.data.status && res.data.msg) {
              swal(this.$t("dialog.info"), res.data.msg, "info");
            } else {
              swal(
                  this.$t("dialog.notice"),
                  this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                  "error"
              );
            }
          })
          .catch((err) => {
            swal(
                this.$t("dialog.systemerror"),
                this.$t("dialog.anerrorhasccurred") + err.toString(),
                "error"
            );
          })
          .finally(() => {
            this.loadingShow = false;
          });
    },
  },
};
</script>

<style></style>
