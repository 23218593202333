import axios from "axios";
import { encode } from "html-entities";
import swal from "sweetalert";
import config from "../config";
const instance = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      swal({
        title: "Oturum Süreniz Doldu",
        text: "Oturum süreniz doldu. Giriş sayfasına yönlendiriliyorsunuz.",
        type: "warning",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Tamam",
        closeOnConfirm: false,
      }).finally(() => {
        window.parent.window.location = "/";
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export default {
  searchBarcode(type, barcode, vehicleId = null) {
    return instance.post("cargoexistbarcode/" + type, {
      barcode,
      vehicleId,
    });
  },
  saveCargoUpDown(type, data, extra = null) {
    return instance.post("cargoupdown/" + type, { data, extra });
  },
  saveUserDefinedTemplate(data) {
    return instance.post("/asset/userdefined", data);
  },
  getUserDefinedTemplate(type) {
    return instance.get("/asset/userdefined/" + type + "?t=" + new Date().getTime());
  },
  getCargoStat() {
    return instance.get("/cargostat");
  },
  getSiteLang() {
    return instance.get("/langvalues");
  },
  me() {
    return instance.get("/me");
  },
  getMyAccount() {
    return instance.get("/myaccount");
  },
  saveMyAccount(data) {
    return instance.post("/myaccount", { data });
  },
  login(user, pass) {
    return instance.post("/login", { userName: user, userPass: pass });
  },
  brokerageLogin(user, pass) {
    return instance.post("/brokeragelogin", { userName: user, userPass: pass });
  },
  getFirmOne(id) {
    return instance.get(`/firm/${id}`);
  },
  saveFirm(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`firm/${id}`, { data });
    }
    return instance.post("firm", { data });
  },
  deleteFirm(id) {
    return instance.delete(`firm/${id}`);
  },

  getCargoOne(id) {
    return instance.get(`/cargo/${id}`);
  },
  saveCargo(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`cargo/${id}`, { data });
    }
    return instance.post("cargo", { data });
  },
  deleteCargo(id) {
    return instance.delete(`cargo/${id}`);
  },

  getApiCargoOne(id) {
    return instance.get(`/apicargo/${id}`);
  },
  saveApiCargo(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`apicargo/${id}`, { data });
    }
    return instance.post("apicargo", { data });
  },
  deleteApiCargo(id) {
    return instance.delete(`apicargo/${id}`);
  },

  getPermission() {
    return instance.get("permission");
  },

  getRolePerms(id) {
    return instance.get("permswithid/" + id);
  },

  getCountry() {
    return instance.get("country");
  },
  getCountryOne(id) {
    return instance.get(`/country/${id}`);
  },
  saveCountry(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`country/${id}`, { data });
    }
    return instance.post("country", { data });
  },
  deleteCountry(id) {
    return instance.delete(`country/${id}`);
  },

  getCityWidthRegionId(id) {
    return instance.get(`/citywithregionid/${id}`);
  },
  getCity() {
    return instance.get("city");
  },
  getCityOne(id) {
    return instance.get(`/city/${id}`);
  },
  saveCity(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`city/${id}`, { data });
    }
    return instance.post("city", { data });
  },
  deleteCity(id) {
    return instance.delete(`city/${id}`);
  },

  getPayType() {
    return instance.get("paytype");
  },
  getPayTypeOne(id) {
    return instance.get(`/paytype/${id}`);
  },
  savePayType(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`paytype/${id}`, { data });
    }
    return instance.post("paytype", { data });
  },
  deletePayType(id) {
    return instance.delete(`paytype/${id}`);
  },

  getBox() {
    return instance.get("box");
  },
  getBoxOne(id) {
    return instance.get(`/box/${id}`);
  },
  resendClientCargo(id) {
    return instance.put(`cargo/resendclient/${id}`);
  },
  saveBox(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`box/${id}`, { data });
    }
    return instance.post("box", { data });
  },
  deleteBox(id) {
    return instance.delete(`box/${id}`);
  },

  getApiToken() {
    return instance.get("apitoken");
  },
  getApiTokenOne(id) {
    return instance.get(`/apitoken/${id}`);
  },
  saveApiToken(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`apitoken/${id}`, { data });
    }
    return instance.post("apitoken", { data });
  },
  deleteApiToken(id) {
    return instance.delete(`apitoken/${id}`);
  },

  getGMapData(address) {
    return instance.post("apicargo/geocoding", { address });
  },

  getSendingType() {
    return instance.get("sendingtype");
  },
  getSendingTypeOne(id) {
    return instance.get(`/sendingtype/${id}`);
  },
  saveSendingType(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`sendingtype/${id}`, { data });
    }
    return instance.post("sendingtype", { data });
  },
  deleteSendingType(id) {
    return instance.delete(`sendingtype/${id}`);
  },

  getCountyOne(id) {
    return instance.get(`/county/${id}`);
  },
  saveCounty(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`county/${id}`, { data });
    }
    return instance.post("county", { data });
  },
  deleteCounty(id) {
    return instance.delete(`county/${id}`);
  },

  getCountyWidthCityId(id) {
    return instance.get(`/countywithcityid/${id}`);
  },

  getTownOne(id) {
    return instance.get(`/town/${id}`);
  },
  saveTown(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`town/${id}`, { data });
    }
    return instance.post("town", { data });
  },
  deleteTown(id) {
    return instance.delete(`town/${id}`);
  },

  getTownWidthCountyId(id) {
    return instance.get(`/townwithcountyid/${id}`);
  },
  getRegion() {
    return instance.get(`/region`);
  },
  getRegionOne(id) {
    return instance.get(`/region/${id}`);
  },
  saveRegion(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`region/${id}`, { data });
    }
    return instance.post("region", { data });
  },
  deleteRegion(id) {
    return instance.delete(`region/${id}`);
  },

  getRegionWidthCountryId(id) {
    return instance.get(`/regionwithcountryid/${id}`);
  },

  getRole() {
    return instance.get("role");
  },
  getRoleOne(id) {
    return instance.get(`/role/${id}`);
  },
  saveRole(data, perms, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`role/${id}`, { data, perms });
    }
    return instance.post("role", { data, perms });
  },
  deleteRole(id) {
    return instance.delete(`role/${id}`);
  },

  getResellerAddress(id) {
    return instance.get("reselleraddress/" + id);
  },
  getReseller() {
    return instance.get("reseller");
  },

  getResellerWithTownId(id, apicargoid) {
    return instance.get(`/resellerwithtownid/${id}/${apicargoid}`);
  },

  getResellersWithTownId(id, apicargoid) {
    return instance.get(`/resellerswithtownid/${id}/${apicargoid}`);
  },
  getResellerOne(id) {
    return instance.get(`/reseller/${id}`);
  },
  saveReseller(data, extra, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`reseller/${id}`, { data, extra });
    }
    return instance.post("reseller", { data, extra });
  },
  deleteReseller(id) {
    return instance.delete(`reseller/${id}`);
  },

  getPackage() {
    return instance.get("package");
  },
  getPackageWithDesi() {
    return instance.get("packagewithdesi");
  },
  getPackageWithDesiOnlyShow() {
    return instance.get("packagewithdesionlyshow");
  },
  getPackageOne(id) {
    return instance.get(`/package/${id}`);
  },
  savePackage(data, extra = [], id = 0) {
    if (intval(id) > 0) {
      return instance.put(`package/${id}`, { data, extra });
    }
    return instance.post("package", { data, extra });
  },
  deletePackage(id) {
    return instance.delete(`package/${id}`);
  },
  getPackageDesi(id) {
    return instance.get(`/packagedesi/${id}`);
  },
  removePackageDesi(id) {
    return instance.delete(`/packagedesi/${id}`);
  },

  getVehicle() {
    return instance.get("vehicle");
  },
  getVehicleOne(id) {
    return instance.get(`/vehicle/${id}`);
  },
  saveVehicle(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`vehicle/${id}`, { data });
    }
    return instance.post("vehicle", { data });
  },
  deleteVehicle(id) {
    return instance.delete(`vehicle/${id}`);
  },

  getStaticPrice() {
    return instance.get("staticprice");
  },
  getStaticPriceOne(id) {
    return instance.get(`/staticprice/${id}`);
  },
  saveStaticPrice(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`staticprice/${id}`, { data });
    }
    return instance.post("staticprice", { data });
  },
  deleteStaticPrice(id) {
    return instance.delete(`staticprice/${id}`);
  },

  getUserOne(id) {
    return instance.get(`/user/${id}`);
  },
  saveUser(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`user/${id}`, { data });
    }
    return instance.post("user", { data });
  },
  deleteUser(id) {
    return instance.delete(`user/${id}`);
  },

  getBrokerage() {
    return instance.get("brokerage");
  },
  getBrokerageOne(id) {
    return instance.get(`/brokerage/${id}`);
  },
  saveBrokerage(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`brokerage/${id}`, { data });
    }
    return instance.post("brokerage", { data });
  },
  deleteBrokerage(id) {
    return instance.delete(`brokerage/${id}`);
  },

  getBrokerageAgent() {
    return instance.get("brokerageagent");
  },
  getBrokerageAgentOne(id) {
    return instance.get(`brokerageagent/${id}`);
  },
  saveBrokerageAgent(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`brokerageagent/${id}`, { data });
    }
    return instance.post("brokerageagent", { data });
  },
  deleteBrokerageAgent(id) {
    return instance.delete(`brokerageagent/${id}`);
  },
  getBrokerageAgentWidthBrokerageId(id) {
    return instance.get(`/brokerageagentwithbrokerageid/${id}`);
  },

  getPresent() {
    return instance.get("present");
  },
  getPresentOne(id) {
    return instance.get(`present/${id}`);
  },
  savePresent(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`present/${id}`, { data });
    }
    return instance.post("present", { data });
  },
  deletePresent(id) {
    return instance.delete(`present/${id}`);
  },

  getPresentWithTerm(term, isBrokerAgePresent = -1) {
    return instance.post(`/presentforautocomplete/${isBrokerAgePresent}`, {
      term: encode(term),
    });
  },

  getAnotherInvoiceWithTerm(term) {
    return instance.post(`/contractforautocomplete`, {
      term: encode(term),
    });
  },

  getPresentAddress() {
    return instance.get("presentaddress");
  },
  getPresentAddressOne(id) {
    return instance.get(`presentaddress/${id}`);
  },
  savePresentAddress(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`presentaddress/${id}`, { data });
    }
    return instance.post("presentaddress", { data });
  },
  deletePresentAddress(id) {
    return instance.delete(`presentaddress/${id}`);
  },

  getPresentAddressWithPresentId(id) {
    return instance.get(`presentaddresswithpresentid/${id}`);
  },

  getReturnVariable() {
    return instance.get("returnvariable");
  },
  getReturnVariableOne(id) {
    return instance.get(`/returnvariable/${id}`);
  },
  saveReturnVariable(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`returnvariable/${id}`, { data });
    }
    return instance.post("returnvariable", { data });
  },
  deleteReturnVariable(id) {
    return instance.delete(`returnvariable/${id}`);
  },

  getContract() {
    return instance.get("contract");
  },
  getContractList() {
    return instance.get("contractlist");
  },
  isContract(data) {
    return instance.post("iscontract", data);
  },
  getContractOne(id) {
    return instance.get(`/contract/${id}`);
  },
  saveContract(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`contract/${id}`, { data });
    }
    return instance.post("contract", { data });
  },
  deleteContract(id) {
    return instance.delete(`contract/${id}`);
  },

  getCargoType() {
    return instance.get("cargotype");
  },
  getCargoTypeOne(id) {
    return instance.get(`/cargotype/${id}`);
  },
  saveCargoType(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`cargotype/${id}`, { data });
    }
    return instance.post("cargotype", { data });
  },
  deleteCargoType(id) {
    return instance.delete(`cargotype/${id}`);
  },

  getLang() {
    return instance.get("lang");
  },
  getLangOne(id) {
    return instance.get(`/lang/${id}`);
  },
  saveLang(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`lang/${id}`, { data });
    }
    return instance.post("lang", { data });
  },
  deleteLang(id) {
    return instance.delete(`lang/${id}`);
  },

  searchBarcodeForManifest(data) {
    return instance.post("manifestsearch", data);
  },
  searchCargoForVehicleAction(id, detailId) {
    return instance.get("cargosearchwithid/" + id + "/" + detailId);
  },

  getSelectedCargosForManifest(data) {
    return instance.post("manifestselectedcargos", data);
  },

  extractManifest(data) {
    return instance.post("extractmanifest", data);
  },

  getManifest() {
    return instance.get("manifest");
  },
  getManifestOne(id) {
    return instance.get(`/manifest/${id}`);
  },
  saveManifest(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`manifest/${id}`, { data });
    }
    return instance.post("manifest", { data });
  },
  deleteManifest(id) {
    return instance.delete(`manifest/${id}`);
  },

  getLangField() {
    return instance.get("langfield");
  },
  getLangFieldOne(id) {
    return instance.get(`/langfield/${id}`);
  },
  saveLangField(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`langfield/${id}`, { data });
    }
    return instance.post("langfield", { data });
  },
  deleteLangField(id) {
    return instance.delete(`langfield/${id}`);
  },

  getCampaignCoupon() {
    return instance.get("campaigncoupon");
  },
  getCampaignCouponOne(id) {
    return instance.get(`/campaigncoupon/${id}`);
  },
  saveCampaignCoupon(data, id = 0) {
    if (intval(id) > 0) {
      return instance.put(`campaigncoupon/${id}`, { data });
    }
    return instance.post("campaigncoupon", { data });
  },
  deleteCampaignCoupon(id) {
    return instance.delete(`campaigncoupon/${id}`);
  },

  getCampaignCouponWithCouponCode(COUPONCODE) {
    return instance.post(`/campaigncouponwithcouponcode`, {
      COUPONCODE,
    });
  },

  getResellerWithSendingAndPayType(id) {
    return instance.get(`/resellerwithsendingandpaytype/${id}`);
  },

  getResellerProfitWithResellerId(id) {
    return instance.get(`/resellerprofit/${id}`);
  },

  getResellerWithSendingAndPayTypeForCargo() {
    return instance.get(`/resellerwithsendingandpaytypeforcargo`);
  },

  getResellerPoly() {
    return instance.get("/resellerpoly");
  },

  getResellerWithCityId(id) {
    return instance.get("resellerwithcityid/" + id);
  },

  setCargoCancel(data) {
    return instance.post("cargo/cancel", { data });
  },

  saveResellerTrans(data) {
    return instance.post("resellertrans", { data });
  },

  saveResellerClaimTrans(data) {
    return instance.post("resellerclaimtrans", { data });
  },

  saveContractTrans(data) {
    return instance.post("contracttrans", { data });
  },

  saveContractPayedTrans(data) {
    return instance.post("contractpayedtrans", { data });
  },

  //LİST

  getDataForBasicList(table, search = {}, sort = {}) {
    search.sort = sort;
    return instance.post("search/" + table, { search });
  },

  getDataForBasicListCargoTrans(id, search = {}, sort = {}) {
    search.sort = sort;
    return instance.post("search/cargotrans/" + id, { search });
  },

  getCargoTrack(id) {
    return instance.post("cargo/track/" + id);
  },

  upload(formData, uri = "upload/file") {
    return instance.post(uri, formData, {
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  integration(data) {
    return instance.post("integration/run", { data });
  },
  getCols(file) {
    return instance.get(`asset/${file}`);
  },

  farmazonTransfer(data) {
    return instance.post("farmazon/transfer", { data });
  },

  exportExcell(data) {
    return instance.post("export/excell", {
      data,
    });
  },

  sendInvoice(id) {
    return instance.post("invoice/send", { id });
  },
  statusInvoice(id) {
    return instance.post("invoice/status", { id });
  },
  downloadInvoice(id) {
    return instance.post("invoice/download", { id });
  },

  setDeliveryStatus(data = {}) {
    return instance.post("cargodeliverystatus", data);
  },
  setReturnStatus(data = {}) {
    return instance.post("cargoreturnstatus", data);
  },

  getResellerWithAccount() {
    return instance.get(`/resellerwithaccount`);
  },

  getDefaultInfo() {
    return instance.get(`/defaultinfo`);
  },

  getUserWithResellerId(id) {
    return instance.get(`/userwithreseller/` + id);
  },

  /************************************************************/

  requestSms(phoneE164) {
    return axios.post(`${config.SMS_API_URL}public/validatephone`, {
      phoneE164,
    });
  },

  doSmsOtp(token, code) {
    return axios.post(`${config.SMS_API_URL}public/validatephoneotp`, {
      token,
      code,
    });
  },

  acceptCargo(id, status) {
    return instance.put(`cargo/accept/${id}/${status}`);
  },
};
