<template>
  <div class="row">
    <div class="col-12">
      {{ $t("search.ismediatepresent") }}
      <m-select
        class="search-form-field"
        v-model="search.ISBROKERAGEPRESENT"
        nolabel
        :options="options.yesNo"
      ></m-select>
    </div>
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.FULLNAME"
        nolabel
        :placeholder="$t('search.name')"
      ></m-input>
    </div>
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.FIRMNAME"
        nolabel
        :placeholder="$t('search.firmname')"
      ></m-input>
    </div>

    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.IDENTITYNO"
        nolabel
        :placeholder="$t('search.identityno')"
      ></m-input>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        ISBROKERAGEPRESENT: null,
        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
      },
      options: {
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
  },

  methods: {
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        ISBROKERAGEPRESENT: null,
        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
      };
    },
  },
};
</script>

<style></style>
