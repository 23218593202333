<template>
  <b-tabs>
    <b-tab :title="$t('search.global')" active>
      <div class="row">
        <div class="col-12">
          <m-input
            class="search-form-field"
            v-model="search.BARCODE"
            nolabel
            :placeholder="$t('search.barcode')"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.senderagent") }}
          <m-select
            v-model="search.SENDERRESELLERID"
            class="search-form-field"
            :options="options.reseller"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.takeragent") }}
          <m-select
            v-model="search.TAKERRESELLERID"
            class="search-form-field"
            nolabel
            :options="options.reseller"
          ></m-select>
        </div>
        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.deliverydate") }}
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}

          <m-input
            class="search-form-field"
            v-model="search.DELIVERYDATE[0]"
            nolabel
            type="date"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}

          <m-input
            class="search-form-field"
            type="date"
            v-model="search.DELIVERYDATE[1]"
            nolabel
          ></m-input>
        </div>

        <div class="col-12">
          <m-input
            class="search-form-field"
            v-model="search.USERNAME"
            nolabel
            :placeholder="$t('search.deliveryusername')"
          ></m-input>
        </div>
      </div>
    </b-tab>
    <b-tab :title="$t('search.mediate')">
      <div class="row">
        <div class="col-12" v-if="getPerms('cargolist')">
          {{ $t("search.mediate") }}
          <m-select
            class="search-form-field"
            v-model="search.BROKERAGEID"
            nolabel
            :options="options.brokerage"
          ></m-select>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import api from "../../api";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPerms"]),
  },
  data() {
    return {
      search: {
        BARCODE: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,
        DELIVERYDATE: [null, null],
        USERNAME: null,
        BROKERAGEID: null,
      },
      options: {
        reseller: [],
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
        brokerage: [],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
    this.getReseller();
    this.getBrokerage();
  },
  methods: {
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getReseller() {
      api.getReseller().then((res) => {
        if (res.data.status) {
          this.options.reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        BARCODE: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,
        DELIVERYDATE: [null, null],
        USERNAME: null,
        BROKERAGEID: null,
      };
    },
  },
};
</script>

<style></style>
