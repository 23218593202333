<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input
          v-model="form.FIRSTNAME"
          :disabled="true"
          langlabel="yourname"
        />
        <m-input
          v-model="form.LASTNAME"
          :disabled="true"
          langlabel="yoursurname"
        />
        <m-input
          v-model="form.RESELLERNAME"
          :disabled="true"
          langlabel="youragentname"
        />
        <m-input v-model="form.GSM" langlabel="gsm" />
        <m-input v-model="form.EMAIL" langlabel="email" />
        <m-input
          v-model="form.USERNAME"
          langlabel="username"
          :disabled="true"
        />

        <p
          style="text-align: right"
          v-text="$t('info.passwordnotchangestring')"
        ></p>
        <m-input v-model="form.NEWPASSWORD" langlabel="newpassword" />
        <m-input
          v-model="form.REPEATNEWPASSWORD"
          langlabel="newpasswordrepeat"
        />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    this.getMyAccount();
  },
  data() {
    return {
      ID: null,
      form: {
        FIRSTNAME: null,
        LASTNAME: null,
        RESELLERNAME: null,
        USERNAME: null,
        GSM: null,
        EMAIL: null,
        NEWPASSWORD: null,
        REPEATNEWPASSWORD: null,
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    save() {
      if (this.form.NEWPASSWORD != null) {
        if (this.form.NEWPASSWORD.length < 6) {
          swal(
            this.$t("dialog.notice"),
            "Şifreniz en az 6 karakter olmalıdır!"
          );
          return;
        }

        if (this.form.NEWPASSWORD != this.form.REPEATNEWPASSWORD) {
          swal(
            this.$t("dialog.notice"),
            "Şifreniz tekrar girilen şifre ile uyuşmuyor!"
          );
          return;
        }
      }

      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .saveMyAccount(this.form)
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              this.$t("dialog.successfullyupdatedrecord"),
              "success"
            );
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getMyAccount() {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getMyAccount()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              FIRSTNAME,
              LASTNAME,
              RESELLERNAME,
              USERNAME,
              GSM,
              EMAIL,
            } = res.data.data;
            this.form = {
              FIRSTNAME,
              LASTNAME,
              RESELLERNAME,
              USERNAME,
              GSM,
              EMAIL,

              NEWPASSWORD: null,
              REPEATNEWPASSWORD: null,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      swal(
        this.$t("dialog.notice"),
        "Kendi kullanıcınızı silemezsiniz!",
        "error"
      );
      return;
    },
  },
};
</script>

<style></style>
