<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import api from "./api";
import { mapActions } from "vuex";

export default {
  beforeMount() {
    if (["Login", "BrokerageLogin"].indexOf(this.$route.name) == -1) {
      api.me().then((res) => {
        if (res.data.status) {
          this.setPerms(res.data.data.PERMS);
        }
      });

      const selectedlang = localStorage.getItem("selectedlang");
      const messages = JSON.parse(localStorage.getItem("messages"));

      this.$root.$i18n.setLocaleMessage(selectedlang, messages[selectedlang]);
      this.$root.$i18n.locale = selectedlang;
    }
  },
  methods: {
    ...mapActions(["setPerms"]),
  },
};
</script>

<style lang="scss">
$colorMain: #08090d;
$colorSecond: #1a1e26;
$colorThird: #343a40;
$colorFourth: #6e7371;
$colorFifth: #a1a69c;

$colorWhite: #fff;

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  height: 100vh !important;
  font-size: 12px !important;
  color: $colorWhite !important;
}

body,
#app {
  width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

#app {
  height: 100%;
}
//app
.m-app {
  width: 100%;
  height: 100%;
  background-color: $colorThird;
}

// menu
.m-menu {
  width: 100%;
  height: 30px;
  background-color: $colorMain;
  color: $colorFifth;
  z-index: 9999;

  ul.m-menu-inner {
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    position: relative;

    li {
      display: inline-block;
      float: left;

      line-height: 30px;
      padding: 0px 15px;
      cursor: pointer;
      color: $colorFifth;

      &.m-menu-divider {
        height: 1px;
        background-color: $colorFourth;
        margin: 5px;
        cursor: default !important;

        &:hover {
          background-color: $colorFourth !important;
          color: $colorFourth !important;
          cursor: default !important;
        }
      }

      a {
        color: $colorFifth;

        text-decoration: none;
      }

      &:hover {
        background-color: $colorSecond;
        color: $colorWhite;

        a {
          color: $colorWhite;
        }
      }

      &:hover > ul {
        display: block;
      }

      & > ul {
        position: absolute;
        margin-left: -15px;
        padding: 0px;
        background-color: $colorMain;
        display: none;
        z-index: 9999;

        li {
          display: block;
          float: none;
          line-height: 25px;
          & > ul {
            display: none;
            padding: 0px;
            background-color: $colorMain;
            left: 100%;
            margin-top: -25px;
            width: max-content;
            margin-left: 0px;
          }

          &:hover > ul {
            display: block;
            position: absolute;
          }
        }
      }
    }

    a.m-sub-menu {
      &::after {
        position: absolute;
        padding-left: 5px;
        content: ">";
      }
    }
  }
}

.m-tab-area {
  width: 100%;
  height: calc(100% - 35px);
  z-index: 9000;

  .m-tab-list {
    width: 100%;
    height: 30px;
    ul {
      width: 100%;
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      li {
        z-index: 8999;

        position: relative;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        float: left;
        max-width: 150px;
        min-width: 120px;
        width: auto;
        margin-top: 2px;
        color: white;
        background-color: darken($color: #222, $amount: 3);
        height: 28px;
        font-size: 13px;
        text-align: center;
        line-height: 28px;
        margin-left: 3px;
        margin-right: 3px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        padding-right: 15px;
        padding-left: 5px;
        &:hover {
          background-color: darken($color: #222, $amount: 8);
        }
        &.active {
          background-color: darken($color: #000, $amount: 1);
        }
        span {
          display: block;
          position: absolute;
          width: 15px;
          height: 15px;
          right: 3px;
          top: 6px;
          text-align: center;
          line-height: 15px;
          &:hover {
            border-radius: 10px;
            background-color: brown;
          }
        }
      }
    }
  }
  .m-tab-content {
    width: 100%;
    height: calc(100% - 30px);
    border-top: 1px solid #222;
    padding-bottom: 0px;
    iframe {
      width: 100%;
      height: 100%;
      border: 0px solid none;
    }
  }
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.search-form-field {
  margin-bottom: 3px !important;
}
</style>
