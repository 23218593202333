import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    perms: {},
  },
  mutations: {
    SET_PERMS(state, data) {
      state.perms = data;
    },
  },
  actions: {
    setPerms({ commit }, data) {
      commit("SET_PERMS", data);
    },
  },
  getters: {
    getPerms: (state) => (key) => {
      if (key in state.perms) {
        return state.perms[key] == "1";
      }
      return false;
    },
  },
  modules: {},
});
