<template>
  <m-app>
    <div class="row">
      <div class="col-3">
        <m-input label="Adı" />
        <m-select label="Seç" />
        <m-input-btn label="Seç" />
        <m-textarea label="Yaz" />
        <m-yes-no label="Yaz" />
      </div>
    </div>
  </m-app>
</template>

<script>
  export default {};
</script>

<style lang="scss"></style>
