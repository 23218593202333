<template>
  <div class="row">
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.RESELLERNAME"
        nolabel
        :placeholder="$t('search.agentname')"
      ></m-input>
    </div>
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.RESELLERCODE"
        nolabel
        :placeholder="$t('search.agentcode')"
      ></m-input>
    </div>
    <div class="col-12">
      {{ $t("search.istransfercenter") }}
      <m-select
        class="search-form-field"
        v-model="search.ISTRANSFERCENTER"
        nolabel
        :options="options.yesNo"
      ></m-select>
    </div>
    <div class="col-12">
      {{ $t("search.isactive") }}

      <m-select
        class="search-form-field"
        v-model="search.ISACTIVE"
        nolabel
        :options="options.yesNo"
      ></m-select>
    </div>

    <div class="col-12">
      {{ $t("search.city") }}

      <m-select
        class="search-form-field"
        v-model="search.CITYID"
        nolabel
        :options="options.city"
      ></m-select>
    </div>
    <div class="col-12">
      {{ $t("search.county") }}

      <m-select
        class="search-form-field"
        v-model="search.COUNTYID"
        nolabel
        :options="options.county"
      ></m-select>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        RESELLERNAME: null,
        RESELLERCODE: null,
        ISTRANSFERCENTER: null,
        ISACTIVE: null,
        CITYID: null,
        COUNTYID: null,
      },
      options: {
        city: [],
        county: [],
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
    this.getCity();
  },
  methods: {
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountyWidthCityId(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        RESELLERNAME: null,
        RESELLERCODE: null,
        ISTRANSFERCENTER: null,
        ISACTIVE: null,
        CITYID: null,
        COUNTYID: null,
      };
    },
  },
  watch: {
    "search.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
  },
};
</script>

<style></style>
