<template>
  <b-tabs>
    <b-tab :title="$t('search.global')" active>
      <div class="row">
        <div class="col-12">
          <m-input class="search-form-field" v-model="search.BARCODE" nolabel :placeholder="$t('search.barcode')">
          </m-input>
        </div>
        <div class="col-6">
          {{ $t("search.senderagentcity") }}
          <m-select v-model="search.SENDERRESELLERCITYID" class="search-form-field" :options="options.city" nolabel>
          </m-select>
        </div>
        <div class="col-6">
          {{ $t("search.takeragentcity") }}
          <m-select v-model="search.TAKERRESELLERCITYID" class="search-form-field" nolabel :options="options.city">
          </m-select>
        </div>
        <div class="col-6">
          {{ $t("search.senderagent") }}
          <m-select v-model="search.SENDERRESELLERID" class="search-form-field" :options="options.senderreseller"
            nolabel></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.takeragent") }}
          <m-select v-model="search.TAKERRESELLERID" class="search-form-field" nolabel :options="options.takerreseller">
          </m-select>
        </div>
        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.sender") }}
          </span>
        </div>
        <div class="col-12">
          <m-input class="search-form-field" nolabel :placeholder="$t('search.sendername')"
            v-model="search.SENDERFULLNAME"></m-input>
          <m-input class="search-form-field" nolabel :placeholder="$t('search.senderfirmname')"
            v-model="search.SENDERFIRMNAME"></m-input>

          <m-input class="search-form-field" nolabel :placeholder="$t('search.senderidentityno')"
            v-model="search.SENDERIDENTITYNO"></m-input>
        </div>
        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.taker") }}
          </span>
        </div>
        <div class="col-12">
          <m-input class="search-form-field" nolabel :placeholder="$t('search.takername')"
            v-model="search.TAKERFULLNAME"></m-input>
          <m-input class="search-form-field" nolabel :placeholder="$t('search.takerfirmname')"
            v-model="search.TAKERFIRMNAME"></m-input>

          <m-input class="search-form-field" nolabel :placeholder="$t('search.takeridentityno')"
            v-model="search.TAKERIDENTITYNO"></m-input>
        </div>

        <div class="col-12">
          {{ $t("search.paytype") }}

          <m-select v-model="search.PAYTYPEID" class="search-form-field" :options="options.paytype" nolabel></m-select>
        </div>

        <div class="col-6">
          {{ $t("search.deliverystatus") }}

          <m-select v-model="search.ISDELIVERY" class="search-form-field" :options="options.yesNo" nolabel></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.cancelstatus") }}

          <m-select v-model="search.ISCANCEL" class="search-form-field" nolabel :options="options.yesNo"></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.returnstatus") }}

          <m-select v-model="search.ISRETURN" class="search-form-field" nolabel :options="options.yesNo"></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.informationformstatus") }}

          <m-select v-model="search.ISINFORMATIONFORM" class="search-form-field" nolabel :options="options.yesNo">
          </m-select>
        </div>
        <div class="col-12">
          <m-input class="search-form-field" v-model="search.TRACKINGNO" nolabel :placeholder="$t('search.trackingno')">
          </m-input>
        </div>
        <div class="col-12">
          <m-input class="search-form-field" v-model="search.ID" nolabel :placeholder="$t('search.id')">
          </m-input>
        </div>
        <div class="col-12">
          <m-input class="search-form-field" v-model="search.AGENTBARCODE" nolabel :placeholder="$t('search.agentbarcode')">
          </m-input>
        </div>
      </div>
    </b-tab>
    <b-tab title="Entegrasyon">
      <div class="row">

        <div class="col-12">
          Aracı Barkodu (tam değer)
          <m-input class="search-form-field" v-model="search.EQ_INTEGRATIONBARCODE" nolabel
                   :placeholder="$t('search.mediatebarcode')"></m-input>
        </div>

        <div class="col-12">
          Aracı Barkodu (değer içinde geçen)
          <m-input class="search-form-field" v-model="search.INTEGRATIONBARCODE" nolabel
            :placeholder="$t('search.mediatebarcode')"></m-input>
        </div>

        <div class="col-12">
          Dağıtıcı Birim Barkodu
          <m-input class="search-form-field" v-model="search.AGENTBARCODE" nolabel placeholder="Dağıtıcı Birim Barkodu">
          </m-input>
        </div>

        <div class="col-12">
          Dağıtıcı Birim Kargosu mu?

          <m-select v-model="search.ISCLIENTCARGO" class="search-form-field" :options="options.yesNo" nolabel>
          </m-select>
        </div>
        <div class="col-12">
          Dağıtıcı Birim Kabul Etti mi?

          <m-select v-model="search.ISACCEPTCLIENT" class="search-form-field" nolabel :options="options.yesNo">
          </m-select>
        </div>
        <div class="col-12">
          Dağıtıcı Birime Gönderim Başarılı mı?

          <m-select v-model="search.CLIENTCARGOSENDED" class="search-form-field" :options="options.yesNo" nolabel>
          </m-select>
        </div>
      </div>
    </b-tab>

    <b-tab title="Aralıklar">
      <div class="row">

        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.savedate") }}
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}

          <m-input class="search-form-field" v-model="search.SAVEDATE[0]" nolabel type="date"></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}

          <m-input class="search-form-field" type="date" v-model="search.SAVEDATE[1]" nolabel></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            Kabul Tarihi
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input class="search-form-field" v-model="search.ACCEPTCLIENTDATE[0]" nolabel type="date"></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input class="search-form-field" type="date" v-model="search.ACCEPTCLIENTDATE[1]" nolabel></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            Teslim Tarihi
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input class="search-form-field" v-model="search.DELIVERYDATE[0]" nolabel type="date"></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input class="search-form-field" type="date" v-model="search.DELIVERYDATE[1]" nolabel></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            İptal Tarihi
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input class="search-form-field" v-model="search.CANCELDATE[0]" nolabel type="date"></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input class="search-form-field" type="date" v-model="search.CANCELDATE[1]" nolabel></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            İade Tarihi
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input class="search-form-field" v-model="search.RETURNDATE[0]" nolabel type="date"></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input class="search-form-field" type="date" v-model="search.RETURNDATE[1]" nolabel></m-input>
        </div>

      </div>
    </b-tab>

  </b-tabs>
</template>

<script>
import api from "../../api";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPerms"]),
  },
  data() {
    return {
      search: {
        BARCODE: null,
        EQ_INTEGRATIONBARCODE: null,
        INTEGRATIONBARCODE: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,
        TAKERRESELLERCITYID: null,
        SENDERRESELLERCITYID: null,
        SAVEDATE: [null, null],
        CANCELDATE : [null, null],
        DELIVERYDATE : [null, null],
        ACCEPTCLIENTDATE : [null, null],
        RETURNDATE : [null, null],
        SENDERFIRMNAME: null,
        SENDERFULLNAME: null,
        SENDERIDENTITYNO: null,
        TAKERFIRMNAME: null,
        TAKERFULLNAME: null,
        TAKERIDENTITYNO: null,
        BROKERAGEID: null,

        ISCANCEL: null,
        ISDELIVERY: null,
        ISRETURN: null,
        ISINFORMATIONFORM: null,
        TRACKINGNO: null,

        ISCLIENTCARGO: null,
        ISACCEPTCLIENT: null,
        CLIENTCARGOSENDED: null,
        AGENTBARCODE: null,
      },
      options: {
        senderreseller: [],
        takerreseller: [],
        city: [],
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
        brokerage: [],
        paytype: [],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
    this.getCity();
    this.getReseller();
    this.getBrokerage();
    this.getPayType();
  },
  methods: {
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getReseller() {
      api.getReseller().then((res) => {
        if (res.data.status) {
          const reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });

          this.options.senderreseller = reseller;
          this.options.takerreseller = reseller;
        }
      });
    },

    getPayType() {
      api.getPayType().then((res) => {
        if (res.data.status) {
          this.options.paytype = res.data.data.rows.map((item) => {
            return {
              text: item.PAYTYPENAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getResellerWithCity(id, field) {
      api.getResellerWithCityId(id).then((res) => {
        if (res.data.status) {
          const reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });

          this.options[field] = reseller;
        }
      });
    },
    getCity() {
      api.getCity().then((res) => {
        if (res.data.status) {
          const city = res.data.data.rows.map((item) => {
            return {
              text: item.CITYNAME,
              value: item.ID,
            };
          });

          this.options.city = city;
        }
      });
    },
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        BARCODE: null,
        INTEGRATIONBARCODE: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,
        TAKERRESELLERCITYID: null,
        SENDERRESELLERCITYID: null,
        SAVEDATE: [null, null],
        CANCELDATE : [null, null],
        DELIVERYDATE : [null, null],
        ACCEPTCLIENTDATE : [null, null],
        RETURNDATE : [null, null],
        SENDERFIRMNAME: null,
        SENDERFULLNAME: null,
        SENDERIDENTITYNO: null,
        TAKERFIRMNAME: null,
        TAKERFULLNAME: null,
        TAKERIDENTITYNO: null,
        BROKERAGEID: null,

        ISCANCEL: null,
        ISDELIVERY: null,

        ISRETURN: null,
        ISINFORMATIONFORM: null,
        TRACKINGNO: null,
        ID: null,


        ISCLIENTCARGO: null,
        ISACCEPTCLIENT: null,
        CLIENTCARGOSENDED: null,
        AGENTBARCODE: null,
      };
    },
  },
  watch: {
    "search.TAKERRESELLERCITYID"(val) {
      if (intval(val) > 0) {
        this.search.TAKERRESELLERID = null;
        this.getResellerWithCity(val, "takerreseller");
      }
    },
    "search.SENDERRESELLERCITYID"(val) {
      if (intval(val) > 0) {
        this.search.SENDERRESELLERID = null;
        this.getResellerWithCity(val, "senderreseller");
      }
    },
  },
};
</script>

<style>
</style>
