<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('tab.global')" active>
        <div class="row">
          <div class="col-5">
            <m-input v-model.lazy="form.RESELLERCODE" langlabel="agentcode" />
            <m-input v-model.lazy="form.RESELLERNAME" langlabel="agentname" />
            <m-input v-model.lazy="form.AUTHORTITLE" langlabel="agentauthor" />
            <m-input v-model.lazy="form.TAXOFFICE" langlabel="taxoffice" />
            <m-input v-model.lazy="form.TAXNO" langlabel="taxno" />
            <m-input v-model.lazy="form.PHONE" langlabel="phone" />
            <m-input v-model.lazy="form.GSM" langlabel="gsm" />
            <m-input v-model.lazy="form.EMAIL" langlabel="email" />
            <m-yes-no
              v-model.lazy="form.ISTRANSFERCENTER"
              langlabel="istransfercenter"
            />
            <m-yes-no v-model.lazy="form.ISACTIVE" langlabel="isactive" />
          </div>

          <div class="col-5">
            <m-select
              v-if="USE_REGION"
              :options="options.region"
              v-model.lazy="form.REGIONID"
              langlabel="region"
            />
            <m-select
              :options="options.city"
              v-model.lazy="form.CITYID"
              langlabel="city"
            />
            <m-select
              :options="options.county"
              v-model.lazy="form.COUNTYID"
              langlabel="county"
            />
            <m-select
              :options="options.town"
              v-model.lazy="form.TOWNID"
              langlabel="town"
            />
            <m-input v-model.lazy="form.RESELLERLAT" langlabel="lat" />
            <m-input v-model.lazy="form.RESELLERLNG" langlabel="lng" />

            <m-textarea v-model.lazy="form.ADDRESS" langlabel="address" />
            <m-textarea
              v-model.lazy="form.DESCRIPTION"
              langlabel="description"
            />
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('tab.agentneighborhood')">
        <div class="row">
          <div class="col-12">
            <m-yes-no
              v-model.lazy="form.SHOWNOTFOUNTAGENT"
              label="Şube bulunamadığı adreslerde bu şubeyi göster"
            />
          </div>

          <div class="col-12">
            <b-btn
              variant="success"
              size="sm"
              style="float: right"
              @click="addAddress"
              >{{ $t("tab.newrow") }}</b-btn
            >
          </div>
          <div class="col-12">
            <b-table-simple>
              <b-thead>
                <tr>
                  <th
                    style="width: 15%; color: white !important"
                    v-if="USE_REGION"
                  >
                    {{ $t("form.region") }}
                  </th>
                  <th style="width: 15%; color: white !important">
                    {{ $t("form.city") }}
                  </th>
                  <th style="width: 15%; color: white !important">
                    {{ $t("form.county") }}
                  </th>
                  <th style="width: 45%; color: white !important">
                    {{ $t("form.neigborhood") }}
                  </th>
                  <th style="width: 10%; color: white !important">#</th>
                </tr>
              </b-thead>

              <b-tbody>
                <tr v-for="(addr, addrIndex) in address" :key="addrIndex">
                  <td v-if="USE_REGION">
                    <m-select
                      v-model.lazy="address[addrIndex].REGIONID"
                      @input="changeRegion(addrIndex)"
                      :options="options.region"
                      nolabel
                    />
                  </td>
                  <td>
                    <m-select
                      v-model.lazy="address[addrIndex].CITYID"
                      @input="changeCity(addrIndex)"
                      :options="
                        USE_REGION ? address[addrIndex].cities : options.city
                      "
                      nolabel
                    />
                  </td>
                  <td>
                    <m-select
                      @input="changeCounty(addrIndex)"
                      :options="address[addrIndex].counties"
                      v-model.lazy="address[addrIndex].COUNTYID"
                      nolabel
                    />
                  </td>
                  <td>
                    <multiselect
                      v-model.lazy="address[addrIndex].TOWNIDS"
                      :options="address[addrIndex].towns"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :placeholder="$t('info.selectneighborhood')"
                      label="text"
                      track-by="text"
                      :preselect-first="true"
                      group-values="child"
                      group-label="text"
                      :group-select="true"
                      :selectLabel="$t('multiselect.select')"
                      :selectedLabel="$t('info.selected')"
                      :deselectLabel="$t('info.deselect')"
                      :selectGroupLabel="$t('info.selectgroup')"
                      :deselectGroupLabel="$t('info.deselectgroup')"
                    >
                      <span slot="noResult">{{
                        $t("multiselect.nodata")
                      }}</span>
                      <span slot="noOptions">{{
                        $t("multiselect.nodata")
                      }}</span>
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length && !isOpen"
                          >{{ values.length }} mahalle seçildi</span
                        ></template
                      >
                    </multiselect>
                  </td>
                  <td>
                    <b-btn variant="danger" @click="removeAddress(addrIndex)">{{
                      $t("buton.removerow")
                    }}</b-btn>
                  </td>
                </tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('tab.payandshipment')">
        <div class="row">
          <div class="col-6">
            <b-form-group :label="$t('table.paytype')">
              <b-form-checkbox-group
                v-model="lookup.payTypeSelected"
                :options="options.payType"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('table.sendingtype')">
              <b-form-checkbox-group
                v-model="lookup.sendingTypeSelected"
                :options="options.sendingType"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('tab.agentsenderaccount')">
        <div class="row">
          <div class="col-12">
            <m-select
              v-model="form.SENDERRESELLERPROFIT"
              :options="options.profit"
              langlabel="payinfo"
            />
            <m-input
                v-model="payrateamount"
                type="number"
                langlabel="payrateamount"
                placeholder="(Kazanç)Oran/Tutar"
            />
            <m-input
                v-model="returnrateamount"
                type="number"
                langlabel="returnrateamount"
                placeholder="(İade)Oran/Tutar"
            />
          </div>
          <div class="col-12">
            <div
              v-for="pack in lookup.senderPackageData"
              :key="`sender-${pack.ID}`"
            >
              <b-form-checkbox
                v-model="senderPackData[pack.ID].isUse"
                :value="true"
                :unchecked-value="false"
                @input="
                  () => {
                    $forceUpdate();
                  }
                "
              >
                {{ pack.PACKAGENAME }}
              </b-form-checkbox>
              <div v-show="senderPackData[pack.ID].isUse">
                <div class="row" v-if="pack.ISREQUIREDDESI == '0'">
                  <div class="col-2">
                    {{ $t("form.pricenontax") }}
                    {{ senderPackData[pack.ID].price }}
                  </div>
                  <div class="col-2">
                    {{ $t("form.returnpricenontax") }}
                    {{ senderPackData[pack.ID].returnprice }}
                  </div>
                  <div class="col-3">
                    {{ $t("form.payrateamount") }}
                    <m-input
                      nolabel
                      v-model.lazy="senderPackData[pack.ID].agentprofit"
                      type="number"
                      :disabled="true"
                    />
                  </div>
                  <div class="col-3">
                    {{ $t("form.returnrateamount") }}

                    <m-input
                      nolabel
                      v-model.lazy="senderPackData[pack.ID].agentreturnprofit"
                      type="number"
                      :disabled="true"
                    />
                  </div>
                  <div class="col-2">
                    {{ $t("form.taxrate") }} : %{{ pack.TAXRATE }}
                  </div>
                </div>
                <div v-else>
                  <b-table-simple style="color: white">
                    <b-thead>
                      <tr>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.decistart") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.deciend") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.pricenontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnpricenontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.payrateamount") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnrateamount") }}
                        </th>
                        <th style="width: 10%; color: white !important">
                          {{ $t("form.taxrate") }}
                        </th>
                      </tr>
                    </b-thead>

                    <b-tbody>
                      <tr
                        v-for="desi in pack.desi"
                        :key="`senderdesi${desi.ID}`"
                      >
                        <td>{{ desi.DESISTART }}</td>
                        <td>{{ desi.DESIEND }}</td>
                        <td>
                          {{ senderPackData[pack.ID].desi[desi.ID].price }}
                        </td>
                        <td>
                          {{
                            senderPackData[pack.ID].desi[desi.ID].returnprice
                          }}
                        </td>
                        <td>
                          <m-input
                            v-model.lazy="
                              senderPackData[pack.ID].desi[desi.ID].agentprofit
                            "
                            type="number"
                            nolabel
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <m-input
                            v-model.lazy="
                              senderPackData[pack.ID].desi[desi.ID].agentreturnprofit
                            "
                            type="number"
                            nolabel
                            :disabled="true"
                          />
                        </td>
                        <td>{{ desi.DESITAXRATE }}</td>
                      </tr>
                    </b-tbody>
                    <b-tfoot v-if="pack.DYNAMICDESIUSE == '1'">
                      <tr>
                        <td colspan="7">
                          <div class="row">
                            <div class="col-1">Desi Başlangıç</div>
                            <div class="col-1">Desi Tutarı</div>
                            <div class="col-1">Desi Başına Tutar</div>
                            <div class="col-2">Desi Kazanç</div>
                            <div class="col-2">Desi Başına Kazanç</div>
                            <div class="col-2">Desi İade</div>
                            <div class="col-2">Desi Başına İade</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <div class="row">
                            <div class="col-1">
                              {{ pack.DYNAMICDESISTART }}
                            </div>
                            <div class="col-1">
                              {{ pack.DYNAMICDESIPRICE }}
                            </div>
                            <div class="col-1">
                              {{ pack.DYNAMICDESIPERPRICE }}
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID].dynamicDesiProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID].dynamicDesiPerProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID]
                                    .dynamicDesiReturnProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID]
                                    .dynamicDesiReturnPerProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <p class="mt-1">*Dinamik Desi Hesaplamalarında hakedişin hesaplanabilmesi için lütfen bu alanı doldurun.</p>
                          </div>
                        </td>
                      </tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('tab.agenttakeraccount')">
        <div class="row">
          <div class="col-12">
            <m-select
              v-model="form.TAKERRESELLERPROFIT"
              :options="options.profit"
              langlabel="payinfo"
            />
            <m-input
                v-model="payrateamounttaker"
                type="number"
                langlabel="payrateamount"
                placeholder="(Kazanç)Oran/Tutar"
            />
            <m-input
                v-model="returnrateamounttaker"
                type="number"
                langlabel="returnrateamount"
                placeholder="(İade)Oran/Tutar"
            />
          </div>
          <div class="col-12">
            <div
              v-for="pack in lookup.takerPackageData"
              :key="`taker-${pack.ID}`"
            >
              <b-form-checkbox
                v-model="takerPackData[pack.ID].isUse"
                :value="true"
                :unchecked-value="false"
                @input="
                  () => {
                    $forceUpdate();
                  }
                "
              >
                {{ pack.PACKAGENAME }}
              </b-form-checkbox>
              <div v-show="takerPackData[pack.ID].isUse">
                <div class="row" v-if="pack.ISREQUIREDDESI == '0'">
                  <div class="col-2">
                    {{ $t("form.pricenontax") }}
                    {{ takerPackData[pack.ID].price }}
                  </div>
                  <div class="col-2">
                    {{ $t("form.returnpricenontax") }}
                    {{ takerPackData[pack.ID].returnprice }}
                  </div>
                  <div class="col-3">
                    {{ $t("form.payrateamount") }}
                    <m-input
                      nolabel
                      v-model="takerPackData[pack.ID].agentprofit"
                      type="number"
                      :disabled="true"
                    />
                  </div>
                  <div class="col-3">
                    {{ $t("form.returnrateamount") }}
                    <m-input
                      nolabel
                      v-model="takerPackData[pack.ID].agentreturnprofit"
                      type="number"
                      :disabled="true"
                    />
                  </div>
                  <div class="col-2">
                    {{ $t("form.taxrate") }} : %{{ pack.TAXRATE }}
                  </div>
                </div>
                <div v-else>
                  <b-table-simple style="color: white">
                    <b-thead>
                      <tr>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.decistart") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.deciend") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.pricenontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnpricenontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.payrateamount") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnrateamount") }}
                        </th>
                        <th style="width: 10%; color: white !important">
                          {{ $t("form.taxrate") }}
                        </th>
                      </tr>
                    </b-thead>

                    <b-tbody>
                      <tr
                        v-for="desi in pack.desi"
                        :key="`takerdesi${desi.ID}`"
                      >
                        <td>{{ desi.DESISTART }}</td>
                        <td>{{ desi.DESIEND }}</td>
                        <td>
                          {{ takerPackData[pack.ID].desi[desi.ID].price }}
                        </td>
                        <td>
                          {{ takerPackData[pack.ID].desi[desi.ID].returnprice }}
                        </td>
                        <td>
                          <m-input
                            v-model="
                              takerPackData[pack.ID].desi[desi.ID].agentprofit
                            "
                            type="number"
                            nolabel
                            :disabled="true"
                          />
                        </td>
                        <td>
                          <m-input
                            v-model="
                              takerPackData[pack.ID].desi[desi.ID]
                                .agentreturnprofit
                            "
                            type="number"
                            nolabel
                            :disabled="true"
                          />
                        </td>
                        <td>{{ desi.DESITAXRATE }}</td>
                      </tr>
                    </b-tbody>
                    <b-tfoot v-if="pack.DYNAMICDESIUSE == '1'">
                      <tr>
                        <td colspan="7">
                          <div class="row">
                            <div class="col-1">Desi Başlangıç</div>
                            <div class="col-1">Desi Tutarı</div>
                            <div class="col-1">Desi Başına Tutar</div>
                            <div class="col-2">Desi Kazanç</div>
                            <div class="col-2">Desi Başına Kazanç</div>
                            <div class="col-2">Desi İade</div>
                            <div class="col-2">Desi Başına İade</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <div class="row">
                            <div class="col-1">
                              {{ pack.DYNAMICDESISTART }}Desi Başlangıç
                            </div>
                            <div class="col-1">
                              {{ pack.DYNAMICDESIPRICE }}Desi Tutarı
                            </div>
                            <div class="col-1">
                              {{ pack.DYNAMICDESIPERPRICE }}Desi Başına Tutar
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  takerPackData[pack.ID].dynamicDesiProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  takerPackData[pack.ID].dynamicDesiPerProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  takerPackData[pack.ID].dynamicDesiReturnProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  takerPackData[pack.ID]
                                    .dynamicDesiReturnPerProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <p class="mt-1">*Dinamik Desi Hesaplamalarında hakedişin hesaplanabilmesi için lütfen bu alanı doldurun.</p>
                          </div>
                        </td>
                      </tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('tab.mapstate')" v-if="isUseMap">
        <m-map-draw ref="mapDrawing" />
      </b-tab>
    </b-tabs>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
import config from "../../config";
export default {
  mounted() {
    this.options.profit[0].text = this.$t("buton.forrate");
    this.options.profit[1].text = this.$t("buton.foramount");
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
    }
    if (this.USE_REGION) {
      this.getRegion();
    } else {
      this.getCity();
    }
    this.getPayType();
    this.getSendingType();
    this.getPackage();
  },
  computed: {
    isUseMap() {
      return config.USE_MAP_API;
    },
    USE_REGION() {
      return config.USE_REGION;
    },
  },
  data() {
    return {
      payrateamount: "0,00",
      returnrateamount: "0,00",
      payrateamounttaker: "0,00",
      returnrateamounttaker: "0,00",
      ID: null,
      form: {
        RESELLERNAME: null,
        RESELLERCODE: null,
        CITYID: null,
        REGIONID: null,
        COUNTYID: null,
        TOWNID: null,
        ADDRESS: null,
        ISACTIVE: 1,
        TAXOFFICE: null,
        TAXNO: null,
        PHONE: null,
        GSM: null,
        EMAIL: null,
        AUTHORTITLE: null,
        DESCRIPTION: null,
        ISTRANSFERCENTER: 0,

        RESELLERLNG: null,
        RESELLERLAT: null,

        SENDERRESELLERPROFIT: "Rate",
        TAKERRESELLERPROFIT: "Rate",

        POLYLATLNG: [],

        SHOWNOTFOUNTAGENT: 0,
      },
      loadingShow: false,
      loadingMsg: null,
      options: {
        region: [],
        city: [],
        town: [],
        county: [],
        payType: [],
        sendingType: [],
        profit: [
          {
            text: "Orana Göre",
            value: "Rate",
          },
          {
            text: "Sabit Tutar",
            value: "Amount",
          },
        ],
      },

      lookup: {
        payTypeData: [],
        payTypeSelected: [],
        sendingTypeData: [],
        sendingTypeSelected: [],
        senderPackageData: [],
        takerPackageData: [],
      },

      senderPackData: {},
      takerPackData: {},

      address: [],
    };
  },
  validations: {
    form: {},
  },
  methods: {
    getPackage() {
      api
        .getPackageWithDesi()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.lookup.senderPackageData = res.data.data;
            this.lookup.takerPackageData = res.data.data;
            res.data.data.map((item) => {
              this.senderPackData[item.ID] = {
                isUse: true,
                price: toTrFromNumber(item.PRICE),
                returnprice: toTrFromNumber(item.RETURNPRICE),
                agentprofit: 0,
                agentreturnprofit: 0,
                desi: {},
                ISREQUIREDDESI: item.ISREQUIREDDESI,
                DYNAMICDESIUSE: item.DYNAMICDESIUSE,
                DYNAMICDESISTART: item.DYNAMICDESISTART,
                DYNAMICDESIPRICE: item.DYNAMICDESIPRICE,
                DYNAMICDESIPERPRICE: item.DYNAMICDESIPERPRICE,
                dynamicDesiProfit: 0,
                dynamicDesiPerProfit: 0,
                dynamicDesiReturnProfit: 0,
                dynamicDesiReturnPerProfit: 0,
              };
              this.takerPackData[item.ID] = {
                isUse: true,
                price: toTrFromNumber(item.PRICE),
                returnprice: toTrFromNumber(item.RETURNPRICE),
                agentprofit: 0,
                agentreturnprofit: 0,
                desi: {},
                ISREQUIREDDESI: item.ISREQUIREDDESI,
                DYNAMICDESIUSE: item.DYNAMICDESIUSE,
                DYNAMICDESISTART: item.DYNAMICDESISTART,
                DYNAMICDESIPRICE: item.DYNAMICDESIPRICE,
                DYNAMICDESIPERPRICE: item.DYNAMICDESIPERPRICE,
                dynamicDesiProfit: 0,
                dynamicDesiPerProfit: 0,
                dynamicDesiReturnProfit: 0,
                dynamicDesiReturnPerProfit: 0,
              };
              item.desi.map((desiItem) => {
                this.senderPackData[item.ID].desi[desiItem.ID] = {
                  price: toTrFromNumber(desiItem.DESIPRICE),
                  returnprice: toTrFromNumber(desiItem.DESIRETURNPRICE),
                  agentprofit: 0,
                  agentreturnprofit: 0,
                };
                this.takerPackData[item.ID].desi[desiItem.ID] = {
                  price: toTrFromNumber(desiItem.DESIPRICE),
                  returnprice: toTrFromNumber(desiItem.DESIRETURNPRICE),
                  agentprofit: 0,
                  agentreturnprofit: 0,
                };
              });
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }

          if (intval(this.ID) > 0) {
            this.getReseller(this.ID);
            this.getResellerAddress(this.ID);
            this.getResellerWithSendingAndPayType(this.ID);
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getPayType() {
      api.getPayType().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.lookup.payTypeData = res.data.data.rows;
          this.options.payType = res.data.data.rows.map((item) => {
            return {
              text: item.PAYTYPENAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getSendingType() {
      api.getSendingType().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.lookup.sendingTypeData = res.data.data.rows;
          this.options.sendingType = res.data.data.rows.map((item) => {
            return {
              text: item.SENDINGTYPENAME,
              value: item.ID,
            };
          });
        }
      });
    },
    removeAddress(index) {
      this.address.splice(index, 1);
    },
    getResellerAddress(id) {
      api.getResellerAddress(id).then((res) => {
        if (res.data.status) {
          res.data.data.forEach((item, index) => {
            this.address.push({
              REGIONID: item.REGIONID,
              CITYID: item.CITYID,
              COUNTYID: item.COUNTYID,
              TOWNIDS: item.TOWNIDS,
              cities: [],
              counties: [],
              towns: [],
            });
            if (this.USE_REGION) {
              this.changeRegion(index);
            }
            this.changeCity(index);
            this.changeCounty(index);
          });
        }
      });
    },
    addAddress() {
      this.address.push({
        REGIONID: null,
        CITYID: null,
        COUNTYID: null,
        TOWNIDS: [],
        cities: [],
        counties: [],
        towns: [],
      });
    },
    changeRegion(index) {
      if (!this.USE_REGION) {
        return;
      }
      api
        .getCityWidthRegionId(this.address[index].REGIONID)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.address[index].cities = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCity(index) {
      api
        .getCountyWidthCityId(this.address[index].CITYID)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.address[index].counties = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCounty(index) {
      api
        .getTownWidthCountyId(this.address[index].COUNTYID)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.address[index].towns = [
              {
                text: "Tümünü Seç",
                child: res.data.data.rows.map((item) => {
                  return { text: item.TOWNNAME, value: item.ID };
                }),
              },
            ];
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getRegion() {
      api
        .getRegion()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.region = res.data.data.rows.map((item) => {
              return { text: item.REGIONNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCityWidthRegionId(id) {
      api
        .getCityWidthRegionId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountyWidthCityId(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getTownWidthCountyId(id) {
      api
        .getTownWidthCountyId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.town = res.data.data.rows.map((item) => {
              return { text: item.TOWNNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getResellerWithSendingAndPayType(id) {
      api.getResellerWithSendingAndPayType(id).then((res) => {
        if (res.data.status) {
          this.lookup.sendingTypeSelected = res.data.data.sendingType.map(
            (item) => {
              return item.ID; //sendingtype ID
            }
          );
          this.lookup.payTypeSelected = res.data.data.payType.map((item) => {
            return item.ID; //paytype ID
          });
        }
      });
    },
    save() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.loadingMsg = this.$t("dialog.savingrecord");
        this.loadingShow = true;

        if (config.USE_MAP_API) {
          this.form.POLYLATLNG = this.$refs.mapDrawing.getPolygons();
        }

        this.form.POLYLATLNG = JSON.stringify(this.form.POLYLATLNG);

        let extraData = {
          address: this.address.map((item) => {
            return {
              REGIONID: item.REGIONID,
              CITYID: item.CITYID,
              COUNTYID: item.COUNTYID,
              TOWNIDS: item.TOWNIDS.map((townItem) => {
                return townItem.value;
              }),
            };
          }),
          payType: this.lookup.payTypeSelected,
          sendingType: this.lookup.sendingTypeSelected,
        };

        const senderPackIds = Object.keys(this.senderPackData);
        extraData.senderPack = senderPackIds.map((item) => {
          const pack = this.senderPackData[item];
          const desiIds = Object.keys(pack.desi);

          return {
            PACKAGEID: item,
            PROFIT: toNumberFromTr(pack.agentprofit),
            RETURNPROFIT: toNumberFromTr(pack.agentreturnprofit),
            ISREQUIREDDESI: pack.ISREQUIREDDESI,
            DYNAMICDESIPROFIT: toNumberFromTr(pack.dynamicDesiProfit),
            DYNAMICDESIPERPROFIT: toNumberFromTr(pack.dynamicDesiPerProfit),
            DYNAMICDESIRETURNPROFIT: toNumberFromTr(
              pack.dynamicDesiReturnProfit
            ),
            DYNAMICDESIRETURNPERPROFIT: toNumberFromTr(
              pack.dynamicDesiReturnPerProfit
            ),
            DYNAMICDESIUSE: pack.DYNAMICDESIUSE,

            desis: desiIds.map((desiId) => {
              const desi = pack.desi[desiId];
              return {
                DESIID: desiId,
                DESIPROFIT: toNumberFromTr(desi.agentprofit),
                DESIRETURNPROFIT: toNumberFromTr(desi.agentreturnprofit),
              };
            }),
          };
        });

        const takerPackIds = Object.keys(this.takerPackData);
        extraData.takerPack = takerPackIds.map((item) => {
          const pack = this.takerPackData[item];
          const desiIds = Object.keys(pack.desi);

          return {
            PACKAGEID: item,
            PROFIT: toNumberFromTr(pack.agentprofit),
            RETURNPROFIT: toNumberFromTr(pack.agentreturnprofit),
            ISREQUIREDDESI: pack.ISREQUIREDDESI,
            DYNAMICDESIPROFIT: toNumberFromTr(pack.dynamicDesiProfit),
            DYNAMICDESIPERPROFIT: toNumberFromTr(pack.dynamicDesiPerProfit),
            DYNAMICDESIRETURNPROFIT: toNumberFromTr(
              pack.dynamicDesiReturnProfit
            ),
            DYNAMICDESIRETURNPERPROFIT: toNumberFromTr(
              pack.dynamicDesiReturnPerProfit
            ),
            DYNAMICDESIUSE: pack.DYNAMICDESIUSE,
            desis: desiIds.map((desiId) => {
              const desi = pack.desi[desiId];
              return {
                DESIID: desiId,
                DESIPROFIT: toNumberFromTr(desi.agentprofit),
                DESIRETURNPROFIT: toNumberFromTr(desi.agentreturnprofit),
              };
            }),
          };
        });

        api
          .saveReseller(this.form, extraData, this.ID)
          .then((res) => {
            if (res.data.status) {
              if (this.ID == res.data.data.ID) {
                swal(
                  this.$t("dialog.success"),
                  this.$t("dialog.successfullyupdatedrecord"),
                  "success"
                );
              } else {
                this.ID = res.data.data.ID;
                swal(
                  this.$t("dialog.success"),
                  this.$t("dialog.successfullysaverecord"),
                  "success"
                );
              }
            } else {
              swal(
                this.$t("dialog.notice"),
                this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                "error"
              );
            }
          })
          .catch((err) => {
            swal(
              this.$t("dialog.systemerror"),
              this.$t("dialog.anerrorhasccurred") + err.toString(),
              "error"
            );
          })
          .finally(() => {
            this.loadingShow = false;
          });
      }
    },
    getReseller(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getResellerOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              RESELLERNAME,
              RESELLERCODE,
              CITYID,
              COUNTYID,
              TOWNID,
              ADDRESS,
              ISACTIVE,
              SHOWNOTFOUNTAGENT,
              TAXOFFICE,
              TAXNO,
              PHONE,
              GSM,
              EMAIL,
              AUTHORTITLE,
              DESCRIPTION,
              ISTRANSFERCENTER,
              POLYLATLNG,
              REGIONID,
              RESELLERLNG,
              RESELLERLAT,
            } = res.data.data;

            this.form = {
              RESELLERNAME,
              RESELLERCODE,
              CITYID,
              COUNTYID,
              TOWNID,
              ADDRESS,
              ISACTIVE,
              TAXOFFICE,
              TAXNO,
              PHONE,
              GSM,
              EMAIL,
              AUTHORTITLE,
              DESCRIPTION,
              ISTRANSFERCENTER,
              POLYLATLNG: POLYLATLNG ? JSON.parse(POLYLATLNG) : [],
              REGIONID,
              RESELLERLNG,
              RESELLERLAT,
              SHOWNOTFOUNTAGENT,
            };

            if (config.USE_MAP_API) {
              this.$refs.mapDrawing.setPolygons(
                POLYLATLNG ? JSON.parse(POLYLATLNG) : []
              );
            }

            /**
             * 
             * res.data.data.map((item) => {
              this.senderPackData[item.ID] = {
                isUse: true,
                price: toTrFromNumber(item.PRICE),
                returnprice: toTrFromNumber(item.RETURNPRICE),
                agentprofit: 0,
                agentreturnprofit: 0,
                desi: {},
                ISREQUIREDDESI: item.ISREQUIREDDESI,
              };
              this.takerPackData[item.ID] = {
                isUse: true,
                price: toTrFromNumber(item.PRICE),
                returnprice: toTrFromNumber(item.RETURNPRICE),
                agentprofit: 0,
                agentreturnprofit: 0,
                desi: {},
                ISREQUIREDDESI: item.ISREQUIREDDESI,
              };
              item.desi.map((desiItem) => {
                this.senderPackData[item.ID].desi[desiItem.ID] = {
                  price: toTrFromNumber(desiItem.DESIPRICE),
                  returnprice: toTrFromNumber(desiItem.DESIRETURNPRICE),
                  agentprofit: 0,
                  agentreturnprofit: 0,
                };
                this.takerPackData[item.ID].desi[desiItem.ID] = {
                  price: toTrFromNumber(desiItem.DESIPRICE),
                  returnprice: toTrFromNumber(desiItem.DESIRETURNPRICE),
                  agentprofit: 0,
                  agentreturnprofit: 0,
                };
              });
            });
             */

            const profit = res.data.data.profit;

            if (profit) {
              profit.map((item) => {
                if (item.PROFITTYPE == "Sender") {
                  this.form.SENDERRESELLERPROFIT = item.PROFITCALCTYPE;

                  if (item.PACKAGEID in this.senderPackData) {
                    this.senderPackData[item.PACKAGEID].agentprofit =
                      toTrFromNumber(item.PROFIT);
                    this.senderPackData[item.PACKAGEID].agentreturnprofit =
                      toTrFromNumber(item.RETURNPROFIT);

                    /**Dinamik desi */
                    this.senderPackData[item.PACKAGEID].dynamicDesiProfit =
                      toTrFromNumber(item.DYNAMICDESIPROFIT);
                    this.senderPackData[item.PACKAGEID].dynamicDesiPerProfit =
                      toTrFromNumber(item.DYNAMICDESIPERPROFIT);
                    this.senderPackData[
                      item.PACKAGEID
                    ].dynamicDesiReturnProfit = toTrFromNumber(
                      item.DYNAMICDESIRETURNPROFIT
                    );
                    this.senderPackData[
                      item.PACKAGEID
                    ].dynamicDesiReturnPerProfit = toTrFromNumber(
                      item.DYNAMICDESIRETURNPERPROFIT
                    );
                    /**Dinamik desi son */
                    if (
                      this.senderPackData[item.PACKAGEID].ISREQUIREDDESI ==
                        "1" &&
                      item.ISREQUIREDDESI == "1" &&
                      item.desi != null
                    ) {
                      item.desi.map((desiItem) => {
                        if (
                          desiItem.PACKAGEDESIID in
                          this.senderPackData[item.PACKAGEID].desi
                        ) {
                          this.senderPackData[item.PACKAGEID].desi[
                            desiItem.PACKAGEDESIID
                          ].agentprofit = toTrFromNumber(desiItem.DESIPROFIT);
                          this.senderPackData[item.PACKAGEID].desi[
                            desiItem.PACKAGEDESIID
                          ].agentreturnprofit = toTrFromNumber(
                            desiItem.DESIRETURNPROFIT
                          );
                        }
                      });
                    }
                  }
                } else if (item.PROFITTYPE == "Taker") {
                  this.form.TAKERRESELLERPROFIT = item.PROFITCALCTYPE;

                  if (item.PACKAGEID in this.takerPackData) {
                    this.takerPackData[item.PACKAGEID].agentprofit =
                      toTrFromNumber(item.PROFIT);
                    this.takerPackData[item.PACKAGEID].agentreturnprofit =
                      toTrFromNumber(item.RETURNPROFIT);

                    /**Dinamik desi */
                    this.takerPackData[item.PACKAGEID].dynamicDesiProfit =
                      toTrFromNumber(item.DYNAMICDESIPROFIT);
                    this.takerPackData[item.PACKAGEID].dynamicDesiPerProfit =
                      toTrFromNumber(item.DYNAMICDESIPERPROFIT);
                    this.takerPackData[item.PACKAGEID].dynamicDesiReturnProfit =
                      toTrFromNumber(item.DYNAMICDESIRETURNPROFIT);
                    this.takerPackData[
                      item.PACKAGEID
                    ].dynamicDesiReturnPerProfit = toTrFromNumber(
                      item.DYNAMICDESIRETURNPERPROFIT
                    );
                    /**Dinamik desi son */

                    if (
                      this.takerPackData[item.PACKAGEID].ISREQUIREDDESI ==
                        "1" &&
                      item.ISREQUIREDDESI == "1" &&
                      item.desi != null
                    ) {
                      item.desi.map((desiItem) => {
                        if (
                          desiItem.PACKAGEDESIID in
                          this.takerPackData[item.PACKAGEID].desi
                        ) {
                          this.takerPackData[item.PACKAGEID].desi[
                            desiItem.PACKAGEDESIID
                          ].agentprofit = toTrFromNumber(desiItem.DESIPROFIT);
                          this.takerPackData[item.PACKAGEID].desi[
                            desiItem.PACKAGEDESIID
                          ].agentreturnprofit = toTrFromNumber(
                            desiItem.DESIRETURNPROFIT
                          );
                        }
                      });
                    }
                  }
                }
              });
            }
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteReseller(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "form.REGIONID"(id) {
      this.getCityWidthRegionId(id);
    },
    "form.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
    "form.COUNTYID"(id) {
      this.getTownWidthCountyId(id);
    },
    payrateamount(newValue) {
      Object.values(this.senderPackData).forEach(pack => {
        pack.agentprofit = newValue;
        Object.values(pack.desi).forEach(desi => {
          desi.agentprofit = newValue;
        });
      });
    },
    returnrateamount(newValue) {
      Object.values(this.senderPackData).forEach(pack => {
        pack.agentreturnprofit = newValue;
        Object.values(pack.desi).forEach(desi => {
          desi.agentreturnprofit = newValue;
        });
      });
    },
    payrateamounttaker(newValue) {
      Object.values(this.takerPackData).forEach(pack => {
        pack.agentprofit = newValue;
        Object.values(pack.desi).forEach(desi => {
          desi.agentprofit = newValue;
        });
      });
    },
    returnrateamounttaker(newValue) {
      Object.values(this.takerPackData).forEach(pack => {
        pack.agentreturnprofit = newValue;
        Object.values(pack.desi).forEach(desi => {
          desi.agentreturnprofit = newValue;
        });
      });
    },
  },

};
</script>

<style></style>
