<template>
  <m-app>
    <m-menu>
      <m-menu-item
        :title="getLang('cargo', 'list')"
        @click.native="openTab('list/basic/cargo', getLang('cargo', 'list'))"
      />
      <m-menu-item
        :title="getLang('menu.logout')"
        @click.native="
          () => {
            $router.push({ path: '/companylogin' });
          }
        "
      />
      <!--
      <m-menu-item :title="getLang('menu.lists')">
        <m-sub-menu>
          <m-menu-item
            :title="getLang('cargo', 'list')"
            @click.native="
              openTab('list/basic/cargo', getLang('cargo', 'list'))
            "
          />
        </m-sub-menu>
      </m-menu-item>
      
      <m-menu-item title="Raporlar">
        <m-sub-menu>
          <m-menu-item
            :title="getLang('reselleraccount', 'list')"
            @click.native="
              openTab(
                'list/basic/reselleraccount',
                getLang('reselleraccount', 'list')
              )
            "
          />
        </m-sub-menu>
      </m-menu-item>
      -->
    </m-menu>
    <m-tab ref="mainAppTab" />
  </m-app>
</template>

<script>
import EventBus from "../libs/EventBus";
import { mapGetters } from "vuex";
export default {
  computed: {
    getLangField: () => (key, type = "form") => {
      const items = key.split("/");
      const val = {
        cargo: "menu.cargo",
        reseller: "menu.agent",
        lang: "menu.lang",
        langfield: "menu.langfield",
        role: "menu.role",
        user: "menu.user",
        brokerage: "menu.mediate",
        brokerageagent: "menu.mediateagent",
        package: "menu.package",
        present: "menu.present",
        presentaddress: "menu.presentaddress",
        vehicle: "menu.vehicle",
        returnvariable: "menu.returnvariable",
        paytype: "menu.paytype",
        sendingtype: "menu.sendingtype",
        campaigncoupon: "menu.campaigncoupon",
        contract: "menu.contract",
        cargotype: "menu.cargotype",
        city: "menu.city",
        county: "menu.county",
        neighborhood: "menu.neighborhood",
        town: "menu.neighborhood",
      };

      if (items.length > 1) {
        if (items[0] == "form") {
          if (items[1] in val) {
            return val[items[1]] + "definitions";
          }
        } else if (items[0] == "list") {
          if (items[2] in val) {
            return val[items[2]] + "list";
          }
        }
      } else {
        if (key in val) {
          if (type == "form") {
            return val[key] + "definitions";
          } else if (type == "list") {
            return val[key] + "list";
          } else {
            return val[key];
          }
        }
      }

      return key;
    },
  },

  mounted() {
    EventBus.$on("grid-row-double-click", (row) => {
      this.openTab(
        "form/" + row.type + "/" + row.ID,
        this.$t(this.getLangField(row.type)) + ` [${row.ID}]`
      );
    });
    EventBus.$on("apicargo-transfer-click", (id) => {
      this.openTab("form/cargo/0/0/" + id, "Aracı Kargo Transferi - " + id);
    });
    EventBus.$on("cargo-track-click", (id) => {
      this.openTab("form/cargotrack/" + id, "Kargo Detayları - " + id);
    });
    //this.$refs["mainAppTab"].openTab("welcome", "Hoş Geldiniz :)");
    //this.$refs["mainAppTab"].openTab("grid", "Grid");
    setTimeout(() => {
      //this.$refs["mainAppTab"].openTab("welcome", "Hoş Geldiniz :)", "ww");
      /*localStorage.setItem("selectedlang", "tr");
      const selectedlang = localStorage.getItem("selectedlang");
      const messages = JSON.parse(localStorage.getItem("messages"));

      this.$root.$i18n.setLocaleMessage(selectedlang, messages[selectedlang]);
      this.$root.$i18n.locale = selectedlang;*/
    }, 5000);
  },
  methods: {
    openUrl(url) {
      window.open(url, "new");
    },
    getLang(key, type = "form") {
      return this.$t(this.getLangField(key, type));
    },
    openTab(link, title) {
      this.$refs["mainAppTab"].openTab(link, title);
    },
  },
};
</script>

<style></style>
