<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('tab.global')" active>
        <div class="row">
          <div class="col-6">
            <m-yes-no langlabel="ismediate" v-model="form.ISBROKERAGE" />
            <m-input langlabel="contractcode" v-model="form.CONTRACTCODE" />
            <m-input
              type="date"
              langlabel="startdate"
              v-model="form.STARTDATE"
            />
            <m-input type="date" langlabel="enddate" v-model="form.ENDDATE" />
            <m-yes-no langlabel="isactive" v-model="form.ISACTIVE" />
            <m-textarea langlabel="description" v-model="form.DESCRIPTION" />
            <m-input v-model="form.DUEDAY" langlabel="dueday" />
            <m-input v-model="form.USERNAME" langlabel="username" />
            <m-input v-model="form.USERPASS" langlabel="password" />
          </div>
          <div class="col-6" v-show="!!form.ISBROKERAGE">
            {{ $t("form.mediateselect") }}
            <m-select
              nolabel
              v-model="form.BROKERAGEID"
              :options="options.brokerage"
            />
          </div>
          <div class="col-6" v-show="!form.ISBROKERAGE">
            <div class="row">
              <div class="col-4 text-right">
                {{ $t("form.presentsearchorselect") }}
              </div>
              <div class="col-8">
                <multiselect
                  v-model="selected.present"
                  :options="lookup.presentData"
                  :placeholder="$t('form.presentsearchorselect')"
                  label="text"
                  track-by="text"
                  :limit="3"
                  @search-change="searchPresent"
                  :selectLabel="$t('multiselect.pressentertoselect')"
                  :selectedLabel="$t('multiselect.selected')"
                  :deselectLabel="$t('multiselect.pressentertoselect')"
                >
                  <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
                  <span slot="noOptions">{{ $t("multiselect.nodata") }}</span>
                </multiselect>
                <br />
              </div>
            </div>
            <m-input
              readonly
              :value="selected.present ? selected.present.FIRMNAME : null"
              langlabel="firmname"
            />
            <m-input
              readonly
              :value="selected.present ? selected.present.FULLNAME : null"
              langlabel="fullname"
            />
            <m-input
              readonly
              :value="selected.present ? selected.present.TAXOFFICE : null"
              langlabel="taxoffice"
            />
            <m-input
              readonly
              :value="selected.present ? selected.present.IDENTITYNO : null"
              langlabel="taxno"
            />
            <m-select
              :options="options.address"
              v-model="form.ADDRESSID"
              langlabel="address"
            />
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('tab.packageoptions')">
        <div class="row">
          <div class="col-12">
            <div v-for="pack in lookup.packageData" :key="pack.ID">
              <b-form-checkbox
                v-model="packData[pack.ID].isUse"
                :value="true"
                :unchecked-value="false"
                @input="
                  () => {
                    $forceUpdate();
                  }
                "
              >
                {{ pack.PACKAGENAME }}
              </b-form-checkbox>
              <div v-show="packData[pack.ID].isUse">
                <div class="row" v-if="pack.ISREQUIREDDESI == '0'">
                  <div class="col-3">
                    {{ $t("form.pricenontax") }}
                    <m-input
                      nolabel
                      v-model="packData[pack.ID].price"
                      type="number"
                    />
                  </div>
                  <div class="col-3">
                    {{ $t("form.returnpricenontax") }}
                    <m-input
                      nolabel
                      v-model="packData[pack.ID].returnprice"
                      type="number"
                    />
                  </div>
                  <div class="col-3">
                    {{ $t("form.taxrate") }} : %{{ pack.TAXRATE }}
                  </div>
                </div>
                <div v-else>
                  <div class="col-12"><h5>Değişken Desi Tanımı</h5></div>

                  <b-table-simple>
                    <b-thead>
                      <tr>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.decistart") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.deciend") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.pricenontax") }}
                        </th>
                        <th
                          style="width: 15%; color: white !important"
                          v-if="USE_REGION_PRICE"
                        >
                          {{ $t("form.cityamountnontax") }}
                        </th>
                        <th
                          style="width: 15%; color: white !important"
                          v-if="USE_REGION_PRICE"
                        >
                          {{ $t("form.regionamountnontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnpricenontax") }}
                        </th>
                        <th style="width: 10%; color: white !important">
                          {{ $t("form.taxrate") }}
                        </th>
                      </tr>
                    </b-thead>

                    <b-tbody>
                      <tr v-for="desi in pack.desi" :key="`desi${desi.ID}`">
                        <td>{{ desi.DESISTART }}</td>
                        <td>{{ desi.DESIEND }}</td>
                        <td>
                          <m-input
                            v-model.lazy="packData[pack.ID].desi[desi.ID].price"
                            type="number"
                            nolabel
                          />
                        </td>
                        <td v-if="USE_REGION_PRICE">
                          <m-input
                            v-model.lazy="
                              packData[pack.ID].desi[desi.ID].cityprice
                            "
                            type="number"
                            nolabel
                          />
                        </td>
                        <td v-if="USE_REGION_PRICE">
                          <m-input
                            v-model.lazy="
                              packData[pack.ID].desi[desi.ID].regionprice
                            "
                            type="number"
                            nolabel
                          />
                        </td>
                        <td>
                          <m-input
                            v-model.lazy="
                              packData[pack.ID].desi[desi.ID].returnprice
                            "
                            type="number"
                            nolabel
                          />
                        </td>
                        <td>{{ desi.DESITAXRATE }}</td>
                      </tr>
                    </b-tbody>
                  </b-table-simple>
                  <template v-if="packData[pack.ID].DYNAMICDESIUSE">
                    <div class="col-12">
                      <h5>Dinamik Desi Tanımı</h5>
                      <small
                        >Pakette tanımlanan desiler haricinde ilave bir desi
                        için eklenecek fiyat.</small
                      >
                    </div>
                    <div class="col-4">
                      Başlangıç Desisi:
                      {{ packData[pack.ID].DYNAMICDESISTART }}
                    </div>

                    <div class="col-4">
                      Fiyat
                      <m-input
                        nolabel
                        type="number"
                        v-model="packData[pack.ID].DYNAMICDESIPRICE"
                      />
                    </div>

                    <div class="col-4">
                      Her Desi İçin Tutar
                      <m-input
                        nolabel
                        type="number"
                        v-model="packData[pack.ID].DYNAMICDESIPERPRICE"
                      />
                    </div>

                    <div class="col-4">
                      İade Fiyat
                      <m-input
                        nolabel
                        type="number"
                        v-model="packData[pack.ID].DYNAMICDESIRETURNPRICE"
                      />
                    </div>

                    <div class="col-4">
                      Her Desi İçin İade Tutarı
                      <m-input
                        nolabel
                        type="number"
                        v-model="packData[pack.ID].DYNAMICDESIRETURNPERPRICE"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('tab.sendingtypeoptions')">
        <div class="row">
          <div class="col-4">
            <strong class="text-center">
              {{ $t("form.sendingtypename") }}</strong
            >
          </div>
          <div class="col-4">
            <strong class="text-center">{{ $t("form.taxrate") }}</strong>
          </div>
          <div class="col-4">
            <strong class="text-center">{{ $t("form.pricenontax") }}</strong>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            v-for="(sendingType, sendingTypeIndex) in lookup.sendingTypeData"
            :key="`sendingType-${sendingTypeIndex}`"
            v-if="sendingType.ISPAYMENTDOOR == '1'"
          >
            <div class="row">
              <div class="col-4">{{ sendingType.SENDINGTYPENAME }}</div>
              <div class="col-4">{{ sendingType.SENDINGTYPETAXRATE }}</div>
              <div class="col-4">
                <m-input
                  nolabel
                  v-model="sendingData[sendingType.ID].price"
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="Ödeme Ayarları">
        <div class="row">
          <div class="col-6">
            <m-yes-no
              label="Ödeme Bilgilerini Ödeme Şekline Göre Ayarla"
              v-model="form.ISUSEPAYTYPE"
            />
            <m-yes-no
              label="Kapıda Ödeme Ücretini Alıcı Öder"
              :disabled="form.ISUSEPAYTYPE == '1'"
              v-model="form.ISPAYPAYMENTDOORTAKER"
            />
            <m-yes-no
              label="Taşıma Ücretini Alıcı Öder"
              :disabled="form.ISUSEPAYTYPE == '1'"
              v-model="form.ISPAYSHIPPINGPRICETAKER"
            />
            <m-yes-no
              label="Ambalaj Ücretini Alıcı Öder"
              :disabled="form.ISUSEPAYTYPE == '1'"
              v-model="form.ISPAYBOXAMOUNTTAKER"
            />
            <m-yes-no
              label="Ek Ücretleri Alıcı Öder"
              :disabled="form.ISUSEPAYTYPE == '1'"
              v-model="form.ISPAYSTATICPRICEAMOUNTTAKER"
            />
            <m-yes-no
              :disabled="form.ISUSEPAYTYPE == '1'"
              label="İskontoyu Tüm Fiyatlar Üzerinden Yap"
              v-model="form.ISUSEDISCOUNTALL"
            />

            <m-yes-no label="Vadesiz İşlem Yap" v-model="form.ISUSENOTDUE" />
          </div>
        </div>
      </b-tab>
      <b-tab title="Şube Hakedişi">
        <div class="row">
          <div class="col-12">
            <m-yes-no
              label="Sözleşmeyi şubeye bağla"
              v-model="form.USERESELLERPROFIT"
            />
          </div>
          <div class="col-12" v-show="form.USERESELLERPROFIT == '1'">
            <m-select
              v-model="form.RESELLERID"
              :options="options.reseller"
              langlabel="agent"
            />

            <m-select
              v-model="form.RESELLERPROFITTYPE"
              :options="options.profit"
              langlabel="payinfo"
            />
          </div>

          <div class="col-12" v-show="form.USERESELLERPROFIT == '1'">
            <div
              v-for="pack in lookup.senderPackageData"
              :key="`sender-${pack.ID}`"
            >
              <b-form-checkbox
                v-model="senderPackData[pack.ID].isUse"
                :value="true"
                :unchecked-value="false"
                @input="
                  () => {
                    $forceUpdate();
                  }
                "
              >
                {{ pack.PACKAGENAME }}
              </b-form-checkbox>
              <div v-show="senderPackData[pack.ID].isUse">
                <div class="row" v-if="pack.ISREQUIREDDESI == '0'">
                  <div class="col-2">
                    {{ $t("form.pricenontax") }}
                    {{ senderPackData[pack.ID].price }}
                  </div>
                  <div class="col-2">
                    {{ $t("form.returnpricenontax") }}
                    {{ senderPackData[pack.ID].returnprice }}
                  </div>
                  <div class="col-3">
                    {{ $t("form.payrateamount") }}
                    <m-input
                      nolabel
                      v-model.lazy="senderPackData[pack.ID].agentprofit"
                      type="number"
                    />
                  </div>
                  <div class="col-3">
                    {{ $t("form.returnrateamount") }}

                    <m-input
                      nolabel
                      v-model.lazy="senderPackData[pack.ID].agentreturnprofit"
                      type="number"
                    />
                  </div>
                  <div class="col-2">
                    {{ $t("form.taxrate") }} : %{{ pack.TAXRATE }}
                  </div>
                </div>
                <div v-else>
                  <b-table-simple style="color: white">
                    <b-thead>
                      <tr>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.decistart") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.deciend") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.pricenontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnpricenontax") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.payrateamount") }}
                        </th>
                        <th style="width: 15%; color: white !important">
                          {{ $t("form.returnrateamount") }}
                        </th>
                        <th style="width: 10%; color: white !important">
                          {{ $t("form.taxrate") }}
                        </th>
                      </tr>
                    </b-thead>

                    <b-tbody>
                      <tr
                        v-for="desi in pack.desi"
                        :key="`senderdesi${desi.ID}`"
                      >
                        <td>{{ desi.DESISTART }}</td>
                        <td>{{ desi.DESIEND }}</td>
                        <td>
                          {{ senderPackData[pack.ID].desi[desi.ID].price }}
                        </td>
                        <td>
                          {{
                            senderPackData[pack.ID].desi[desi.ID].returnprice
                          }}
                        </td>
                        <td>
                          <m-input
                            v-model.lazy="
                              senderPackData[pack.ID].desi[desi.ID].agentprofit
                            "
                            type="number"
                            nolabel
                          />
                        </td>
                        <td>
                          <m-input
                            v-model.lazy="
                              senderPackData[pack.ID].desi[desi.ID]
                                .agentreturnprofit
                            "
                            type="number"
                            nolabel
                          />
                        </td>
                        <td>{{ desi.DESITAXRATE }}</td>
                      </tr>
                    </b-tbody>
                    <b-tfoot v-if="pack.DYNAMICDESIUSE == '1'">
                      <tr>
                        <td colspan="7">
                          <div class="row">
                            <div class="col-1">Desi Başlangıç</div>
                            <div class="col-1">Desi Tutarı</div>
                            <div class="col-1">Desi Başına Tutar</div>
                            <div class="col-2">Desi Kazanç</div>
                            <div class="col-2">Desi Başına Kazanç</div>
                            <div class="col-2">Desi İade</div>
                            <div class="col-2">Desi Başına İade</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <div class="row">
                            <div class="col-1">
                              {{ pack.DYNAMICDESISTART }}
                            </div>
                            <div class="col-1">
                              {{ pack.DYNAMICDESIPRICE }}
                            </div>
                            <div class="col-1">
                              {{ pack.DYNAMICDESIPERPRICE }}
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID].dynamicDesiProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID].dynamicDesiPerProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID]
                                    .dynamicDesiReturnProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                            <div class="col-2">
                              <m-input
                                v-model.lazy="
                                  senderPackData[pack.ID]
                                    .dynamicDesiReturnPerProfit
                                "
                                type="number"
                                nolabel
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
import config from "../../config";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      //get contractı paketin yüklenmesini beklemesi için getpackagenin içine aldım
    }

    this.getBrokerage();

    this.getPackage();

    this.getReseller();

    this.getPackageProfit();

    this.options.profit[0].text = this.$t("buton.forrate");
    this.options.profit[1].text = this.$t("buton.foramount");
  },
  computed: {
    USE_REGION_PRICE() {
      return config.USE_REGION_PRICE;
    },
  },
  data() {
    return {
      ID: null,
      form: {
        ISBROKERAGE: 0,
        BROKERAGEID: null,
        CONTRACTCODE: null,
        PRESENTID: null,
        ADDRESSID: null,
        STARTDATE: null,
        ENDDATE: null,
        ISACTIVE: 1,
        DESCRIPTION: null,
        ISPAYPAYMENTDOORTAKER: 0,
        ISPAYSHIPPINGPRICETAKER: 0,
        ISPAYBOXAMOUNTTAKER: 0,
        ISPAYSTATICPRICEAMOUNTTAKER: 0,
        ISUSEDISCOUNTALL: 0,
        ISUSENOTDUE: 0,
        ISUSEPAYTYPE: 0,
        USERNAME: null,
        DUEDAY: null,
        USERPASS: null,

        USERESELLERPROFIT: 0,
        RESELLERID: null,
        RESELLERPROFITTYPE: "Rate",
      },

      packData: {},
      sendingData: {},

      loadingShow: false,
      loadingMsg: null,
      options: {
        brokerage: [],
        address: [],
        reseller: [],
        profit: [
          {
            text: "Orana Göre",
            value: "Rate",
          },
          {
            text: "Sabit Tutar",
            value: "Amount",
          },
        ],
      },

      lookup: {
        presentData: [],
        packageData: [],
        sendingTypeData: [],
        senderPackageData: [],
      },

      senderPackData: {},

      selected: {
        present: null,
      },
    };
  },
  methods: {
    searchPresent(query) {
      if (query.length < 4) {
        return;
      }

      this.getPresentWithTerm(query).then((res) => {
        if (res.data.status && !res.data.msg) {
          this.lookup.presentData = res.data.data.map((item) => {
            return {
              ...item,
              text: `${item.FIRMNAME} (${item.FULLNAME}) [${item.IDENTITYNO}]`,
              value: item.ID,
            };
          });
        }
      });
      //}, 500);
    },
    getAddress(id) {
      api.getPresentAddressWithPresentId(id).then((res) => {
        if (res.data.status) {
          this.options.address = res.data.data.rows.map((item) => {
            return {
              text: item.ADDRESSNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getPresentWithTerm(str, allPresent = 0) {
      let isBrokeragePresent = this.form.ISBROKERAGE;
      if (allPresent) {
        isBrokeragePresent = -1;
      }
      return api.getPresentWithTerm(str, isBrokeragePresent);
    },
    getPackage() {
      api
        .getPackageWithDesi()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.lookup.packageData = res.data.data;
            res.data.data.map((item) => {
              this.packData[item.ID] = {
                isUse: false,
                price: toTrFromNumber(item.PRICE),
                returnprice: toTrFromNumber(item.RETURNPRICE),
                desi: {},
                ISREQUIREDDESI: item.ISREQUIREDDESI,
                DYNAMICDESIUSE: item.DYNAMICDESIUSE,
                DYNAMICDESISTART: item.DYNAMICDESISTART,
                DYNAMICDESIPRICE: toTrFromNumber(item.DYNAMICDESIPRICE),
                DYNAMICDESIPERPRICE: toTrFromNumber(item.DYNAMICDESIPERPRICE),
                DYNAMICDESIRETURNPRICE: toTrFromNumber(
                  item.DYNAMICDESIRETURNPRICE
                ),
                DYNAMICDESIRETURNPERPRICE: toTrFromNumber(
                  item.DYNAMICDESIRETURNPERPRICE
                ),
              };
              item.desi.map((desiItem) => {
                this.packData[item.ID].desi[desiItem.ID] = {
                  price: toTrFromNumber(desiItem.DESIPRICE),
                  cityprice: toTrFromNumber(item.DESICITYPRICE),
                  regionprice: toTrFromNumber(item.DESIREGIONPRICE),
                  returnprice: toTrFromNumber(desiItem.DESIRETURNPRICE),
                };
              });
            });

            //get contractı paketin yüklenmesini beklemesi için getpackagenin içine aldım
            this.getSendingType();
            //get contractı paketin yüklenmesini beklemesi için getpackagenin içine aldım
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getSendingType() {
      api
        .getSendingType()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.lookup.sendingTypeData = res.data.data.rows;
            res.data.data.rows.map((item) => {
              this.sendingData[item.ID] = {
                price: toTrFromNumber(item.SENDINGTYPEPRICE),
                ISSTANDARTSENDING: item.ISSTANDARTSENDING,
              };
            });

            //get contractı paketin yüklenmesini beklemesi için getpackagenin içine aldım
            if (intval(this.ID) > 0) {
              this.getContract(this.ID);
            }
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getPackageProfit() {
      api
        .getPackageWithDesi()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.lookup.senderPackageData = res.data.data;
            res.data.data.map((item) => {
              this.senderPackData[item.ID] = {
                isUse: true,
                price: toTrFromNumber(item.PRICE),
                returnprice: toTrFromNumber(item.RETURNPRICE),
                agentprofit: 0,
                agentreturnprofit: 0,
                desi: {},
                ISREQUIREDDESI: item.ISREQUIREDDESI,
                DYNAMICDESIUSE: item.DYNAMICDESIUSE,
                DYNAMICDESISTART: item.DYNAMICDESISTART,
                DYNAMICDESIPRICE: item.DYNAMICDESIPRICE,
                DYNAMICDESIPERPRICE: item.DYNAMICDESIPERPRICE,
                dynamicDesiProfit: 0,
                dynamicDesiPerProfit: 0,
                dynamicDesiReturnProfit: 0,
                dynamicDesiReturnPerProfit: 0,
              };

              item.desi.map((desiItem) => {
                this.senderPackData[item.ID].desi[desiItem.ID] = {
                  price: toTrFromNumber(desiItem.DESIPRICE),
                  returnprice: toTrFromNumber(desiItem.DESIRETURNPRICE),
                  agentprofit: 0,
                  agentreturnprofit: 0,
                };
              });
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getReseller() {
      api
        .getReseller()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.reseller = res.data.data.rows.map((item) => {
              return { text: item.RESELLERNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    save() {
      if (this.form.ISBROKERAGE) {
        if (this.form.BROKERAGEID == null) {
          swal(this.$t("dialog.notice"), "Lütfen aracı seçiniz!", "error");
          return;
        }
      } else {
        if (this.selected.present.value == null) {
          swal(this.$t("dialog.notice"), "Lütfen müşteri seçiniz!", "error");
          return;
        }
      }

      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      const data = this.form;

      const sendingIds = Object.keys(this.sendingData);
      data.sendingtypes = sendingIds.map((sendingId) => {
        return {
          SENDINGTYPEID: sendingId,
          SENDINGTYPEPRICE: toNumberFromTr(this.sendingData[sendingId].price),
        };
      });

      const packIds = Object.keys(this.packData);
      data.packs = packIds
        .filter((item) => {
          const pack = this.packData[item];

          return pack.isUse;
        })
        .map((item) => {
          const pack = this.packData[item];
          const desiIds = Object.keys(pack.desi);

          return {
            PACKAGEID: item,
            PRICE: toNumberFromTr(pack.price),
            RETURNPRICE: toNumberFromTr(pack.returnprice),
            ISREQUIREDDESI: pack.ISREQUIREDDESI,
            DYNAMICDESIUSE: pack.DYNAMICDESIUSE,
            DYNAMICDESISTART: pack.DYNAMICDESISTART,
            DYNAMICDESIPRICE: toNumberFromTr(pack.DYNAMICDESIPRICE),
            DYNAMICDESIPERPRICE: toNumberFromTr(pack.DYNAMICDESIPERPRICE),
            DYNAMICDESIRETURNPRICE: toNumberFromTr(pack.DYNAMICDESIRETURNPRICE),
            DYNAMICDESIRETURNPERPRICE: toNumberFromTr(
              pack.DYNAMICDESIRETURNPERPRICE
            ),
            desis: desiIds.map((desiId) => {
              const desi = pack.desi[desiId];
              return {
                DESIID: desiId,
                DESIPRICE: toNumberFromTr(desi.price),
                DESICITYPRICE: toNumberFromTr(desi.cityprice),
                DESIREGIONPRICE: toNumberFromTr(desi.regionprice),
                DESIRETURNPRICE: toNumberFromTr(desi.returnprice),
              };
            }),
          };
        });

      //reseller profit
      const senderPackIds = Object.keys(this.senderPackData);
      data.senderProfit = senderPackIds.map((item) => {
        const pack = this.senderPackData[item];
        const desiIds = Object.keys(pack.desi);

        return {
          PACKAGEID: item,
          PROFIT: toNumberFromTr(pack.agentprofit),
          RETURNPROFIT: toNumberFromTr(pack.agentreturnprofit),
          ISREQUIREDDESI: pack.ISREQUIREDDESI,
          DYNAMICDESIPROFIT: toNumberFromTr(pack.dynamicDesiProfit),
          DYNAMICDESIPERPROFIT: toNumberFromTr(pack.dynamicDesiPerProfit),
          DYNAMICDESIRETURNPROFIT: toNumberFromTr(pack.dynamicDesiReturnProfit),
          DYNAMICDESIRETURNPERPROFIT: toNumberFromTr(
            pack.dynamicDesiReturnPerProfit
          ),
          DYNAMICDESIUSE: pack.DYNAMICDESIUSE,

          desis: desiIds.map((desiId) => {
            const desi = pack.desi[desiId];
            return {
              DESIID: desiId,
              DESIPROFIT: toNumberFromTr(desi.agentprofit),
              DESIRETURNPROFIT: toNumberFromTr(desi.agentreturnprofit),
            };
          }),
        };
      });

      if (data.ISBROKERAGE) {
      } else {
        data.PRESENTID = this.selected.present.value;
      }

      api
        .saveContract(data, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getContract(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getContractOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              ISBROKERAGE,
              BROKERAGEID,
              CONTRACTCODE,
              PRESENTID,
              STARTDATE,
              ENDDATE,
              ISACTIVE,
              DESCRIPTION,
              ISPAYSHIPPINGPRICETAKER,
              ISPAYPAYMENTDOORTAKER,
              ISPAYBOXAMOUNTTAKER,
              ISPAYSTATICPRICEAMOUNTTAKER,
              ISUSEDISCOUNTALL,
              ISUSENOTDUE,
              ISUSEPAYTYPE,
              USERNAME,
              DUEDAY,
              USERPASS,
              ADDRESSID,

              USERESELLERPROFIT,
              RESELLERID,
              RESELLERPROFITTYPE,
            } = res.data.data;
            this.form = {
              ISBROKERAGE: intval(ISBROKERAGE),
              BROKERAGEID,
              CONTRACTCODE,
              PRESENTID,
              STARTDATE,
              ENDDATE,
              ISACTIVE,
              DESCRIPTION,
              USERNAME,
              USERPASS,
              DUEDAY,
              ISPAYBOXAMOUNTTAKER: intval(ISPAYBOXAMOUNTTAKER),
              ISPAYSTATICPRICEAMOUNTTAKER: intval(ISPAYSTATICPRICEAMOUNTTAKER),
              ISUSEDISCOUNTALL: intval(ISUSEDISCOUNTALL),
              ISPAYSHIPPINGPRICETAKER: intval(ISPAYSHIPPINGPRICETAKER),
              ISPAYPAYMENTDOORTAKER: intval(ISPAYPAYMENTDOORTAKER),
              ISUSEPAYTYPE: intval(ISUSEPAYTYPE),
              ISUSENOTDUE: intval(ISUSENOTDUE),
              ADDRESSID,
              USERESELLERPROFIT: intval(USERESELLERPROFIT),
              RESELLERID,
              RESELLERPROFITTYPE,
            };

            if (!intval(ISBROKERAGE)) {
              this.selected.present = res.data.data.present;
              this.selected.present.text = res.data.data.present.FULLNAME;
              this.selected.present.value = PRESENTID;
            }

            const packages = res.data.data.pack;
            this.$nextTick(() => {
              packages.forEach((item) => {
                if (item.ID in this.packData) {
                  this.packData[item.ID].isUse = true;
                  this.packData[item.ID].price = toTrFromNumber(item.PRICE);
                  this.packData[item.ID].returnprice = toTrFromNumber(
                    item.RETURNPRICE
                  );
                  this.packData[item.ID].DYNAMICDESIUSE = item.DYNAMICDESIUSE;
                  this.packData[item.ID].DYNAMICDESISTART =
                    item.DYNAMICDESISTART;
                  this.packData[item.ID].DYNAMICDESIPRICE = toTrFromNumber(
                    item.DYNAMICDESIPRICE
                  );
                  this.packData[item.ID].DYNAMICDESIPERPRICE = toTrFromNumber(
                    item.DYNAMICDESIPERPRICE
                  );

                  this.packData[item.ID].DYNAMICDESIRETURNPRICE =
                    toTrFromNumber(item.DYNAMICDESIRETURNPRICE);
                  this.packData[item.ID].DYNAMICDESIRETURNPERPRICE =
                    toTrFromNumber(item.DYNAMICDESIRETURNPERPRICE);

                  item.desi.forEach((item2) => {
                    if (item2.PACKAGEID in this.packData) {
                      if (item2.ID in this.packData[item2.PACKAGEID].desi) {
                        this.packData[item2.PACKAGEID].desi[item2.ID].price =
                          toTrFromNumber(item2.DESIPRICE);
                        this.packData[item2.PACKAGEID].desi[
                          item2.ID
                        ].cityprice = toTrFromNumber(item2.DESICITYPRICE);
                        this.packData[item2.PACKAGEID].desi[
                          item2.ID
                        ].regionprice = toTrFromNumber(item2.DESIREGIONPRICE);
                        this.packData[item2.PACKAGEID].desi[
                          item2.ID
                        ].returnprice = toTrFromNumber(item2.DESIRETURNPRICE);
                      }
                    }
                  });
                }
              });
            });
            const sendingtypes = res.data.data.sendingtype;
            this.$nextTick(() => {
              sendingtypes.forEach((item) => {
                if (item.ID in this.sendingData) {
                  this.sendingData[item.ID].price = toTrFromNumber(
                    item.SENDINGTYPEPRICE
                  );
                }
              });
            });

            const profit = res.data.data.profit;
            if (profit) {
              profit.map((item) => {
                if (item.PROFITTYPE == "Sender") {
                  this.form.SENDERRESELLERPROFIT = item.PROFITCALCTYPE;

                  if (item.PACKAGEID in this.senderPackData) {
                    this.senderPackData[item.PACKAGEID].agentprofit =
                      toTrFromNumber(item.PROFIT);
                    this.senderPackData[item.PACKAGEID].agentreturnprofit =
                      toTrFromNumber(item.RETURNPROFIT);

                    /**Dinamik desi */
                    this.senderPackData[item.PACKAGEID].dynamicDesiProfit =
                      toTrFromNumber(item.DYNAMICDESIPROFIT);
                    this.senderPackData[item.PACKAGEID].dynamicDesiPerProfit =
                      toTrFromNumber(item.DYNAMICDESIPERPROFIT);
                    this.senderPackData[
                      item.PACKAGEID
                    ].dynamicDesiReturnProfit = toTrFromNumber(
                      item.DYNAMICDESIRETURNPROFIT
                    );
                    this.senderPackData[
                      item.PACKAGEID
                    ].dynamicDesiReturnPerProfit = toTrFromNumber(
                      item.DYNAMICDESIRETURNPERPROFIT
                    );
                    /**Dinamik desi son */
                    if (
                      this.senderPackData[item.PACKAGEID].ISREQUIREDDESI ==
                        "1" &&
                      item.ISREQUIREDDESI == "1" &&
                      item.desi != null
                    ) {
                      item.desi.map((desiItem) => {
                        if (
                          desiItem.PACKAGEDESIID in
                          this.senderPackData[item.PACKAGEID].desi
                        ) {
                          this.senderPackData[item.PACKAGEID].desi[
                            desiItem.PACKAGEDESIID
                          ].agentprofit = toTrFromNumber(desiItem.DESIPROFIT);
                          this.senderPackData[item.PACKAGEID].desi[
                            desiItem.PACKAGEDESIID
                          ].agentreturnprofit = toTrFromNumber(
                            desiItem.DESIRETURNPROFIT
                          );
                        }
                      });
                    }
                  }
                }
              });
            }
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteContract(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "selected.present"(val) {
      if (val.value) {
        this.getAddress(val.value);
      }
    },
  },
};
</script>

<style scoped>
tbody tr td {
  color: white;
}
</style>
