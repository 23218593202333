<template>
  <div class="login-dark">
    <form method="post">
      <h2 class="sr-only">{{ $t("login.dologin") }}</h2>
      <div class="illustration">
        <i class="icon ion-ios-locked-outline"></i>
      </div>
      <div class="form-group" style="text-align: center">
        <img src="../assets/logo.png" class="logo" />
        <br />
        <strong>Aracı Girişi</strong>
      </div>
      <div class="form-group">
        <input
          class="form-control"
          :placeholder="$t('login.username')"
          v-model="username"
        />
      </div>
      <div class="form-group">
        <input
          class="form-control"
          type="password"
          :placeholder="$t('login.password')"
          v-model="password"
        />
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block" @click.prevent="doLogin">
          {{ $t("login.dologin") }}
        </button>
      </div>
      <div class="form-group">
        {{ $t("info.langchoose") }}
        <country-flag
          style="cursor: pointer; margin-left: 2px"
          @click.native="changeLang(lang)"
          :country="lang == 'en' ? 'gb' : lang"
          v-for="lang in langs"
          :key="lang"
        />
      </div>
      <a class="forgot">
        <a href="http://www.proder.com.tr/" target="new">Proder Yazılım A.Ş.</a>
        | 444 2125</a
      >
    </form>
    <m-loading :msg="loadingMsg" v-if="loadingShow" />
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import swal from "sweetalert";
import api from "../api";
import { mapActions } from "vuex";
export default {
  components: {
    "country-flag": CountryFlag,
  },
  beforeMount() {
    this.getLang();
  },
  mounted() {},
  data() {
    return {
      username: null,
      password: null,

      langs: [],

      loadingMsg: null,
      loadingShow: false,
    };
  },
  methods: {
    ...mapActions(["setPerms"]),
    changeLang(lang) {
      localStorage.setItem("selectedlang", lang);
      const selectedlang = lang;
      const messages = JSON.parse(localStorage.getItem("messages"));

      this.$root.$i18n.setLocaleMessage(selectedlang, messages[selectedlang]);
      this.$root.$i18n.locale = selectedlang;
    },
    doLogin() {
      if (this.username.length < 2) {
        swal(
          this.$t("dialog.notice"),
          "Kullanıcı adı en az 3 karakter olmalı!",
          "error"
        );
        return;
      }
      if (this.password.length < 2) {
        swal(
          this.$t("dialog.notice"),
          "Şifre en az 3 karakter olmalı!",
          "error"
        );
        return;
      }

      api.brokerageLogin(this.username, this.password).then((res) => {
        if (res.data.status) {
          this.setPerms(res.data.data.PERMS);
          this.$router.push({
            path: "/brokeragemain",
          });
        } else {
          swal(this.$t("dialog.notice"), res.data.errMsg, "error");
        }
      });
    },
    getLang() {
      this.loadingShow = true;
      api
        .getSiteLang()
        .then((res) => {
          if (res.data.status) {
            this.langs = res.data.data.langs;
            localStorage.setItem("defaultlang", res.data.data.defaultlang);
            let selectedlang = localStorage.getItem("selectedlang");
            let userLang = navigator.language || navigator.userLanguage;
            if (!selectedlang) {
              userLang = userLang.split("-");
              userLang = userLang[0];

              if (this.langs.indexOf(userLang) > -1) {
                localStorage.setItem("selectedlang", userLang);
                selectedlang = userLang;
              } else {
                localStorage.setItem("selectedlang", res.data.data.defaultlang);
                selectedlang = res.data.data.defaultlang;
              }
            }
            localStorage.setItem(
              "messages",
              JSON.stringify(res.data.data.messages)
            );
            this.$nextTick(() => {
              this.$i18n.setLocaleMessage(
                selectedlang,
                res.data.data.messages[selectedlang]
              );
              this.$i18n.locale = selectedlang;
              console.log(selectedlang);
            });

            /*
              ayarlarda çoklu dil kullan ve varsayılan dil seçenekleri var
              eğerki kullanıcı daha önceden giriş yapmamış ve dil seçmemişse:
              taryıcıdiline bak, tarayıcı dili dil tanımlarında varmı? varsa seçili dil olarak tarayıcı dilini ata
              tarayıcı dili dil tanımlarında yoksa seçili dile varsayılan dili ata.

              daha önceden kullanıcı girişi yapmış ve dil seçmişse doğrudan o dili seçili dil olarak kullan
            */
          }
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style lang="scss">
.login-dark {
  height: 100vh;
  background: #475d62 url("../assets/star-sky.jpg");
  background-size: cover;
  position: relative;

  .logo {
    height: auto;
    max-width: 240px;
  }
}

.login-dark form {
  max-width: 320px;
  width: 90%;
  background-color: #1e2833;
  padding: 40px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}

.login-dark .illustration {
  text-align: center;
  padding: 15px 0 20px;
  font-size: 100px;
  color: #2980ef;
}

.login-dark form .form-control {
  background: none;
  border: none;
  border-bottom: 1px solid #434a52;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
}

.login-dark form .btn-primary {
  background: #214a80;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 26px;
  text-shadow: none;
  outline: none;
}

.login-dark form .btn-primary:hover,
.login-dark form .btn-primary:active {
  background: #214a80;
  outline: none;
}

.login-dark form .forgot {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}

.login-dark form .forgot:hover,
.login-dark form .forgot:active {
  opacity: 1;
  text-decoration: none;
}

.login-dark form .btn-primary:active {
  transform: translateY(1px);
}
</style>
