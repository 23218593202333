<template>
  <div class="m-tab-area">
    <div class="m-tab-list">
      <ul>
        <li
          v-for="(tab, index) in tabs"
          :key="tab.id"
          @click="activeTab(tab.id)"
          :class="{ active: tab.id == selected.id }"
          :title="tab.title"
        >
          {{ tab.title }}
          <span @click.stop="closeTab(index)">&times;</span>
        </li>

        <li
          @click="showStats"
          style="
            width: 35px;
            min-width: 35px;
            max-width: 35px;
            position: absolute;
            right: 3px;
          "
        >
          V
        </li>
      </ul>
    </div>
    <div class="m-tab-content">
      <template v-for="tab in tabs">
        <iframe
          frameborder="0"
          v-show="tab.id == selected.id && !isShowMain"
          :src="tab.href"
          :key="tab.id"
        ></iframe>
      </template>
      <div v-show="isShowMain">
        <m-stats />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
      selected: { id: null },
    };
  },
  computed: {
    isShowMain() {
      if (this.tabs.length == 0 || this.selected.id == null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    openTab(href, title, id = null) {
      if (id == null) {
        id = href;
      }
      let tabExist = this.tabs.find((tab) => tab.id == id);
      if (tabExist) {
        this.selected = tabExist;
        return;
      }
      let tab = {
        title,
        href: this.tabToHref(href),
        id: id,
      };
      this.tabs.push(tab);
      this.selected = tab;
    },
    tabToHref(href) {
      return (
        window.location.href.replace("brokeragemain", "").replace("main", "") +
        href
      );
    },
    activeTab(id) {
      this.selected = { id };
    },
    closeTab(index) {
      this.tabs.splice(index, 1);
      if (this.tabs.length > 0) {
        this.selected = this.tabs[0];
      }
    },
    showStats() {
      this.selected.id = null;
    },
  },
};
</script>

<style lang="scss"></style>
